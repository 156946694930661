import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  Accordion,
  HTMLContent,
  LearningGoals,
  PageHeader,
} from '../../components';
import {
  BottomNavigation,
  JustifiedScoreScale,
  LikertScale,
  PaginationPage,
  PaginationSwitcher,
} from '../../containers';
import { setPageFinished } from '../../redux/pages/pagesActions';
import { Reducers } from '../../redux/reducers';

import content from '../../content';
import useQuestion from '../../helpers/useQuestion';

import { ProceedType } from '../../data';
import { questions } from '../../fetches/questions';
import transformNormScoreToJustifiedScore from '../../helpers/transformNormScoreToJustifiedScore';
import { translate } from '../../helpers/translations';

const PAGE = 'succesfactoren-introductie';

interface MapStateToPropsType {
  successFactorLeaderShip: number;
  successFactorCulturalResilience: number;
  [PAGE]: any;
}

type SuccesfactorenIntroductieProps = RouteComponentProps & DispatchProp & MapStateToPropsType;


const SuccesfactorenIntroductie: FC<SuccesfactorenIntroductieProps> = ({
  dispatch,
  successFactorLeaderShip,
  successFactorCulturalResilience,
  [PAGE]: data,
}): ReactElement => {
  const [finishedPageState, setFinishedPageState] = useState(false);

  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [prevPaginationPage, setBeforePaginationChange] = useState(1);

  const [givenLeaderShipAnswers, updateGivenLeaderShipAnswers] = useState<any[]>([]);
  const [givenCulturalResilienceAnswers, updateGivenCulturalResilienceAnswers] = useState<any[]>([]);

  const [leaderShipValues, updateLeaderShipValues] = useState<any[]>([]);
  const [culturalResilienceValues, updateCulturalResilienceValues] = useState<any[]>([]);

  const [justifiedSuccessFactorLeaderShipScore, setJustifiedSuccessFactorLeaderShipScore] = useState(0);
  const [justifiedSuccessFactorCulturalResilienceScore, setJustifiedSuccessFactorCulturalResilienceScore] = useState(0);

  const leaderShipQuestions = [
    useQuestion(1),
    useQuestion(2),
    useQuestion(3),
    useQuestion(4),
    useQuestion(5),
    useQuestion(6),
    useQuestion(7),
  ];

  const culturalResilienceQuestions = [
    useQuestion(8),
    useQuestion(9),
    useQuestion(10),
    useQuestion(11),
    useQuestion(12),
    useQuestion(13),
    useQuestion(14),
    useQuestion(15),
    useQuestion(16),
    useQuestion(17),
    useQuestion(18),
  ];

  const successFactorLeaderShipQuestion = useQuestion(19);
  const successFactorCulturalResilienceQuestion = useQuestion(20);

  const pageRef = React.createRef<HTMLDivElement>();

  const handleLeaderShipChange = (question: string, range: number): void => {
    if (givenLeaderShipAnswers.indexOf(question) === -1) {
      const index = content.succesfactoren.introductie.pageThree.leadershipRatings.findIndex(((value: string) => value === question));
      givenLeaderShipAnswers[index] = range;

      updateGivenLeaderShipAnswers(givenLeaderShipAnswers);

      // Array to calculate average of answers
      updateLeaderShipValues([...leaderShipValues, range]);
    }
  };
  const handleCulturalResilienceChange = (question: string, range: number): void => {
    if (givenCulturalResilienceAnswers.indexOf(question) === -1) {
      const index = content.succesfactoren.introductie.pageThree.culturalResilienceRatings.findIndex(((value: string) => value === question));
      givenCulturalResilienceAnswers[index] = range;

      updateGivenCulturalResilienceAnswers(givenCulturalResilienceAnswers);

      // Array to calculate average of answers
      updateCulturalResilienceValues([...culturalResilienceValues, range]);
    }
  };

  const checkNextButtonLock = (): boolean => {
    if (activePaginationPage === 4) {
      return givenLeaderShipAnswers.filter(answer => answer).length < 7;
    }
    if (activePaginationPage === 5) {
      return givenCulturalResilienceAnswers.filter(answer => answer).length < 11;
    }

    return false;
  };

  const nextButtonLock = checkNextButtonLock();

  const findAVG = (arr: number[]): number => Number((arr.reduce((a: number, b: number) => a + b, 0) / arr.length).toFixed(2));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect((): void => {
    if (successFactorLeaderShip && successFactorCulturalResilience) {
      setFinishedPageState(true);
    }

    if (
      leaderShipQuestions[0]
      && leaderShipQuestions[1]
      && leaderShipQuestions[2]
      && leaderShipQuestions[3]
      && leaderShipQuestions[4]
      && leaderShipQuestions[5]
      && leaderShipQuestions[6]
      && culturalResilienceQuestions[0]
      && culturalResilienceQuestions[1]
      && culturalResilienceQuestions[2]
      && culturalResilienceQuestions[3]
      && culturalResilienceQuestions[4]
      && culturalResilienceQuestions[5]
      && culturalResilienceQuestions[6]
      && culturalResilienceQuestions[7]
      && culturalResilienceQuestions[8]
      && culturalResilienceQuestions[9]
      && culturalResilienceQuestions[10]
    ) {
      if (
        leaderShipQuestions[0].attributes.answer
        && leaderShipQuestions[1].attributes.answer
        && leaderShipQuestions[2].attributes.answer
        && leaderShipQuestions[3].attributes.answer
        && leaderShipQuestions[4].attributes.answer
        && leaderShipQuestions[5].attributes.answer
        && leaderShipQuestions[6].attributes.answer
        && culturalResilienceQuestions[0].attributes.answer
        && culturalResilienceQuestions[1].attributes.answer
        && culturalResilienceQuestions[2].attributes.answer
        && culturalResilienceQuestions[3].attributes.answer
        && culturalResilienceQuestions[4].attributes.answer
        && culturalResilienceQuestions[5].attributes.answer
        && culturalResilienceQuestions[6].attributes.answer
        && culturalResilienceQuestions[7].attributes.answer
        && culturalResilienceQuestions[8].attributes.answer
        && culturalResilienceQuestions[9].attributes.answer
        && culturalResilienceQuestions[10].attributes.answer
      ) {
        setFinishedPageState(true);
      }
    }
  });

  const finishedPage = data.finished || finishedPageState;

  const handleSubmit = (): void => {
    const averageLeaderShip = transformNormScoreToJustifiedScore(
      ProceedType.inclusiefLeiderschap,
      findAVG(leaderShipValues),
    );
    const averageCulturalResilience = transformNormScoreToJustifiedScore(
      ProceedType.cultureleVeerkracht,
      findAVG(culturalResilienceValues),
    );

    setJustifiedSuccessFactorLeaderShipScore(averageLeaderShip);
    setJustifiedSuccessFactorCulturalResilienceScore(averageCulturalResilience);

    if (!finishedPage) {
      dispatch(setPageFinished(PAGE));

      if (successFactorLeaderShipQuestion) {
        questions.patch(successFactorLeaderShipQuestion.id, {
          attributes: {
            answer: String(averageLeaderShip),
          },
          relationships: {},
        });
      }

      if (successFactorCulturalResilienceQuestion) {
        questions.patch(successFactorCulturalResilienceQuestion.id, {
          attributes: {
            answer: String(averageCulturalResilience),
          },
          relationships: {},
        });
      }

      givenLeaderShipAnswers.forEach((value, index) => {
        const q = leaderShipQuestions[index];

        if (q) {
          questions.patch(q.id, {
            attributes: {
              answer: value,
            },
            relationships: {},
          });
        }
      });

      givenCulturalResilienceAnswers.forEach((value, index) => {
        const q = culturalResilienceQuestions[index];

        if (q) {
          questions.patch(q.id, {
            attributes: {
              answer: value,
            },
            relationships: {},
          });
        }
      });
    }
  };

  useEffect((): void => {
    if (prevPaginationPage === 6 && !finishedPage) {
      handleSubmit();
    }
  }, [prevPaginationPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    setJustifiedSuccessFactorLeaderShipScore(
      successFactorLeaderShipQuestion ? parseInt(successFactorLeaderShipQuestion.attributes.answer, 0) : 0,
    );
  }, [successFactorLeaderShipQuestion]);

  useEffect((): void => {
    setJustifiedSuccessFactorCulturalResilienceScore(
      successFactorCulturalResilienceQuestion ? parseInt(successFactorCulturalResilienceQuestion.attributes.answer, 0) : 0,
    );
  }, [successFactorCulturalResilienceQuestion]);

  return (
    <main ref={pageRef} className="page">
      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <PageHeader title="Introductie" />
          <HTMLContent html={content.succesfactoren.introductie.pageOne.intro} />
          <LearningGoals
            knowledge={content.succesfactoren.introductie.pageOne.kennis}
            insight={content.succesfactoren.introductie.pageOne.inzicht}
            skill={content.succesfactoren.introductie.pageOne.skill}
          />
        </PaginationPage>
        <PaginationPage id={2}>
          <PageHeader title="Het belang van inclusief leiderschap en culturele veerkracht" />
          <Accordion
            id="drijfveren-mijn-drijfveer-kwaliteit"
            items={[
              { label: 'Inclusief leiderschap', htmlContent: content.succesfactoren.introductie.pageTwo.inclusiefLeiderschap },
              { label: 'Culturele Veerkracht', htmlContent: content.succesfactoren.introductie.pageTwo.cultureleVeerkracht },
            ]}
          />
        </PaginationPage>
        <PaginationPage id={3}>
          <PageHeader title="Mijn inclusief leiderschap en culturele veerkracht" />
          <HTMLContent html={content.succesfactoren.introductie.pageThree.intro} />
        </PaginationPage>
        <PaginationPage id={4}>
          <PageHeader title="Vragenlijsten" />
          <p>Als leidinggevende...</p>
          {leaderShipQuestions[leaderShipQuestions.length - 1] !== undefined ? (
            <LikertScale
              data={data}
              name="leidinggevend"
              questions={content.succesfactoren.introductie.pageThree.leadershipRatings}
              defaultValues={[...leaderShipQuestions].map((question) => (question ? Number(question.attributes.answer) : undefined))}
              disabled={finishedPage}
              onChange={handleLeaderShipChange}
            />
          ) : <span>Laden...</span>}
        </PaginationPage>
        <PaginationPage id={5}>
          <PageHeader title="Vragenlijsten" />
          <p>Geef aan in hoeverre de onderstaande stellingen op jou van toepassing zijn:</p>
          {culturalResilienceQuestions[culturalResilienceQuestions.length - 1] !== undefined ? (
            <LikertScale
              data={data}
              name="culturele-veerkracht"
              labelLeast="Helemaal niet van toepassing"
              labelMost="Helemaal van toepassing"
              questions={content.succesfactoren.introductie.pageThree.culturalResilienceRatings}
              defaultValues={[...culturalResilienceQuestions].map((question) => (question ? Number(question.attributes.answer) : undefined))}
              disabled={finishedPage}
              onChange={handleCulturalResilienceChange}
            />
          ) : <span>Laden...</span>}
        </PaginationPage>
        <PaginationPage id={6}>
          <PageHeader title="Scores" />
          { justifiedSuccessFactorLeaderShipScore ? (
            <JustifiedScoreScale
              intro={translate('succesfactoren.introductie.pageSix.proceedTypeIntro', { proceedType: 'inclusief leiderschap' })}
              score={justifiedSuccessFactorLeaderShipScore}
            />
          ) : null}
          { justifiedSuccessFactorCulturalResilienceScore ? (
            <JustifiedScoreScale
              intro={translate('succesfactoren.introductie.pageSix.proceedTypeIntro', { proceedType: 'culturele veerkracht' })}
              score={justifiedSuccessFactorCulturalResilienceScore}
            />
          ) : null}
        </PaginationPage>
      </PaginationSwitcher>
      <BottomNavigation
        paginationSize={6}
        isForm={!finishedPage}
        unblockedPagination={finishedPage}
        lockNextButton={nextButtonLock}
        onPaginationChange={setActivePaginationPage}
        onBeforePaginationChange={setBeforePaginationChange}
      />
    </main>
  );
};

const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  successFactorLeaderShip: state.userReducer.successFactorLeaderShip,
  successFactorCulturalResilience: state.userReducer.successFactorCulturalResilience,
  [PAGE]: state.pagesReducer.pages[PAGE],
});


export default connect(mapStateToProps)(SuccesfactorenIntroductie);

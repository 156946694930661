import React, { FC, ReactElement } from 'react';

import { BottomNavigationWithExitButton } from '..';
import { HTMLContent, PageHeader } from '../../components';
import content from '../../content';


const SuccesfactorenBibliotheek: FC = (): ReactElement => (
  <>
    <PageHeader title="Bibliotheek" />
    <HTMLContent html={content.succesfactoren.bibliotheek} />

    <BottomNavigationWithExitButton />
  </>
);

export default SuccesfactorenBibliotheek;

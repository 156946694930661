import { useSelector } from 'react-redux';
import { Reducers } from '../redux/reducers';

export default <Reducer extends keyof Reducers, Property extends keyof Reducers[Reducer]>(
  reducer: Reducer,
  property: Property,
  equalityFn?: (left: Reducers[Reducer][Property], right: Reducers[Reducer][Property]) => boolean,
): Reducers[Reducer][Property] => useSelector<Reducers, Reducers[Reducer][Property]>(
  (state): Reducers[Reducer][Property] => state[reducer][property], equalityFn,
);

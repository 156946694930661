import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useRouter from 'use-react-router';
import { SingleJsonApiResponseType } from '../fetches/JsonApiResponse';
import { getOrCreateQuestion } from '../redux/questions/questionsActions';
import useStore from './useStore';

export default (questionNumber: number, locationOverwrite?: string): SingleJsonApiResponseType<'questions'>['data']|undefined => {
  const dispatch = useDispatch();
  const { location } = useRouter();
  const [question, setQuestion] = useState<SingleJsonApiResponseType<'questions'>['data']>();
  const questionInStore = useStore('questionReducer', 'question');
  const questionLocation = locationOverwrite || location.pathname;

  useEffect(() => {
    dispatch(getOrCreateQuestion.started({
      page: questionLocation,
      number: questionNumber,
    }));
  }, [questionNumber, questionLocation, dispatch]);

  useEffect(() => {
    if (questionInStore === undefined
          || questionInStore.attributes.page !== questionLocation
          || questionInStore.attributes.number !== questionNumber
          || (question !== undefined && questionInStore.attributes.answer !== question.attributes.answer)
    ) {
      return;
    }

    setQuestion(questionInStore);
  }, [questionInStore]); // eslint-disable-line react-hooks/exhaustive-deps

  return question;
};

import React, { FC, ReactElement } from 'react';

import { ModuleProgression } from '../../helpers/getAllModuleProgressions';
import { ModuleStatusCard } from '../index';
import './ModuleStatusCardsContainer.scss';

interface ModuleStatusCardsContainerProps {
  className?: string;
  progressions: ModuleProgression[];
}

function isModuleStatusCardDisabled(index: number, modules: ModuleProgression[]): boolean {
  if (index === 0) {
    return false;
  }

  if (modules[index - 1].progression === 100) {
    return false;
  }

  if (modules[index].progression !== 0) {
    return false;
  }

  return true;
}

const ModuleStatusCardsContainer: FC<ModuleStatusCardsContainerProps> = ({
  className = '',
  progressions,
}): ReactElement => (
  <div className={`module-status-cards-container ${className}`}>
    {progressions.map((module, index) => {
      const isDisabled = isModuleStatusCardDisabled(index, progressions);

      return (
        <ModuleStatusCard
          className="module-status-cards-container__module-status-card"
          key={module.title}
          title={module.title}
          path={module.path}
          progress={module.progression}
          estimation={module.estimationInMinutes}
          isDisabled={isDisabled}
        />
      );
    })}
    <ModuleStatusCard
      className="module-status-cards-container__module-status-card"
      title="Bibliotheek"
      path="/bibliotheek/algemeen"
      intro="Hier vind je een overzicht van het naslagwerk en de links uit alle modules."
    />
  </div>
);

export default ModuleStatusCardsContainer;

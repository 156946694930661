import React, { FC, ReactElement, useMemo } from 'react';

import { BottomNavigation } from '..';
import { Button, HTMLContent, StartTitle } from '../../components';
import content from '../../content';
import { ProceedType } from '../../data';
import { translate } from '../../helpers/translations';

import './OpbrengstenOpdracht.scss';

interface OpbrengstenOpdrachtProps {
  chosenProceedType: ProceedType | null;
}


const OpbrengstenOpdracht: FC<OpbrengstenOpdrachtProps> = ({ chosenProceedType }): ReactElement => {
  const proceedType = useMemo(
    () => (chosenProceedType ? chosenProceedType.toLowerCase() : 'onbekend'
    ), [chosenProceedType],
  );

  const onButtonDownloadClick = () => {
    const a = document.createElement('a');
    a.href = '/docx/opdracht-module3.docx';
    a.download = '';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <>
      <StartTitle text="Opdracht" />
      <HTMLContent html={translate('opbrengsten.opdracht.intro', { proceedType })} />
      <HTMLContent html={content.opbrengsten.opdracht.callToAction} />
      <Button onClick={onButtonDownloadClick} className="opbrengsten-opdracht__download-button" text="Download formulier" />

      <img className="opbrengsten-opdracht__opdracht-preview" src="/opdracht-previews/opdracht-module3.png" alt="opdracht 3 preview" />

      <BottomNavigation />
    </>
  );
};

export default OpbrengstenOpdracht;

import { ProceedType } from '../../../data';
import { questions } from '../../../fetches/questions';

export default function patchChosenProceedType(chosenProceedType: ProceedType, apiChosenProceedType: any): void {
  questions.patch(apiChosenProceedType.id, {
    attributes: {
      answer: `${chosenProceedType}`,
    },
    relationships: {},
  });
}

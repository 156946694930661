import React, { FC, ReactElement } from 'react';

import { ConnectedStartCultureleDiversiteit } from '../../connectors';


const StartCultureleDiversiteit: FC = (): ReactElement => {

  return (
    <div className="page">
      <ConnectedStartCultureleDiversiteit />
    </div>
  );
};

export default StartCultureleDiversiteit;

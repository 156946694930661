import React, { FC, ReactElement } from 'react';

import ConnectedAanDeSlagVoorbereiding
  from '../../connectors/ConnectedAanDeSlagVoorbereiding/ConnectedAanDeSlagVoorbereiding';


const Voorbereiding: FC = (): ReactElement => (
  <main className="page">
    <ConnectedAanDeSlagVoorbereiding />
  </main>
);

export default Voorbereiding;

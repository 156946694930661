import React, {
  FC,
  FormEvent,
  ReactElement,
  useState,
} from 'react';

import { AnswerBubble, CheckboxInput } from '../../../components';
import '../QuizQuestion.scss';

import { QuizQuestionType } from '../QuizQuestion';

const getDefaultChecked = (defaultValues: string | string[] | undefined, option: string): boolean => {
  if (!defaultValues) {
    return false;
  }

  return !!(typeof defaultValues === 'string' ? [defaultValues] : defaultValues)
    .find(value => value === option);
};


const QuizCheckbox: FC<QuizQuestionType> = ({
  questionNumber,
  defaultValues,
  name,
  question,
  answer,
  answerImages,
  options,
  onChange,
  onSubmit,
  disabled = false,
  className = '',
}): ReactElement => {
  const [questionInput, setQuestionInput] = useState<string[]>([]);
  const [hasSubmit, setSubmit] = useState(false);

  const handleChange = (value: string): void => {
    const valueIndex = questionInput.indexOf(value);

    if (valueIndex === -1) {
      const newQuestionInput = questionInput.length > 0 ? [...questionInput, value] : [value];
      setQuestionInput(newQuestionInput);

      if (onChange) {
        onChange(value);
      }
    } else {
      const newQuestionInput = [...questionInput];
      newQuestionInput.splice(valueIndex, 1);
      setQuestionInput(newQuestionInput);

      if (onChange) {
        onChange(value);
      }
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setSubmit(true);
    if (onSubmit) {
      onSubmit(questionInput);
    }
  };

  if (!name || !options) {
    return <p className="quiz-question__error">Error: Vraag heeft geen name of options</p>;
  }

  return (
    <form onSubmit={handleSubmit} className={`quiz-question ${className}`}>
      {questionNumber && <legend className="quiz-question__chapeau">{`Vraag ${questionNumber}`}</legend>}

      <label className="quiz-question__label">{question}</label>

      {options.map((option): ReactElement => {
        const defaultChecked = getDefaultChecked(defaultValues, option);
        return (
          <CheckboxInput
            key={option}
            defaultChecked={defaultChecked}
            label={option}
            value={option}
            name={name}
            disabled={disabled || hasSubmit}
            onChange={handleChange}
          />
        );
      })}
      {(answer && hasSubmit) && <AnswerBubble htmlContent={answer} images={answerImages} />}

      {(answer && !hasSubmit) && (
        <input
          type="submit"
          value={answer ? 'Bekijk antwoord' : 'Verstuur'}
          disabled={!(questionInput.length > 0)}
          className="quiz-question__submit-button"
        />
      )}
    </form>
  );
};


export default QuizCheckbox;

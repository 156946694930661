import axios, { AxiosPromise } from 'axios';
import { addPatch, removePatch } from '../redux/questions/questionsActions';
import { getStore } from '../redux/store';
import {
  GetListPayload,
  OptionalAttributes,
  parseGetListPayload,
  parsePatchRequest,
  parsePostRequest,
} from './JsonApiRequest';
import { ManyJsonApiResponseType, SingleJsonApiResponseType } from './JsonApiResponse';
import ResponseTypes from './ResponseTypes';

export default <Type extends keyof ResponseTypes>(endpoint: string, type: Type) => ({
  fetch: (payload: GetListPayload): AxiosPromise<ManyJsonApiResponseType<Type>> => {
    const params = parseGetListPayload(payload);

    return axios.get<ManyJsonApiResponseType<Type>>(`${process.env.REACT_APP_API_URL}/${endpoint}/?${params}`);
  },
  post: (payload: ResponseTypes[Type]): AxiosPromise<SingleJsonApiResponseType<Type>> => {
    const params = parsePostRequest(payload.attributes, payload.relationships, type);

    return axios.post<SingleJsonApiResponseType<Type>>(`${process.env.REACT_APP_API_URL}/${endpoint}/`, params);
  },
  patch: (id: string, payload: OptionalAttributes<Type>): AxiosPromise<SingleJsonApiResponseType<Type>> => {
    const params = parsePatchRequest(id, payload.attributes, payload.relationships, type);
    const store = getStore();

    const req = axios.patch<SingleJsonApiResponseType<Type>>(`${process.env.REACT_APP_API_URL}/${endpoint}/${id}`, params);

    if (store) {
      store.dispatch(addPatch());
      req.finally(() => store.dispatch(removePatch()));
    }

    return req;
  },
});

import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { Accordion, PageHeader } from '../../components';
import content from '../../content';


const AanDeSlagBestPractices: FC = (): ReactElement => (
  <>
    <PageHeader title="Best practices" />
    <Accordion
      id="aan-de-slag-best-practices"
      items={[
        { label: 'Kwaliteit', htmlContent: content.aanDeSlag.bibliotheek.kwaliteit },
        { label: 'Rechtvaardigheid', htmlContent: content.aanDeSlag.bibliotheek.rechtvaardigheid },
        { label: 'Toegang', htmlContent: content.aanDeSlag.bibliotheek.toegang },
        { label: 'Leren', htmlContent: content.aanDeSlag.bibliotheek.leren },
      ]}
    />

    <BottomNavigation />
  </>
);

export default AanDeSlagBestPractices;

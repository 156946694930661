import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import getSummaryTypeTranslation from '../../helpers/getSummaryTypeTranslation';
import { SummaryType } from '../../ModuleSummary';

import './ModuleSummaryItem.scss';

interface ReflectionItemProps {
  type: SummaryType;
  showType: boolean;
  content?: string;
  className?: string;
}

const ModuleSummaryItem: FC<ReflectionItemProps> = ({
  type,
  content = '',
  showType = true,
  children,
  className = '',
}): ReactElement => {
  const hasIcon = !!type && showType;
  const classNames = classnames('module-summary-item', {
    [`module-summary-item--is-${type}`]: hasIcon,
    'module-summary-item--has-icon': hasIcon,
  }, className);

  return (
    <li className={classNames}>
      <p>
        {`${showType ? `${getSummaryTypeTranslation(type)}: ` : ''}${content}`}
      </p>
      { children }
    </li>
  );
};

export default ModuleSummaryItem;

import { ProceedType } from '../../../data';
import { SingleJsonApiResponseType } from '../../../fetches/JsonApiResponse';
import { WorkUnitEstimate } from '../ConnectedOpbrengstenDiagnose';

export default function transformApiEstimatesToGivenEstimates(
  apiValues: Array<SingleJsonApiResponseType<'questions'>['data'] | undefined>,
  proceedTypes: ProceedType[],
): Partial<Record<ProceedType, WorkUnitEstimate>> {
  return proceedTypes.reduce((total: Partial<Record<ProceedType, WorkUnitEstimate>>, proceedType, index) => {
    if (!apiValues[index]) {
      return total;
    }

    const data = apiValues[index];

    return {
      ...total,
      [proceedType]: {
        score: (data && data.attributes.answer) ? parseInt(data.attributes.answer, 0) : undefined,
        comment: data ? data.attributes.comment : undefined,
      },
    };
  }, {});
}

export const AUTH_SAGA_LOGOUT = 'AUTH_SAGA_LOGOUT';
export const AUTH_UNSET_TOKENS = 'AUTH_UNSET_TOKENS';
export const AUTH_SAGA_AUTHENTICATE_USER_WITH_CREDENTIALS = 'AUTH_SAGA_AUTHENTICATE_USER_WITH_CREDENTIALS';
export const AUTH_SAGA_AUTHENTICATE_USER_WITH_INVITE_TOKEN = 'AUTH_SAGA_AUTHENTICATE_USER_WITH_INVITE_TOKEN';
export const AUTH_SAGA_AUTHENTICATE_USER_WITH_RESET_TOKEN = 'AUTH_SAGA_AUTHENTICATE_USER_WITH_RESET_TOKEN';
export const AUTH_AUTHENTICATE_REQUEST = 'AUTH_AUTHENTICATE_REQUEST';
export const AUTH_AUTHENTICATE_SUCCESS = 'AUTH_AUTHENTICATE_SUCCESS';
export const AUTH_AUTHENTICATE_FAILURE = 'AUTH_AUTHENTICATE_FAILURE';
export const AUTH_SAGA_SET_PASSWORD = 'AUTH_SAGA_SET_PASSWORD';
export const AUTH_SET_PASSWORD_REQUEST = 'AUTH_SET_PASSWORD_REQUEST';
export const AUTH_SET_PASSWORD_SUCCESS = 'AUTH_SET_PASSWORD_SUCCESS';
export const AUTH_SET_PASSWORD_FAILURE = 'AUTH_SET_PASSWORD_FAILURE';
export const AUTH_UPDATE_API_TOKEN = 'AUTH_UPDATE_API_TOKEN';
export const AUTH_UPDATE_REFRESH_TOKEN = 'AUTH_UPDATE_REFRESH_TOKEN';
export const AUTH_SAGA_REQUEST_USER_PASSWORD_RESET = 'AUTH_SAGA_REQUEST_USER_PASSWORD_RESET';

export interface AuthUserWithCredentialsAction {
  type: typeof AUTH_SAGA_AUTHENTICATE_USER_WITH_CREDENTIALS;
  credentials: object;
  errorCallback: () => void;
}

export interface AuthUserWithInviteTokenAction {
  type: typeof AUTH_SAGA_AUTHENTICATE_USER_WITH_INVITE_TOKEN;
  token: string;
}

export interface AuthUserWithResetTokenAction {
  type: typeof AUTH_SAGA_AUTHENTICATE_USER_WITH_RESET_TOKEN;
  token: string;
}

export interface AuthSetTokenAction {
  type: typeof AUTH_UPDATE_API_TOKEN | typeof AUTH_UPDATE_REFRESH_TOKEN;
  apiToken?: string;
  refreshToken?: string;
}

export interface AuthSetPasswordAction {
  type: typeof AUTH_SAGA_SET_PASSWORD;
  password: string;
  history: any;
  errorCallback?: () => void;
}

export interface AuthRequestUserPasswordResetAction {
  type: typeof AUTH_SAGA_REQUEST_USER_PASSWORD_RESET;
  email: string;
}

export interface AuthLogoutAction {
  type: typeof AUTH_SAGA_LOGOUT;
}

export interface AuthReducerTypes {
  type: typeof AUTH_AUTHENTICATE_REQUEST |
    typeof AUTH_AUTHENTICATE_REQUEST |
    typeof AUTH_AUTHENTICATE_SUCCESS |
    typeof AUTH_AUTHENTICATE_FAILURE |
    typeof AUTH_SET_PASSWORD_REQUEST |
    typeof AUTH_SET_PASSWORD_SUCCESS |
    typeof AUTH_SET_PASSWORD_FAILURE |
    typeof AUTH_UPDATE_API_TOKEN |
    typeof AUTH_UPDATE_REFRESH_TOKEN |
    typeof AUTH_UNSET_TOKENS;
  apiToken: string;
  refreshToken: string;
  userName: string;
  userId: string;
  history: object;
}

import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { BottomNavigation, PaginationPage, PaginationSwitcher } from '..';
import {
  Accordion,
  HTMLContent,
  PageHeader,
  ProceedTypeButton,
  StartTitle,
} from '../../components';
import {
  proceedTypes,
  WorkUnitEstimate,
} from '../../connectors/ConnectedOpbrengstenDiagnose/ConnectedOpbrengstenDiagnose';
import content from '../../content';
import { ProceedType } from '../../data';
import getJustifiedScoresForOrganisation from '../../helpers/getJustifiedScoresForOrganisation';
import { translate } from '../../helpers/translations';
import AccordionProceedScoreElement from '../AccordionProceedScoreElement/AccordionProceedScoreElement';
import OpbrengstenTable from '../OpbrengstenTable/OpbrengstenTable';

import './OpbrengstenDiagnose.scss';

interface OpbrengstenDiagnoseProps {
  organization: string;
  givenEstimates: Partial<Record<ProceedType, WorkUnitEstimate>>;
  givenImportance: Partial<Record<ProceedType, number | null>>;
  chosenProceedType: ProceedType | null;
  onGivenEstimatesChange: (givenEstimates: Partial<Record<ProceedType, WorkUnitEstimate>>) => void;
  onGivenImportantChange: (givenImportance: Partial<Record<ProceedType, number | null>>) => void;
  onGivenEstimatesSubmit: () => void;
  onTableSubmit: () => void;
  onChosenProceedTypeChange: (proceedType: ProceedType) => void;
}

const OpbrengstenDiagnose: FC<OpbrengstenDiagnoseProps> = ({
  organization,
  givenEstimates,
  givenImportance,
  chosenProceedType,
  onGivenEstimatesChange,
  onGivenEstimatesSubmit,
  onTableSubmit,
  onGivenImportantChange,
  onChosenProceedTypeChange,
}): ReactElement => {
  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [organisationScores, setOrganisationScores] = useState<Record<ProceedType, number> | null>(null);
  const [nextButtonLock, setNextButtonLock] = useState(false);

  useEffect(() => {
    setOrganisationScores(getJustifiedScoresForOrganisation(organization));
  }, [organization]);

  useEffect(() => {
    if (activePaginationPage === 2) {
      const answersQuestionsLength = Object.values(givenEstimates)
        .filter((value) => (value && value.score))
        .length;

      setNextButtonLock(answersQuestionsLength !== proceedTypes.length);
    } else if (activePaginationPage === 3 && !chosenProceedType) {
      setNextButtonLock(true);
    } else {
      setNextButtonLock(false);
    }
  }, [activePaginationPage, givenEstimates, chosenProceedType]);

  const onBeforePaginationChange = (page: number) => {
    if (page === 3) {
      onGivenEstimatesSubmit();
    }
  };

  const onSubmit = () => {
    onTableSubmit();
  };

  const onSetOwnOrganisationProceedTypeScore = (score: number, proceedType: ProceedType) => {
    // @ts-ignore
    const comment = (givenEstimates && givenEstimates[proceedType]) ? givenEstimates[proceedType].comment : undefined;

    onGivenEstimatesChange({
      ...givenEstimates,
      [proceedType]: {
        comment,
        score,
      },
    });
  };

  const onSetOwnOrganisationProceedTypeComment = (comment: string, proceedType: ProceedType) => {
    const proceedTypeEstimate = givenEstimates[proceedType];

    onGivenEstimatesChange({
      ...givenEstimates,
      [proceedType]: {
        score: proceedTypeEstimate ? proceedTypeEstimate.score : undefined,
        comment,
      },
    });
  };

  const onProceedTypeImportanceChange = (importance: number | null, proceedType: ProceedType) => {
    onGivenImportantChange({
      ...givenImportance,
      [proceedType]: importance,
    });
  };

  const onProceedTypeButtonClick = (proceedType: ProceedType) => {
    onChosenProceedTypeChange(proceedType);
  };

  if (!organisationScores) {
    return (
      <span>Laden...</span>
    );
  }

  return (
    <div className="opbrengsten-diagnose">
      <StartTitle text="Diagnose" />
      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <PageHeader title="Scores eigen organisatie" />
          <HTMLContent html={content.opbrengsten.diagnose.pages.pageOne.intro} />
          <Accordion
            id="opbrengsten-diagnose-scores-eigen-organisatie"
            items={proceedTypes.map(proceedType => (
              {
                label: proceedType,
                element: <AccordionProceedScoreElement
                  intro={translate('opbrengsten.diagnose.pages.pageOne.proceedTypeIntro', { proceedType: proceedType.toLowerCase() })}
                  score={organisationScores[proceedType]}
                />,
              }
            ))}
          />
        </PaginationPage>
        <PaginationPage id={2}>
          <PageHeader title="Scores eigen werkeenheid" />
          <HTMLContent html={content.opbrengsten.diagnose.pages.pageTwo.intro} />
          <Accordion
            id="opbrengsten-diagnose-scores-eigen-werkeenheid"
            items={proceedTypes.map(proceedType => {
              const proceedTypeEstimate = givenEstimates[proceedType];

              return (
                {
                  label: proceedType,
                  element: <AccordionProceedScoreElement
                    intro={translate('opbrengsten.diagnose.pages.pageTwo.proceedTypeIntro', { proceedType: proceedType.toLowerCase() })}
                    proceedType={proceedType}
                    onScoreChange={onSetOwnOrganisationProceedTypeScore}
                    onCommentChange={onSetOwnOrganisationProceedTypeComment}
                    score={proceedTypeEstimate ? proceedTypeEstimate.score : undefined}
                    comment={proceedTypeEstimate ? proceedTypeEstimate.comment : undefined}
                  />,
                }
              );
            })}
          />
        </PaginationPage>
        <PaginationPage id={3}>
          <PageHeader title="Selectie van diversiteitsopbrengst(en) waarop je wilt  interveniëren" />
          <HTMLContent html={content.opbrengsten.diagnose.pages.pageThree.intro} />
          <HTMLContent html={content.opbrengsten.diagnose.pages.pageThree.title} />
          <OpbrengstenTable
            givenEstimates={givenEstimates}
            givenImportance={givenImportance}
            onProceedTypeImportanceChange={onProceedTypeImportanceChange}
          />
          <HTMLContent html={content.opbrengsten.diagnose.pages.pageThree.chooseProceedTypeIntro} />
          <HTMLContent html={content.opbrengsten.diagnose.pages.pageThree.chooseProceedTypeTitle} />
          <div className="opbrengsten-diagnose__proceed-type-buttons">
            { proceedTypes.map((proceedType) => (
              <ProceedTypeButton
                key={proceedType}
                className="opbrengsten-diagnose__proceed-type-button"
                proceedType={proceedType}
                isActive={proceedType === chosenProceedType}
                onClick={onProceedTypeButtonClick}
                text={proceedType}
              />
            )) }
          </div>
        </PaginationPage>
      </PaginationSwitcher>

      <BottomNavigation
        isForm
        paginationSize={3}
        lockNextButton={nextButtonLock}
        onPaginationChange={setActivePaginationPage}
        onBeforePaginationChange={onBeforePaginationChange}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default OpbrengstenDiagnose;

import React, { FC, FormEvent, ReactElement } from 'react';
import { Input } from '../index';

interface LoginFormProps {
  userEmail: string;
  userPassword: string;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onForgotPasswordClick: () => void;
  onUserEmailChange: (email: string) => void;
  onUserPasswordChange: (password: string) => void;
  loginFeedback?: string;
}

const LoginForm: FC<LoginFormProps> = ({
  userEmail,
  userPassword,
  onSubmit,
  onForgotPasswordClick,
  onUserEmailChange,
  onUserPasswordChange,
  loginFeedback,
}): ReactElement => (
  <>
    <form onSubmit={onSubmit} className="login-page__form">
      <fieldset className="login-page__form-fieldset">
        <legend className="login-page__form-legend">Inloggen</legend>
        <Input
          label="E-mailadres"
          type="email"
          onChange={onUserEmailChange}
          className="login-page__form-input"
        />
        <Input
          label="Wachtwoord"
          type="password"
          isPassword
          onChange={onUserPasswordChange}
          className="login-page__form-input"
        />
      </fieldset>
      {loginFeedback && <p className="login-page__form-error">{loginFeedback}</p>}
      <div className="login-page__form-buttons">
        <input
          type="submit"
          value="Inloggen"
          disabled={!userEmail || !userPassword}
          className="login-page__form-submit"
        />
      </div>
    </form>
    <p className="login-page__forgot-password">
      <span>
        Wachtwoord vergeten?
        <br />
          Klik
        <button
          type="button"
          onClick={onForgotPasswordClick}
          className="login-page__button-forgot-password"
        >
          hier
        </button>
          om een nieuw wachtwoord in te stellen.
      </span>
    </p>
  </>
);

export default LoginForm;

const benchmark = 'Benchmark (totaal)';

/* Rabobank */
const Rabobank = 'Rabobank';
const CentraleEenheden = 'Centrale eenheden';
// const LokaleBanken = 'Lokale banken';

/* Randstad Groep Nederland */
const RandstadGroepNederland = 'Randstad Groep Nederland';
const RandstadNederland = 'Randstad Nederland';
const TempoTeam = 'Tempo-Team';
const Yacht = 'Yacht';
const BMC = 'BMC';
const RGStaven = 'RG Staven';

/* PostNL */
const PostNL = 'PostNL';
const HoofdkantoorDenHaag = 'Hoofdkantoor Den Haag';
// const VoormaligHoofdkantoorHoofddorp = 'Voormalig hoofdkantoor Hoofddorp';
// const Productieomgeving = 'Productieomgeving';

/* Ministerie van Sociale Zaken en Werkgelegenheid */
const MinisterieVanSocialeZakenEnWerkgelegenheid = 'Ministerie van Sociale Zaken en Werkgelegenheid';
const PlvSecretarisGeneraal = 'Plv. Secretaris Generaal';
const SocialeZekerheidenIntegratie = 'Sociale Zekerheid en Integratie';
// const Werk = 'Werk';
// const InspectieSZW = 'Inspectie SZW';
const SecretarisGeneraal = 'Secretaris Generaal';

/* Polite */
const Politie = 'Politie';
const EenheidAmsterdam = 'Eenheid Amsterdam';
const EenheidDenHaag = 'Eenheid Den Haag';
const EenheidOostNederland = 'Eenheid Oost-Nederland';
const EenheidNoordHolland = 'Eenheid Noord-Holland';
const EenheidMiddenNederland = 'Eenheid Midden-Nederland';

// TODO: Enable types and fix all typescript errors in Score.tsx
// type SignificanceValue = string[] | null;
// type WorkUnit = Record<string, SignificanceValue>;
// type SubOrganisation = Record<string, WorkUnit | SignificanceValue>;
// type Organisation = Record<string, SubOrganisation | SignificanceValue>;
// type Competence = Record<string, Organisation | SignificanceValue>;
// type SignificanceWithProps = Record<ProceedType, Competence>;

/* eslint-disable quote-props */
const significanceWith: any = {
  inclusiefLeiderschap: {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: [benchmark],
        'Domein particulieren': [benchmark],
        'Domein CIO/COO': [benchmark],
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [RandstadGroepNederland, benchmark],
        'RUB': [benchmark],
        'RIS': [benchmark],
        'S&AM': null,
        'HRS': null,
        'RCS': null,
      },
      'Tempo-Team': {
        self: null,
        'TTU': [benchmark],
        'TTIS': [RandstadGroepNederland, benchmark],
        'S&AM, SPARQ, Payrolling, Business Consultancy': [TempoTeam, RandstadGroepNederland, benchmark],
      },
      'Yacht': {
        self: [benchmark],
        'SCS': null,
        'Finance & HLM': null,
        'Engineering & SCM&P': null,
        'IT': null,
      },
      'BMC': {
        self: [RandstadGroepNederland],
        'Implementatie': null,
        'Advies': [RandstadGroepNederland],
      },
      'RG Staven': {
        self: [RandstadGroepNederland],
        'Business Services': null,
        'Health@Work': null,
        'IT + CPM': [RGStaven, RandstadGroepNederland, benchmark],
        'Staven Overig': [RGStaven, benchmark],
      },
    },
    'PostNL': {
      self: null,
      'Hoofdkantoor Den Haag': {
        self: [PostNL],
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: [PostNL, benchmark],
      },
      'Productieomgeving': {
        self: null,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid],
        'OBP': null,
        'DSU': null,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid],
        'S&I': null,
        'WR': [MinisterieVanSocialeZakenEnWerkgelegenheid],
      },
      'Werk': {
        self: null,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'Toezicht': [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'M&M': [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: null,
        'FEZ': null,
        'C': null,
      },
    },
    'Politie': {
      self: [benchmark],
      'Eenheid Amsterdam': {
        self: [benchmark],
        'Centrum Burgwallen': [EenheidAmsterdam, Politie, benchmark],
        'Centrum Jordaan': [benchmark],
        'Boven IJ': null,
        'Zuid Buitenveldert': [EenheidAmsterdam, Politie],
      },
      'Eenheid Den Haag': {
        self: [benchmark],
        'Laak': [EenheidDenHaag, Politie],
        'Zoetermeer': [benchmark],
      },
      'Eenheid Oost-Nederland': {
        self: [benchmark],
        'Enschede': [benchmark],
        'Veluwe West': [benchmark],
      },
      'Eenheid Noord-Holland': {
        self: [benchmark],
        'Den Helder': [EenheidNoordHolland, Politie, benchmark],
        'Hoorn': [EenheidNoordHolland, Politie],
      },
      'Eenheid Midden-Nederland': {
        self: [Politie, benchmark],
        'Gooi en Vechtstreek Zuid': [benchmark],
        'Almere Stad Haven': [EenheidMiddenNederland, Politie, benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': [benchmark],
        'Utrecht-Centrum': [EenheidMiddenNederland],
        'Infrastructuur': [benchmark],
      },
    },
  },
  cultureleVeerkracht: {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: null,
        'Domein particulieren': null,
        'Domein CIO/COO': null,
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [benchmark],
        'RUB': [benchmark],
        'RIS': [RandstadNederland, RandstadGroepNederland],
        'S&AM': null,
        'HRS': [RandstadNederland, RandstadGroepNederland, benchmark],
        'RCS': [benchmark],
      },
      'Tempo-Team': {
        self: [benchmark],
        'TTU': [RandstadGroepNederland, benchmark],
        'TTIS': [benchmark],
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: [benchmark],
        'SCS': null,
        'Finance & HLM': [Yacht, RandstadGroepNederland, benchmark],
        'Engineering & SCM&P': null,
        'IT': null,
      },
      'BMC': {
        self: null,
        'Implementatie': null,
        'Advies': null,
      },
      'RG Staven': {
        self: [RandstadGroepNederland],
        'Business Services': null,
        'Health@Work': [RGStaven, RandstadGroepNederland, benchmark],
        'IT + CPM': [RGStaven, RandstadGroepNederland, benchmark],
        'Staven Overig': null,
      },
    },
    'PostNL': {
      self: [benchmark],
      'Hoofdkantoor Den Haag': {
        self: [PostNL, benchmark],
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: [benchmark],
      },
      'Productieomgeving': {
        self: [PostNL, benchmark],
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: null,
        'OBP': null,
        'DSU': [PlvSecretarisGeneraal, benchmark],
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: null,
        'S&I': null,
        'WR': null,
      },
      'Werk': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [benchmark],
        'Toezicht': [benchmark],
        'M&M': null,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'FEZ': [SecretarisGeneraal, MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'C': null,
      },
    },
    'Politie': {
      self: [benchmark],
      'Eenheid Amsterdam': {
        self: [benchmark],
        'Centrum Burgwallen': null,
        'Centrum Jordaan': null,
        'Boven IJ': null,
        'Zuid Buitenveldert': [EenheidAmsterdam],
      },
      'Eenheid Den Haag': {
        self: [benchmark],
        'Laak': null,
        'Zoetermeer': null,
      },
      'Eenheid Oost-Nederland': {
        self: null,
        'Enschede': [benchmark],
        'Veluwe West': null,
      },
      'Eenheid Noord-Holland': {
        self: null,
        'Den Helder': null,
        'Hoorn': [benchmark],
      },
      'Eenheid Midden-Nederland': {
        self: null,
        'Gooi en Vechtstreek Zuid': null,
        'Almere Stad Haven': null,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': null,
        'Utrecht-Centrum': null,
        'Infrastructuur': null,
      },
    },
  },
  'Werktevredenheid': {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: null,
        'Domein particulieren': null,
        'Domein CIO/COO': null,
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [RandstadGroepNederland],
        'RUB': null,
        'RIS': [RandstadNederland, RandstadGroepNederland],
        'S&AM': null,
        'HRS': [RandstadNederland, RandstadGroepNederland, benchmark],
        'RCS': null,
      },
      'Tempo-Team': {
        self: [benchmark],
        'TTU': [benchmark],
        'TTIS': null,
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: [benchmark],
        'SCS': [benchmark],
        'Finance & HLM': [benchmark],
        'Engineering & SCM&P': null,
        'IT': [benchmark],
      },
      'BMC': {
        self: [RandstadGroepNederland, benchmark],
        'Implementatie': [benchmark],
        'Advies': [benchmark],
      },
      'RG Staven': {
        self: null,
        'Business Services': null,
        'Health@Work': null,
        'IT + CPM': null,
        'Staven Overig': [RGStaven, RandstadGroepNederland, benchmark],
      },
    },
    'PostNL': {
      self: [benchmark],
      'Hoofdkantoor Den Haag': {
        self: [benchmark],
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: [benchmark],
      },
      'Productieomgeving': {
        self: [benchmark],
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: null,
      'Plv. Secretaris Generaal': {
        self: null,
        'OBP': null,
        'DSU': null,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: null,
        'S&I': null,
        'WR': null,
      },
      'Werk': {
        self: null,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: null,
        'Toezicht': null,
        'M&M': null,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: null,
        'FEZ': null,
        'C': null,
      },
    },
    'Politie': {
      self: null,
      'Eenheid Amsterdam': {
        self: null,
        'Centrum Burgwallen': null,
        'Centrum Jordaan': null,
        'Boven IJ': [EenheidAmsterdam, benchmark],
        'Zuid Buitenveldert': null,
      },
      'Eenheid Den Haag': {
        self: null,
        'Laak': null,
        'Zoetermeer': null,
      },
      'Eenheid Oost-Nederland': {
        self: [Politie, benchmark],
        'Enschede': [Politie, benchmark],
        'Veluwe West': null,
      },
      'Eenheid Noord-Holland': {
        self: null,
        'Den Helder': [Politie],
        'Hoorn': null,
      },
      'Eenheid Midden-Nederland': {
        self: null,
        'Gooi en Vechtstreek Zuid': null,
        'Almere Stad Haven': null,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': null,
        'Utrecht-Centrum': [benchmark],
        'Infrastructuur': null,
      },
    },
  },
  'Identificatie met de organisatie': {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: [Rabobank, benchmark],
        'Domein particulieren': null,
        'Domein CIO/COO': [Rabobank, benchmark],
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: null,
        'RUB': null,
        'RIS': [RandstadNederland, RandstadGroepNederland],
        'S&AM': null,
        'HRS': null,
        'RCS': null,
      },
      'Tempo-Team': {
        self: [benchmark],
        'TTU': [TempoTeam, RandstadGroepNederland, benchmark],
        'TTIS': null,
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: [benchmark],
        'SCS': null,
        'Finance & HLM': [Yacht, RandstadGroepNederland, benchmark],
        'Engineering & SCM&P': null,
        'IT': null,
      },
      'BMC': {
        self: null,
        'Implementatie': null,
        'Advies': null,
      },
      'RG Staven': {
        self: null,
        'Business Services': [RandstadGroepNederland],
        'Health@Work': null,
        'IT + CPM': [RGStaven, RandstadGroepNederland, benchmark],
        'Staven Overig': [RGStaven, RandstadGroepNederland, benchmark],
      },
    },
    'PostNL': {
      self: null,
      'Hoofdkantoor Den Haag': {
        self: null,
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: null,
      },
      'Productieomgeving': {
        self: null,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'OBP': [benchmark],
        'DSU': [PlvSecretarisGeneraal, MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: null,
        'S&I': null,
        'WR': [SocialeZekerheidenIntegratie, MinisterieVanSocialeZakenEnWerkgelegenheid],
      },
      'Werk': {
        self: [benchmark],
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [benchmark],
        'Toezicht': [benchmark],
        'M&M': [benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: null,
        'FEZ': [benchmark],
        'C': null,
      },
    },
    'Politie': {
      self: [benchmark],
      'Eenheid Amsterdam': {
        self: [Politie],
        'Centrum Burgwallen': [Politie],
        'Centrum Jordaan': null,
        'Boven IJ': null,
        'Zuid Buitenveldert': null,
      },
      'Eenheid Den Haag': {
        self: [benchmark],
        'Laak': [benchmark],
        'Zoetermeer': [benchmark],
      },
      'Eenheid Oost-Nederland': {
        self: [Politie, benchmark],
        'Enschede': [benchmark],
        'Veluwe West': [benchmark],
      },
      'Eenheid Noord-Holland': {
        self: [benchmark],
        'Den Helder': null,
        'Hoorn': [benchmark],
      },
      'Eenheid Midden-Nederland': {
        self: [benchmark],
        'Gooi en Vechtstreek Zuid': null,
        'Almere Stad Haven': [benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': null,
        'Utrecht-Centrum': [EenheidMiddenNederland, Politie, benchmark],
        'Infrastructuur': null,
      },
    },
  },
  'Ervaren sfeer in de werkomgeving': {
    'Rabobank': {
      self: [benchmark],
      'Centrale eenheden': {
        self: [Rabobank, benchmark],
        'Domein particulieren': [Rabobank, benchmark],
        'Domein CIO/COO': null,
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: null,
        'RUB': [RandstadNederland, benchmark],
        'RIS': [RandstadNederland, RandstadGroepNederland, benchmark],
        'S&AM': null,
        'HRS': null,
        'RCS': null,
      },
      'Tempo-Team': {
        self: null,
        'TTU': [TempoTeam, RandstadGroepNederland, benchmark],
        'TTIS': [TempoTeam],
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: [benchmark],
        'SCS': null,
        'Finance & HLM': [RandstadGroepNederland, benchmark],
        'Engineering & SCM&P': null,
        'IT': null,
      },
      'BMC': {
        self: [benchmark],
        'Implementatie': [benchmark],
        'Advies': null,
      },
      'RG Staven': {
        self: null,
        'Business Services': null,
        'Health@Work': null,
        'IT + CPM': [RGStaven, RandstadGroepNederland, benchmark],
        'Staven Overig': [RGStaven, RandstadGroepNederland, benchmark],
      },
    },
    'PostNL': {
      self: null,
      'Hoofdkantoor Den Haag': {
        self: [PostNL],
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: [benchmark],
      },
      'Productieomgeving': {
        self: [PostNL, benchmark],
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: null,
        'OBP': null,
        'DSU': null,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'S&I': null,
        'WR': [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
      },
      'Werk': {
        self: null,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'Toezicht': [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'M&M': [benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'FEZ': [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'C': null,
      },
    },
    'Politie': {
      self: null,
      'Eenheid Amsterdam': {
        self: [Politie],
        'Centrum Burgwallen': null,
        'Centrum Jordaan': null,
        'Boven IJ': null,
        'Zuid Buitenveldert': null,
      },
      'Eenheid Den Haag': {
        self: null,
        'Laak': null,
        'Zoetermeer': null,
      },
      'Eenheid Oost-Nederland': {
        self: [Politie, benchmark],
        'Enschede': [Politie, benchmark],
        'Veluwe West': null,
      },
      'Eenheid Noord-Holland': {
        self: null,
        'Den Helder': [EenheidNoordHolland, Politie, benchmark],
        'Hoorn': [EenheidNoordHolland, Politie, benchmark],
      },
      'Eenheid Midden-Nederland': {
        self: [Politie, benchmark],
        'Gooi en Vechtstreek Zuid': null,
        'Almere Stad Haven': [EenheidMiddenNederland, Politie, benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': null,
        'Utrecht-Centrum': null,
        'Infrastructuur': null,
      },
    },
  },
  'Ervaren carrièreperspectief': {
    'Rabobank': {
      self: [benchmark],
      'Centrale eenheden': {
        self: [benchmark],
        'Domein particulieren': [CentraleEenheden, Rabobank, benchmark],
        'Domein CIO/COO': [CentraleEenheden],
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [benchmark],
        'RUB': [RandstadNederland, benchmark],
        'RIS': null,
        'S&AM': null,
        'HRS': [RandstadNederland, RandstadGroepNederland],
        'RCS': null,
      },
      'Tempo-Team': {
        self: [benchmark],
        'TTU': null,
        'TTIS': [TempoTeam, RandstadGroepNederland, benchmark],
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: null,
        'SCS': null,
        'Finance & HLM': null,
        'Engineering & SCM&P': [Yacht, benchmark],
        'IT': null,
      },
      'BMC': {
        self: [benchmark],
        'Implementatie': [benchmark],
        'Advies': null,
      },
      'RG Staven': {
        self: null,
        'Business Services': null,
        'Health@Work': null,
        'IT + CPM': null,
        'Staven Overig': [RGStaven, RandstadGroepNederland, benchmark],
      },
    },
    'PostNL': {
      self: null,
      'Hoofdkantoor Den Haag': {
        self: null,
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': [HoofdkantoorDenHaag, PostNL, benchmark],
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: null,
      },
      'Productieomgeving': {
        self: null,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: null,
        'OBP': null,
        'DSU': [PlvSecretarisGeneraal, benchmark],
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid],
        'S&I': null,
        'WR': [MinisterieVanSocialeZakenEnWerkgelegenheid],
      },
      'Werk': {
        self: null,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'Toezicht': [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'M&M': [benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid],
        'FEZ': [MinisterieVanSocialeZakenEnWerkgelegenheid],
        'C': null,
      },
    },
    'Politie': {
      self: [Politie],
      'Eenheid Amsterdam': {
        self: [benchmark],
        'Centrum Burgwallen': [benchmark],
        'Centrum Jordaan': [EenheidAmsterdam, Politie, benchmark],
        'Boven IJ': [benchmark],
        'Zuid Buitenveldert': [benchmark],
      },
      'Eenheid Den Haag': {
        self: [benchmark],
        'Laak': [EenheidDenHaag, Politie],
        'Zoetermeer': null,
      },
      'Eenheid Oost-Nederland': {
        self: [benchmark],
        'Enschede': [benchmark],
        'Veluwe West': [EenheidOostNederland, Politie],
      },
      'Eenheid Noord-Holland': {
        self: [Politie, benchmark],
        'Den Helder': [EenheidNoordHolland, Politie, benchmark],
        'Hoorn': [benchmark],
      },
      'Eenheid Midden-Nederland': {
        self: [benchmark],
        'Gooi en Vechtstreek Zuid': [benchmark],
        'Almere Stad Haven': [benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': [benchmark],
        'Utrecht-Centrum': null,
        'Infrastructuur': [benchmark],
      },
    },
  },
  'Authenticiteit': {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: [Rabobank, benchmark],
        'Domein particulieren': [Rabobank, benchmark],
        'Domein CIO/COO': null,
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [benchmark],
        'RUB': [benchmark],
        'RIS': null,
        'S&AM': null,
        'HRS': [benchmark],
        'RCS': null,
      },
      'Tempo-Team': {
        self: [RandstadGroepNederland, benchmark],
        'TTU': [RandstadGroepNederland, benchmark],
        'TTIS': null,
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: null,
        'SCS': null,
        'Finance & HLM': null,
        'Engineering & SCM&P': null,
        'IT': null,
      },
      'BMC': {
        self: null,
        'Implementatie': null,
        'Advies': null,
      },
      'RG Staven': {
        self: [RandstadGroepNederland],
        'Business Services': [RandstadGroepNederland],
        'Health@Work': null,
        'IT + CPM': [RandstadGroepNederland],
        'Staven Overig': [RGStaven, RandstadGroepNederland, benchmark],
      },
    },
    'PostNL': {
      self: null,
      'Hoofdkantoor Den Haag': {
        self: null,
        'Den Haag HR': null,
        'Den Haag IT': [PostNL],
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: null,
      },
      'Productieomgeving': {
        self: [PostNL, benchmark],
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: null,
        'OBP': null,
        'DSU': [benchmark],
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: null,
        'S&I': null,
        'WR': null,
      },
      'Werk': {
        self: null,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'Toezicht': [MinisterieVanSocialeZakenEnWerkgelegenheid, benchmark],
        'M&M': [benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: null,
        'FEZ': null,
        'C': null,
      },
    },
    'Politie': {
      self: [benchmark],
      'Eenheid Amsterdam': {
        self: null,
        'Centrum Burgwallen': [benchmark],
        'Centrum Jordaan': null,
        'Boven IJ': null,
        'Zuid Buitenveldert': null,
      },
      'Eenheid Den Haag': {
        self: null,
        'Laak': null,
        'Zoetermeer': null,
      },
      'Eenheid Oost-Nederland': {
        self: null,
        'Enschede': [Politie],
        'Veluwe West': null,
      },
      'Eenheid Noord-Holland': {
        self: null,
        'Den Helder': [benchmark],
        'Hoorn': [EenheidNoordHolland, Politie],
      },
      'Eenheid Midden-Nederland': {
        self: [benchmark],
        'Gooi en Vechtstreek Zuid': null,
        'Almere Stad Haven': [benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': null,
        'Utrecht-Centrum': null,
        'Infrastructuur': null,
      },
    },
  },
  'Erkenning (eigenwaarde/zelfbewustzijn/zelfbeoordeling)': {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: null,
        'Domein particulieren': [CentraleEenheden, Rabobank, benchmark],
        'Domein CIO/COO': [CentraleEenheden],
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [benchmark],
        'RUB': [benchmark],
        'RIS': [RandstadNederland],
        'S&AM': null,
        'HRS': [RandstadGroepNederland, benchmark],
        'RCS': null,
      },
      'Tempo-Team': {
        self: [benchmark],
        'TTU': [RandstadGroepNederland, benchmark],
        'TTIS': null,
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: [benchmark],
        'SCS': null,
        'Finance & HLM': null,
        'Engineering & SCM&P': null,
        'IT': null,
      },
      'BMC': {
        self: [RandstadGroepNederland],
        'Implementatie': [RandstadGroepNederland],
        'Advies': null,
      },
      'RG Staven': {
        self: null,
        'Business Services': null,
        'Health@Work': null,
        'IT + CPM': [RGStaven],
        'Staven Overig': [RGStaven, RandstadGroepNederland, benchmark],
      },
    },
    'PostNL': {
      self: [benchmark],
      'Hoofdkantoor Den Haag': {
        self: null,
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: [benchmark],
      },
      'Productieomgeving': {
        self: [benchmark],
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: [benchmark],
        'OBP': [benchmark],
        'DSU': [benchmark],
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: null,
        'S&I': null,
        'WR': null,
      },
      'Werk': {
        self: null,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [benchmark],
        'Toezicht': [benchmark],
        'M&M': [benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: null,
        'FEZ': null,
        'C': null,
      },
    },
    'Politie': {
      self: [benchmark],
      'Eenheid Amsterdam': {
        self: null,
        'Centrum Burgwallen': null,
        'Centrum Jordaan': null,
        'Boven IJ': [Politie],
        'Zuid Buitenveldert': null,
      },
      'Eenheid Den Haag': {
        self: [Politie],
        'Laak': null,
        'Zoetermeer': null,
      },
      'Eenheid Oost-Nederland': {
        self: null,
        'Enschede': null,
        'Veluwe West': null,
      },
      'Eenheid Noord-Holland': {
        self: [benchmark],
        'Den Helder': [benchmark],
        'Hoorn': null,
      },
      'Eenheid Midden-Nederland': {
        self: [Politie, benchmark],
        'Gooi en Vechtstreek Zuid': null,
        'Almere Stad Haven': [benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': null,
        'Utrecht-Centrum': null,
        'Infrastructuur': null,
      },
    },
  },
  'Creativiteit': {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: null,
        'Domein particulieren': [CentraleEenheden, Rabobank, benchmark],
        'Domein CIO/COO': [CentraleEenheden, Rabobank, benchmark],
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [benchmark],
        'RUB': [benchmark],
        'RIS': null,
        'S&AM': null,
        'HRS': [RandstadNederland, RandstadGroepNederland, benchmark],
        'RCS': null,
      },
      'Tempo-Team': {
        self: [benchmark],
        'TTU': null,
        'TTIS': null,
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: [RandstadGroepNederland],
        'SCS': null,
        'Finance & HLM': null,
        'Engineering & SCM&P': null,
        'IT': [RandstadGroepNederland],
      },
      'BMC': {
        self: null,
        'Implementatie': null,
        'Advies': null,
      },
      'RG Staven': {
        self: [benchmark],
        'Business Services': null,
        'Health@Work': null,
        'IT + CPM': null,
        'Staven Overig': [RGStaven, RandstadGroepNederland, benchmark],
      },
    },
    'PostNL': {
      self: [benchmark],
      'Hoofdkantoor Den Haag': {
        self: [benchmark],
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': [benchmark],
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: null,
      },
      'Productieomgeving': {
        self: null,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: [benchmark],
        'OBP': [benchmark],
        'DSU': [benchmark],
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: null,
        'S&I': null,
        'WR': null,
      },
      'Werk': {
        self: [benchmark],
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [benchmark],
        'Toezicht': [benchmark],
        'M&M': [benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: [benchmark],
        'FEZ': null,
        'C': null,
      },
    },
    'Politie': {
      self: [benchmark],
      'Eenheid Amsterdam': {
        self: [benchmark],
        'Centrum Burgwallen': [benchmark],
        'Centrum Jordaan': [EenheidAmsterdam, Politie, benchmark],
        'Boven IJ': null,
        'Zuid Buitenveldert': [Politie],
      },
      'Eenheid Den Haag': {
        self: [benchmark],
        'Laak': null,
        'Zoetermeer': [benchmark],
      },
      'Eenheid Oost-Nederland': {
        self: [Politie, benchmark],
        'Enschede': [EenheidOostNederland, Politie],
        'Veluwe West': null,
      },
      'Eenheid Noord-Holland': {
        self: [benchmark],
        'Den Helder': [EenheidNoordHolland, Politie, benchmark],
        'Hoorn': [EenheidNoordHolland, Politie],
      },
      'Eenheid Midden-Nederland': {
        self: [Politie, benchmark],
        'Gooi en Vechtstreek Zuid': [benchmark],
        'Almere Stad Haven': [EenheidMiddenNederland, Politie, benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': [benchmark],
        'Utrecht-Centrum': null,
        'Infrastructuur': [EenheidMiddenNederland],
      },
    },
  },
  'Reflectie en besluitvorming': {
    'Rabobank': {
      self: null,
      'Centrale eenheden': {
        self: [Rabobank, benchmark],
        'Domein particulieren': null,
        'Domein CIO/COO': [Rabobank, benchmark],
      },
      'Lokale banken': {
        self: null,
      },
    },
    'Randstad Groep Nederland': {
      self: [benchmark],
      'Randstad Nederland': {
        self: [RandstadGroepNederland, benchmark],
        'RUB': [RandstadGroepNederland, benchmark],
        'RIS': [RandstadNederland],
        'S&AM': null,
        'HRS': null,
        'RCS': [benchmark],
      },
      'Tempo-Team': {
        self: null,
        'TTU': null,
        'TTIS': null,
        'S&AM, SPARQ, Payrolling, Business Consultancy': null,
      },
      'Yacht': {
        self: null,
        'SCS': null,
        'Finance & HLM': [Yacht],
        'Engineering & SCM&P': null,
        'IT': null,
      },
      'BMC': {
        self: [RandstadGroepNederland],
        'Implementatie': [BMC],
        'Advies': [RandstadGroepNederland],
      },
      'RG Staven': {
        self: [benchmark],
        'Business Services': [benchmark],
        'Health@Work': [RandstadGroepNederland, benchmark],
        'IT + CPM': null,
        'Staven Overig': null,
      },
    },
    'PostNL': {
      self: [benchmark],
      'Hoofdkantoor Den Haag': {
        self: [benchmark],
        'Den Haag HR': null,
        'Den Haag IT': null,
        'Den Haag CBS': null,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        self: [benchmark],
      },
      'Productieomgeving': {
        self: null,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      self: [benchmark],
      'Plv. Secretaris Generaal': {
        self: [benchmark],
        'OBP': null,
        'DSU': [benchmark],
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        self: [benchmark],
        'S&I': null,
        'WR': [benchmark],
      },
      'Werk': {
        self: [benchmark],
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        self: [benchmark],
        'Toezicht': [benchmark],
        'M&M': [benchmark],
        'AP&S': null,
      },
      'Secretaris Generaal': {
        self: null,
        'FEZ': null,
        'C': null,
      },
    },
    'Politie': {
      self: [benchmark],
      'Eenheid Amsterdam': {
        self: [benchmark],
        'Centrum Burgwallen': [benchmark],
        'Centrum Jordaan': [benchmark],
        'Boven IJ': null,
        'Zuid Buitenveldert': null,
      },
      'Eenheid Den Haag': {
        self: [benchmark],
        'Laak': null,
        'Zoetermeer': null,
      },
      'Eenheid Oost-Nederland': {
        self: [benchmark],
        'Enschede': null,
        'Veluwe West': null,
      },
      'Eenheid Noord-Holland': {
        self: [benchmark],
        'Den Helder': [benchmark],
        'Hoorn': [EenheidNoordHolland, Politie],
      },
      'Eenheid Midden-Nederland': {
        self: [Politie, benchmark],
        'Gooi en Vechtstreek Zuid': [benchmark],
        'Almere Stad Haven': [benchmark],
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': null,
        'Utrecht-Centrum': null,
        'Infrastructuur': null,
      },
    },
  },
};
/* eslint-enable quote-props */

export default significanceWith;

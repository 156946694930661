import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Reducers } from '../../redux/reducers';
import { Workplace } from '../../redux/user/userTypes';

import { MyOrganisationInfo, PageHeader } from '../../components';
import { BottomNavigation } from '../../containers';

import content from '../../content';

interface MapStateToPropsType {
  workplace: Workplace;
}

type StartMijnOrganisatieProps = RouteComponentProps & MapStateToPropsType;


const StartMijnOrganisatie: FC<StartMijnOrganisatieProps> = ({ workplace }): ReactElement => (
  <main className="page">
    <PageHeader title="Mijn Organisatie" />
    <h2>{content.start.mijnOrganisatie.title}</h2>
    <MyOrganisationInfo organization={workplace.organization} />
    <BottomNavigation />
  </main>
);


const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  workplace: state.userReducer.workplace,
});


export default connect(mapStateToProps)(StartMijnOrganisatie);

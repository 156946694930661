import React, {
  FC,
  FormEvent,
  ReactElement,
  useState,
} from 'react';

import { AnswerBubble, RadioInput } from '../../../components';
import '../QuizQuestion.scss';

import { QuizQuestionType } from '../QuizQuestion';


const QuizRadio: FC<QuizQuestionType> = ({
  questionNumber,
  defaultValue,
  name,
  question,
  answer,
  answerImages,
  options,
  onChange,
  onSubmit,
  disabled = false,
  className = '',
}): ReactElement => {
  const [questionInput, setQuestionInput] = useState(defaultValue || '');
  const [hasSubmit, setSubmit] = useState(false);

  const handleChange = (value: string): void => {
    setQuestionInput(value);

    if (onChange) {
      onChange(value);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setSubmit(true);
    if (onSubmit) {
      onSubmit(questionInput);
    }
  };

  if (!name || !options) {
    return <p className="quiz-question__error">Error: Vraag heeft geen name of options</p>;
  }

  return (
    <form onSubmit={handleSubmit} className={`quiz-question ${className}`}>
      {questionNumber && <legend className="quiz-question__chapeau">{`Vraag ${questionNumber}`}</legend>}

      <label className="quiz-question__label">{question}</label>

      {options.map((option): ReactElement => {
        const defaultChecked = option === defaultValue;
        return (
          <RadioInput
            key={option}
            defaultChecked={defaultChecked}
            label={option}
            value={option}
            name={name}
            disabled={disabled || hasSubmit}
            onChange={handleChange}
          />
        );
      })}
      {(answer && hasSubmit) && <AnswerBubble htmlContent={answer} images={answerImages} />}

      {(answer && !hasSubmit) && (
        <input
          type="submit"
          value={answer ? 'Bekijk antwoord' : 'Verstuur'}
          disabled={!questionInput}
          className="quiz-question__submit-button"
        />
      )}
    </form>
  );
};


export default QuizRadio;

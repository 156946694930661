import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { DrijfverenMijnDrijfveer } from '../../containers';
import useMotivation from '../../helpers/useMotivation';
import { Reducers } from '../../redux/reducers';

const ConnectedDrijfverenMijnDrijfveer: FC = (): ReactElement => {
  const motivations = useSelector((state: Reducers) => state.userReducer.motivations);
  const motivationsAPI = useMotivation();
  const motivationAPIHasValues = motivationsAPI
    && (Number(motivationsAPI.attributes.qualityPercentage)
      + Number(motivationsAPI.attributes.justicePercentage)
      + Number(motivationsAPI.attributes.accessPercentage)
      + Number(motivationsAPI.attributes.learningPercentage) > 0);

  const userMotivation = (motivationsAPI && motivationAPIHasValues) ? {
    kwaliteit: Number(motivationsAPI.attributes.qualityPercentage),
    rechtvaardigheid: Number(motivationsAPI.attributes.justicePercentage),
    toegang: Number(motivationsAPI.attributes.accessPercentage),
    leren: Number(motivationsAPI.attributes.learningPercentage),
  } : motivations;

  if (!userMotivation) {
    return <span>Laden...</span>;
  }

  return (
    <DrijfverenMijnDrijfveer motivations={userMotivation} />
  );
};

export default ConnectedDrijfverenMijnDrijfveer;

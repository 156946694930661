import React, {
  ChangeEvent,
  FC,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import './Pagination.scss';

interface PaginationProps {
  amountOfPages: number;
  currentPage: number;
  visibleMargin?: number;
  unblocked?: boolean;
  onChange: (value: number) => void;
  className?: string;
}


const Pagination: FC<PaginationProps> = ({
  amountOfPages,
  currentPage,
  visibleMargin = 2,
  unblocked,
  onChange,
  className = '',
}): ReactElement => {
  const [activePage, setActivePage] = useState<number>(currentPage);
  const [furthestPage, setFurthestPage] = useState<number>(currentPage);

  useEffect((): void => {
    if ((currentPage <= amountOfPages) && (currentPage !== activePage)) {
      setActivePage(currentPage);
      onChange(currentPage);
    }
  }, [currentPage, amountOfPages, onChange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    if (activePage > furthestPage) {
      setFurthestPage(activePage);
    }
  }, [activePage, furthestPage]);

  const visiblePagesPrev = activePage - visibleMargin < 0 ? 0 : (activePage - visibleMargin) - 1;
  const visiblePagesNext = activePage + visibleMargin > amountOfPages ? amountOfPages : activePage + visibleMargin;

  const pages = Array.from(Array(amountOfPages).keys()).map(page => page + 1);
  const cleanPages = pages.slice(1, -1);
  const pagesCompact = [...cleanPages].splice(visiblePagesPrev - 1 < 0 ? 0 : visiblePagesPrev - 1, visiblePagesNext - visiblePagesPrev);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => event.preventDefault();
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setActivePage(Number(event.currentTarget.value));
    onChange(Number(event.currentTarget.value));
  };

  return (
    <form onSubmit={handleSubmit} className={`pagination ${className}`}>
      <div className="pagination__item">
        <input
          id="pagination-1"
          type="radio"
          name="pagination"
          value={1}
          checked={activePage === 1}
          className="pagination__input"
          onChange={handleChange}
        />
        <label htmlFor="pagination-1" className="pagination__button">1</label>
      </div>

      {visiblePagesPrev >= 2 && <div className="pagination__item">…</div>}

      {pagesCompact.map((num): ReactElement => (
        <div key={num} className="pagination__item">
          <input
            id={`pagination-${num}`}
            type="radio"
            name="pagination"
            value={num}
            checked={activePage === num}
            disabled={!unblocked && num > furthestPage}
            className="pagination__input"
            onChange={handleChange}
          />
          <label htmlFor={`pagination-${num}`} className="pagination__button">{num}</label>
        </div>
      ))}

      {visiblePagesNext <= amountOfPages - 2 && <div className="pagination__item">…</div>}

      <div className="pagination__item">
        <input
          id={`pagination-${amountOfPages}`}
          type="radio"
          name="pagination"
          value={amountOfPages}
          checked={activePage === amountOfPages}
          disabled={!unblocked && amountOfPages > furthestPage}
          className="pagination__input"
          onChange={handleChange}
        />
        <label htmlFor={`pagination-${amountOfPages}`} className="pagination__button">{amountOfPages}</label>
      </div>
    </form>
  );
};


export default Pagination;

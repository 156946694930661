import React, { FC, FormEvent, ReactElement } from 'react';

import { convertStringToDash } from '../../helpers/tools';

import './LikertScale.scss';

interface LikertScaleProps {
  data?: any;
  theme?: string;
  name: string;
  questions: string[];
  defaultValues?: Array<number | undefined>;
  labelLeast?: string;
  labelMost?: string;
  onChange?: (question: string, range: number) => void;
  disabled?: boolean;
  className?: string;
}


const LikertScale: FC<LikertScaleProps> = ({
  data,
  theme,
  name,
  questions,
  defaultValues,
  labelLeast = 'Helemaal mee oneens',
  labelMost = 'Helemaal mee eens',
  onChange,
  disabled,
  className = '',
}): ReactElement => {
  const rangeValues = [1, 2, 3, 4, 5];
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => event.preventDefault();

  return (
    <section className={`likert-scale ${className}`}>
      <header className="likert-scale__header">
        {theme && <h2 className="likert-scale__theme-title">{`${theme}…`}</h2>}
        <div className={`likert-scale__rating-container ${!theme ? 'likert-scale__rating-container--align-right' : ''}`}>
          {rangeValues.map((value): ReactElement => (
            <div key={value} className="likert-scale__rating-column">
              {value === 1 && <p className="likert-scale__rating-text-label">{labelLeast}</p>}
              {value === 5 && <p className="likert-scale__rating-text-label">{labelMost}</p>}
              <p className="likert-scale__rating-text-label">{value}</p>
            </div>
          ))}
        </div>
      </header>
      <form onSubmit={handleSubmit} className="likert-scale__form">
        {questions.map((question, questionIndex): ReactElement => (
          <div key={`${theme}-${question}`} className="likert-scale__question-container">
            <div className="likert-scale__question">
              {theme ? `…${question}` : question}
            </div>
            <div className="likert-scale__rating-container">
              {rangeValues.map((option): ReactElement => {
                const handleChange = (): void => {
                  if (onChange) {
                    onChange(question, option);
                  }
                };

                const defaultCheckedREDUX = data ? Number(data[convertStringToDash(`${name}-${question}`)]) === option : undefined;
                const defaultCheckedAPI = defaultValues ? defaultValues[questionIndex] === option : undefined;

                return (
                  <div key={option} className="likert-scale__rating-column">
                    <input
                      id={`${name}-${question}-${option}`}
                      type="radio"
                      name={convertStringToDash(`${name}-${question}`)}
                      value={option}
                      defaultChecked={defaultCheckedREDUX || defaultCheckedAPI}
                      onChange={handleChange}
                      disabled={disabled}
                      className="likert-scale__rating-input"
                    />
                    <label htmlFor={`${name}-${question}-${option}`} className="likert-scale__rating-input-label">
                      <div className="likert-scale__rating-indication-box" />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </form>
    </section>
  );
};


export default LikertScale;

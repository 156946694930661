import React, {FC, ReactElement, useState} from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Reducers } from '../../redux/reducers';
import { Workplace } from '../../redux/user/userTypes';

import { HTMLContent, PageHeader } from '../../components';
import {BottomNavigation, PaginationPage, PaginationSwitcher} from '../../containers';

import content from '../../content';

interface MapStateToPropsType {
  workplace: Workplace;
}

type DrijfverenMijnOrganisatieProps = RouteComponentProps & MapStateToPropsType;

// TODO: Toevoegen ‘herhaling van de filmpjes Startmodule’

const DrijfverenMijnOrganisatie: FC<DrijfverenMijnOrganisatieProps> = ({ workplace }): ReactElement => {
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  return (
    <main className="page">
      <PageHeader title="Mijn Organisatie" />
      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <HTMLContent html={content.drijfveren.mijnOrganisatie.pageOne.intro} />
          {workplace.organization && <HTMLContent html={content.drijfveren.mijnOrganisatie.pageOne.organizations[workplace.organization]} />}
        </PaginationPage>
        <PaginationPage id={2}>
          <HTMLContent html={content.drijfveren.mijnOrganisatie.pageTwo.intro} />
        </PaginationPage>
      </PaginationSwitcher>
      <BottomNavigation
        paginationSize={2}
        onPaginationChange={setActivePaginationPage}
      />
    </main>
  );
};


const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  workplace: state.userReducer.workplace,
});

export default connect(mapStateToProps)(DrijfverenMijnOrganisatie);

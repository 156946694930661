import { dynamicRouteModules } from '../../Routes';

export interface LocationIndexes {
  moduleIndex: number;
  pageIndex: number;
}

export default function retrieveLocationIndexes(module: string, page: string): LocationIndexes {
  const moduleIndex = Math.max(dynamicRouteModules.findIndex(route => route.slug === module), 0);
  const pageIndex = dynamicRouteModules[moduleIndex]
    ? Math.max(dynamicRouteModules[moduleIndex].pages.findIndex(route => route.slug === page), 0)
    : 0;

  return { moduleIndex, pageIndex };
}

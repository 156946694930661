import React, { FC, ReactElement } from 'react';
import { OpbrengstenDiversiteit } from '../../containers';

const Opbrengsten: FC = (): ReactElement => (
  <main className="page">
    <OpbrengstenDiversiteit />
  </main>
);

export default Opbrengsten;

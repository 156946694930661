import japi from './userJapi';

const endpoint = 'users';

export interface User {
  attributes: {
    respondentNumber: number;
    email: string;
    lastVisitedPage: string | null;
  };
  relationships: {
    organization: {
      data: {
        type: 'organisations';
        id: string;
      };
    };
    subOrganization: {
      data: {
        type: 'sub_organizations';
        id: string;
      };
    };
    workUnit: {
      data: {
        type: 'work_unit';
        id: string;
      };
    };
  };
}


export const users = japi(endpoint, 'users');

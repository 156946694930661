import React, { FC, ReactElement, useState } from 'react';

import {
  BottomNavigation,
  ModuleSummary,
  OverviewToolTable,
  PaginationPage,
  PaginationSwitcher,
} from '..';
import {
  Accordion,
  HTMLContent,
  PageHeader,
} from '../../components';
import content from '../../content';

import './StartOverviewTool.scss';


const StartOverviewTool: FC = (): ReactElement => {
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  return (
    <main className="page">
      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <PageHeader title="Welkom!" />
          <HTMLContent html={content.start.overviewTool.pageOne.intro} />
        </PaginationPage>
        <PaginationPage id={2}>
          <PageHeader title="Meer kleur aan de top" />
          <HTMLContent html={content.start.overviewTool.pageTwo.intro} />
          <Accordion
            id="start-overview-tool-rechtvaardigheid"
            items={[
              { label: 'Waarom?', htmlContent: content.start.overviewTool.pageTwo.rationale },
            ]}
          />
        </PaginationPage>
        <PaginationPage id={3}>
          <PageHeader title="Tijdspad" />
          <HTMLContent className="start-overview-tool__page-three-intro" html={content.start.overviewTool.pageThree.intro} />
          <img className="start-overview-tool__overview-image" alt="overview tool" src="/images/overview.png" />

          <Accordion
            id="start-overview-tool-opbouw-tool"
            items={[
              {
                label: content.start.overviewTool.pageThree.components.label,
                element: (
                  <>
                    <HTMLContent html={content.start.overviewTool.pageThree.structure} />
                    <OverviewToolTable />
                    <ModuleSummary
                      intro={content.start.overviewTool.pageThree.components.intro}
                      diagnose={content.start.overviewTool.pageThree.components.diagnose}
                      theory={content.start.overviewTool.pageThree.components.theory}
                      practice={content.start.overviewTool.pageThree.components.practice}
                      outro={content.start.overviewTool.pageThree.components.outro}
                    />
                  </>
                ),
              },
              {
                label: content.start.overviewTool.pageThree.module1.label,
                element: (
                  <ModuleSummary
                    intro={content.start.overviewTool.pageThree.module1.intro}
                    diagnose={content.start.overviewTool.pageThree.module1.diagnose}
                    theory={content.start.overviewTool.pageThree.module1.theory}
                    practice={content.start.overviewTool.pageThree.module1.practice}
                    estimationInMinutes={content.start.overviewTool.pageThree.module1.estimationInMinutes}
                  />
                ),
              },
              {
                label: content.start.overviewTool.pageThree.module2.label,
                element: (
                  <ModuleSummary
                    intro={content.start.overviewTool.pageThree.module2.intro}
                    diagnose={content.start.overviewTool.pageThree.module2.diagnose}
                    theory={content.start.overviewTool.pageThree.module2.theory}
                    practice={content.start.overviewTool.pageThree.module2.practice}
                    estimationInMinutes={content.start.overviewTool.pageThree.module2.estimationInMinutes}
                  />
                ),
              },
              {
                label: content.start.overviewTool.pageThree.module3.label,
                element: (
                  <ModuleSummary
                    intro={content.start.overviewTool.pageThree.module3.intro}
                    diagnose={content.start.overviewTool.pageThree.module3.diagnose}
                    theory={content.start.overviewTool.pageThree.module3.theory}
                    practice={content.start.overviewTool.pageThree.module3.practice}
                    estimationInMinutes={content.start.overviewTool.pageThree.module3.estimationInMinutes}
                  />
                ),
              },
              {
                label: content.start.overviewTool.pageThree.module4.label,
                element: (
                  <ModuleSummary
                    intro={content.start.overviewTool.pageThree.module4.intro}
                    practice={content.start.overviewTool.pageThree.module4.practice}
                    estimationInHours={content.start.overviewTool.pageThree.module4.estimationInHours}
                  />
                ),
              },
            ]}
          />
        </PaginationPage>
      </PaginationSwitcher>

      <BottomNavigation
        paginationSize={3}
        onPaginationChange={setActivePaginationPage}
        hidePrevButton={activePaginationPage === 1}
      />
    </main>
  );
};

export default StartOverviewTool;

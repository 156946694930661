import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { Button } from '../../components';
import './BottomNavigationWithExitButton.scss';

interface BottomNavigationWithExitButtonProps {
  className?: string;
  isLastModule?: boolean;
}


const BottomNavigationWithExitButton: FC<BottomNavigationWithExitButtonProps> = ({ className, isLastModule = false }): ReactElement => (
  <div className={`bottom-navigation-with-exit-button ${className}`}>
    <BottomNavigation
      isLastPageOfModule
      hideNextButton={isLastModule}
      className="bottom-navigation-with-exit-button__bottom-navigation"
    />
    <Button
      text="Opslaan en afsluiten"
      path="/dashboard"
      className="bottom-navigation-with-exit-button__save-and-exit-button"
    />
  </div>
);

export default BottomNavigationWithExitButton;

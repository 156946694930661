import React, { FC, ReactElement } from 'react';

import { BottomNavigationWithExitButton } from '..';
import { HTMLContent, StartTitle } from '../../components';
import content from '../../content';


const AanDeSlagAfronding: FC = (): ReactElement => (
  <>
    <StartTitle text="Einde tool" />
    <HTMLContent html={content.aanDeSlag.afronding.intro} />

    <BottomNavigationWithExitButton isLastModule />
  </>
);

export default AanDeSlagAfronding;

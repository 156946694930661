import React, { FC, FormEvent, ReactElement } from 'react';
import { Button, Input } from '../index';

interface LoginFormProps {
  userEmail: string;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onUserEmailChange: (email: string) => void;
  onBackButtonClick: () => void;
  submitted: boolean;
}

const LoginPasswordForm: FC<LoginFormProps> = ({
  userEmail,
  onSubmit,
  onUserEmailChange,
  onBackButtonClick,
  submitted,
}): ReactElement => (
  <form onSubmit={onSubmit} className="login-page__form">
    <fieldset className="login-page__form-fieldset">
      <legend className="login-page__form-legend">Wachtwoord herstellen</legend>
      {!submitted ? (
        <>
          <p>Naar welk mailadres mogen wij de link sturen?</p>
          <Input
            label="E-mailadres"
            type="email"
            onChange={onUserEmailChange}
            className="login-page__form-input"
          />
        </>
      ) : (
        <p>{`Als ${userEmail} bij ons bekend is zullen we een e-mail sturen met daarin instructies om een nieuw wachwoord aan te maken`}</p>
      )}
    </fieldset>
    <div className="login-page__form-buttons">
      <Button
        type="button"
        onClick={onBackButtonClick}
        className="login-page__button-back"
        icon="chevron-left"
        iconPos="left"
        text="Terug naar inloggen"
      />
      <input
        type="submit"
        value="Versturen"
        disabled={!userEmail || submitted}
        className="login-page__form-submit"
      />
    </div>
  </form>
);

export default LoginPasswordForm;

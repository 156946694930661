import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import {
  BottomNavigation,
  PaginationPage,
  PaginationSwitcher,
  QuizQuestion,
} from '..';
import { HTMLContent, PageHeader } from '../../components';

import content from '../../content';
import { SingleJsonApiResponseType } from '../../fetches/JsonApiResponse';
import getQuizQuestion2Answer from './helpers/getQuizQuestion2Answer';
import getQuizQuestion3Answer from './helpers/getQuizQuestion3Answer';

type Question = SingleJsonApiResponseType<'questions'>['data'] | undefined;

interface StartCultureleDiversiteitProps {
  data: any;
  questionOne: Question;
  questionTwo: Question;
  questionThree: Question;
  onPrevPaginationPageChange: (pageRef: React.RefObject<HTMLDivElement>) => void;
  onPageFinished: (questionOneAnswer: string, questionTwoAnswer: string, questionThreeAnswer: string) => void;
}


const StartCultureleDiversiteit: FC<StartCultureleDiversiteitProps> = ({
  data,
  questionOne,
  questionTwo,
  questionThree,
  onPrevPaginationPageChange,
  onPageFinished,
}): ReactElement => {
  const [finishedPageState, setFinishedPageState] = useState(false);

  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [prevPaginationPage, setBeforePaginationChange] = useState(1);

  const [questionOneAnswer, setQuestionOneAnswer] = useState<string>('');
  const [questionTwoAnswer, setQuestionTwoAnswer] = useState<string>('');
  const [questionThreeAnswer, setQuestionThreeAnswer] = useState<string>('');

  const pageRef = React.createRef<HTMLDivElement>();

  useEffect((): void => {
    onPrevPaginationPageChange(pageRef);
  }, [prevPaginationPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkNextButtonLock = (): boolean => {
    if (activePaginationPage === 1) {
      return questionOneAnswer !== undefined && questionOneAnswer.length === 0;
    }
    if (activePaginationPage === 2) {
      return questionTwoAnswer !== undefined && questionTwoAnswer.length === 0;
    }
    if (activePaginationPage === 3) {
      return questionThreeAnswer !== undefined && questionThreeAnswer.length === 0;
    }

    return false;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect((): void => {
    if (
      questionOne
      && questionTwo
      && questionThree
    ) {
      if (
        questionOne.attributes.answer
        && questionTwo.attributes.answer
        && questionThree.attributes.answer
      ) {
        setFinishedPageState(true);
      }
    }
  });

  const finishedPage = data.finshed || finishedPageState;

  const submitData = (): void => {
    if (!finishedPage) {
      onPageFinished(questionOneAnswer, questionTwoAnswer, questionThreeAnswer);
    }
  };

  const nextButtonLock = checkNextButtonLock();

  return (
    <main ref={pageRef} className="page">
      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <PageHeader title="Culturele diversiteit" />
          <HTMLContent html={content.start.cultureleDiversiteit.intro} />
          {questionOne !== undefined ? (
            <QuizQuestion
              data={data}
              type="textarea"
              questionNumber={1}
              name="start-culturele-diversiteit-question-1"
              defaultValue={questionOne.attributes.answer}
              question={content.start.cultureleDiversiteit.questionOne.question}
              answer={content.start.cultureleDiversiteit.questionOne.answer}
              onChange={setQuestionOneAnswer}
              disabled={finishedPage}
            />
          ) : <span>Laden...</span>}
        </PaginationPage>
        <PaginationPage id={2}>
          <PageHeader title="Culturele diversiteit" />
          {questionTwo !== undefined ? (
            <QuizQuestion
              data={data}
              type="radio"
              questionNumber={2}
              name="start-culturele-diversiteit-question-2"
              defaultValue={questionTwo.attributes.answer}
              question={content.start.cultureleDiversiteit.questionTwo.question}
              options={[
                content.start.cultureleDiversiteit.questionTwo.optionOne,
                content.start.cultureleDiversiteit.questionTwo.optionTwo,
              ]}
              answer={getQuizQuestion2Answer(questionTwo.attributes.answer || questionTwoAnswer)}
              answerImages={[
                '/images/start_culturele-diversiteit_antwoord-2_1.jpg',
                '/images/start_culturele-diversiteit_antwoord-2_2.jpg',
                '/images/start_culturele-diversiteit_antwoord-2_3.jpg',
              ]}
              onChange={setQuestionTwoAnswer}
              disabled={finishedPage}
            />
          ) : <span>Laden...</span>}
        </PaginationPage>
        <PaginationPage id={3}>
          <PageHeader title="Culturele diversiteit" />
          {questionThree !== undefined ? (
            <QuizQuestion
              data={data}
              type="radio"
              questionNumber={3}
              name="start-culturele-diversiteit-question-3"
              defaultValue={questionThree.attributes.answer}
              question={content.start.cultureleDiversiteit.questionThree.question}
              options={[
                content.start.cultureleDiversiteit.questionThree.optionOne,
                content.start.cultureleDiversiteit.questionThree.optionTwo,
                content.start.cultureleDiversiteit.questionThree.optionThree,
              ]}
              answer={getQuizQuestion3Answer(questionThree.attributes.answer || questionThreeAnswer)}
              onChange={setQuestionThreeAnswer}
              disabled={finishedPage}
            />
          ) : <span>Laden...</span>}
        </PaginationPage>
      </PaginationSwitcher>
      <BottomNavigation
        paginationSize={3}
        isForm={!finishedPage}
        unblockedPagination={finishedPage}
        lockNextButton={nextButtonLock}
        onPaginationChange={setActivePaginationPage}
        onBeforePaginationChange={setBeforePaginationChange}
        onSubmit={submitData}
      />
    </main>
  );
};

export default StartCultureleDiversiteit;

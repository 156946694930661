import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { AanDeSlagAfronding } from '../../containers';

type AanDeSlagPaginaNaAfrondingProps = RouteComponentProps;


const AanDeSlagPaginaNaAfronding: FC<AanDeSlagPaginaNaAfrondingProps> = (): ReactElement => (
  <main className="page">
    <AanDeSlagAfronding />
  </main>
);


export default AanDeSlagPaginaNaAfronding;

export default function getNextButtonText(
  isLastPageOfModule: boolean,
  activePaginationPage: number,
  paginationSize?: number,
): string {
  if (isLastPageOfModule && (paginationSize === undefined || activePaginationPage === paginationSize)) {
    return 'Volgende module';
  }

  return 'Volgende';
}

import React, { FC, ReactElement } from 'react';
import { DurationEstimation } from '../../components';
import './ModuleEstimation.scss';

interface ModuleEstimationProps {
  description?: string;
  minutes?: number;
  hours?: number;
  className?: string;
}

const ModuleEstimation: FC<ModuleEstimationProps> = ({
  description,
  minutes,
  hours,
  className = '',
}): ReactElement => (
  <div className={`module-estimation ${className}`}>
    {description && (
      <p>{description}</p>
    )}
    <DurationEstimation className="module-estimation__duration" hours={hours} minutes={minutes} />
  </div>
);

export default ModuleEstimation;

import japi from './japi';

const endpoint = 'statements';

export interface Statement {
  attributes: {
    firstStatement: string;
    secondStatement: string;
    selectedStatement: string;
  };
  relationships: {
    user: {
      data: {
        type: 'users';
        id: string;
      };
    };
  };
}

export const statements = japi(endpoint, 'statements');

import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { isType } from 'typescript-fsa';
import { GetListPayload } from '../../fetches/JsonApiRequest';
import { ManyJsonApiResponseType, SingleJsonApiResponseType } from '../../fetches/JsonApiResponse';
import { questions } from '../../fetches/questions';
import { Reducers } from '../reducers';
import { getOrCreateQuestion } from './questionsActions';

function* getOrCreateQuestionSaga(action: Action) {
  if (!isType(action, getOrCreateQuestion.started)) {
    return;
  }

  yield put(getOrCreateQuestion.done({
    result: undefined,
    params: action.payload,
  }));

  const userId = (yield select((state: Reducers) => state.authReducer.userId)) as Reducers['authReducer']['userId'];

  const payload: GetListPayload = {
    filters: {
      page: action.payload.page,
      number: action.payload.number,
      'user.id': userId.toString(),
    },
  };

  const fetchResponse = (yield call(() => questions.fetch(payload))) as AxiosResponse<ManyJsonApiResponseType<'questions'>>;

  let result: SingleJsonApiResponseType<'questions'>['data'];
  const { data } = fetchResponse.data;

  if (data.length === 0) {
    const postQuestionResponse = (yield call(() => questions.post({
      attributes: {
        page: action.payload.page,
        number: action.payload.number,
        answer: '',
      },
      relationships: {
        user: {
          data: {
            type: 'users',
            id: userId.toString(),
          },
        },
      },
    }))) as AxiosResponse<SingleJsonApiResponseType<'questions'>>;

    result = postQuestionResponse.data.data;
  } else {
    result = data[data.length - 1];
  }

  yield put(getOrCreateQuestion.done({
    result,
    params: action.payload,
  }));
}

export default function* questionWatcher() {
  yield takeEvery(getOrCreateQuestion.started, getOrCreateQuestionSaga);
}

import React, { FC, ReactElement, useState } from 'react';

import { BottomNavigation, PaginationPage, PaginationSwitcher } from '..';
import { Accordion, HTMLContent, PageHeader } from '../../components';
import content from '../../content';

const SuccesfactorenToelichting: FC = (): ReactElement => {
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  return (
    <>
      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <PageHeader title="Achtergrond" />
          <HTMLContent html={content.succesfactoren.toelichting.pageOne.intro} />
        </PaginationPage>
        <PaginationPage id={2}>
          <PageHeader title="Uitleg begrippen" />
          <Accordion
            id="succesfactoren-toelichting-uitleg-begrippen"
            items={[
              {
                label: 'Inclusief leiderschap',
                element: (
                  <>
                    <iframe
                      title="Inclusief leiderschap"
                      src={content.succesfactoren.toelichting.pageTwo.inclusiefLeiderschap.videoUrl}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                    <Accordion
                      id="succesfactoren-toelichting-inclusief-leiderschap-video"
                      items={[
                        {
                          label: content.succesfactoren.toelichting.pageTwo.inclusiefLeiderschap.videoButton,
                          htmlContent: content.succesfactoren.toelichting.pageTwo.inclusiefLeiderschap.videoTranscript,
                        },
                      ]}
                    />
                  </>
                ),
              },
              {
                label: 'Culturele veerkracht',
                element: (
                  <>
                    <iframe
                      title="Culturele veerkracht"
                      src={content.succesfactoren.toelichting.pageTwo.cultureleVeerkracht.videoUrl}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                    <Accordion
                      id="succesfactoren-toelichting-culturele-veerkracht-video"
                      items={[
                        {
                          label: content.succesfactoren.toelichting.pageTwo.cultureleVeerkracht.videoButton,
                          htmlContent: content.succesfactoren.toelichting.pageTwo.cultureleVeerkracht.videoTranscript,
                        },
                      ]}
                    />
                  </>
                ),
              },
            ]}
          />
        </PaginationPage>
      </PaginationSwitcher>

      <BottomNavigation
        paginationSize={2}
        onPaginationChange={setActivePaginationPage}
      />
    </>
  );
}

export default SuccesfactorenToelichting;

import React, { FC, ReactElement } from 'react';
import { DrijfverenOpdracht } from '../../containers';

const Opdracht: FC = (): ReactElement => (
  <main className="page">
    <DrijfverenOpdracht />
  </main>
);

export default Opdracht;

import React, { FC, ReactElement } from 'react';

import content from '../../content';
import Table from '../Table/Table';

import './OverviewToolTable.scss';


const OverviewToolTable: FC = (): ReactElement => (
  <Table className="overview-tool-table" heading={['Werksessie', 'Voorbereiding']}>
    <tbody>
      <tr>
        <td>
          Werksessie 1
        </td>
        <td>
          {content.start.overviewTool.pageThree.workSessions[0]}
        </td>
      </tr>
      <tr>
        <td>
          Werksessie 2
        </td>
        <td>
          {content.start.overviewTool.pageThree.workSessions[1]}
        </td>
      </tr>
      <tr>
        <td>
          Werksessie 3
        </td>
        <td>
          {content.start.overviewTool.pageThree.workSessions[2]}
        </td>
      </tr>
    </tbody>
  </Table>
);

export default OverviewToolTable;

import React, { FC, ReactElement } from 'react';

import { AanDeSlagActieplan } from '../../containers';


const Actieplan: FC = (): ReactElement => (
  <main className="page">
    <AanDeSlagActieplan />
  </main>
);

export default Actieplan;

import React, { FC, ReactElement } from 'react';

import { AanDeSlagStart } from '../../containers';


const Start: FC = (): ReactElement => (
  <main className="page">
    <AanDeSlagStart />
  </main>
);


export default Start;

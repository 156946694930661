import { getCookieValue, removeCookie, setStrictCookie } from '../../helpers/tools';
import {
  AUTH_AUTHENTICATE_FAILURE,
  AUTH_AUTHENTICATE_REQUEST,
  AUTH_AUTHENTICATE_SUCCESS, AUTH_SET_PASSWORD_FAILURE, AUTH_SET_PASSWORD_REQUEST, AUTH_SET_PASSWORD_SUCCESS,
  AUTH_UNSET_TOKENS,
  AUTH_UPDATE_API_TOKEN,
  AUTH_UPDATE_REFRESH_TOKEN,
  AuthReducerTypes,
} from './authTypes';

export type AuthState = Readonly<{
  loading: boolean;
  isAuthenticated: boolean;
  apiToken: string | null;
  apiRefreshToken: string | null;
  userName: string;
  userId: string;
  failure: boolean;
}>;

const initialState = {
  loading: false,
  isAuthenticated: !!getCookieValue('apiToken'),
  apiToken: getCookieValue('apiToken') || null,
  apiRefreshToken: getCookieValue('apiRefreshToken') || null,
  userName: getCookieValue('userName') || '',
  userId: getCookieValue('userId') || '',
  failure: false,
};

const authReducer = (state = initialState, action: AuthReducerTypes) => {
  switch (action.type) {
    case AUTH_AUTHENTICATE_REQUEST:
    case AUTH_SET_PASSWORD_REQUEST:
      return { ...state, loading: true, errors: [] };
    case AUTH_AUTHENTICATE_SUCCESS:
      setStrictCookie('apiToken', action.apiToken);
      setStrictCookie('apiRefreshToken', action.refreshToken);
      setStrictCookie('userName', action.userName);
      setStrictCookie('userId', action.userId);

      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        apiToken: action.apiToken,
        apiRefreshToken: action.refreshToken,
        userName: action.userName,
        userId: action.userId,
        failure: false,
      };
    case AUTH_SET_PASSWORD_SUCCESS:
      return {
        ...state, loading: false,
      };
    case AUTH_UNSET_TOKENS:
      removeCookie('apiRefreshToken');
      removeCookie('apiToken');
      removeCookie('userName');
      removeCookie('userId');

      return {
        ...state, apiToken: null, apiRefreshToken: null, isAuthenticated: false,
      };
    case AUTH_AUTHENTICATE_FAILURE:
    case AUTH_SET_PASSWORD_FAILURE:
      return {
        ...state, loading: false, failure: true,
      };
    case AUTH_UPDATE_API_TOKEN:
      setStrictCookie('apiToken', action.apiToken);
      return { ...state, apiToken: action.apiToken };
    case AUTH_UPDATE_REFRESH_TOKEN:
      setStrictCookie('apiRefreshToken', action.refreshToken);
      return { ...state, apiRefreshToken: action.refreshToken };
    default:
      return state;
  }
};

export default authReducer;

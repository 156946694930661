import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DrijfverenCasuistiek } from '../../containers';
import { caseStudies } from '../../fetches/caseStudies';
import { getAllFormDataAndDispatch } from '../../helpers/tools';
import useCaseStudy from '../../helpers/useCaseStudy';
import { setPageFinished } from '../../redux/pages/pagesActions';
import { Reducers } from '../../redux/reducers';

const PAGE = 'drijfveren-casuistiek';

export interface CaseInputValues {
  [key: number]: string | string[];
}


const ConnectedDrijfverenCasuistiek: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const data = useSelector((state: Reducers) => state.pagesReducer.pages[PAGE]);

  const [isFinished, setIsFinished] = useState(false);
  const [nextButtonIsLocked, setNextButtonIsLocked] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [caseInputValues, setCaseInputValues] = useState<CaseInputValues>({});

  const caseStudyMap: { [key: number]: any } = {
    1: useCaseStudy(1),
    2: useCaseStudy(2),
    3: useCaseStudy(3),
    4: useCaseStudy(4),
  };

  useEffect((): void => {
    const caseStudyValues = Object.values(caseStudyMap);
    const loading = caseStudyValues.filter((value) => !!value).length < caseStudyValues.length;
    const filledAnswersLength = caseStudyValues.filter((value) => value && value.attributes.answer).length;
    const finished = data.finished || filledAnswersLength === caseStudyValues.length;

    setIsLoading(loading);
    setIsFinished(finished);
    setNextButtonIsLocked(!finished);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStudyMap[1], caseStudyMap[2], caseStudyMap[3], caseStudyMap[4]]);

  useEffect(() => {
    const filledAnswersLength = Object.values(caseInputValues).filter(value => value.length !== 0).length;
    setNextButtonIsLocked(!isFinished && filledAnswersLength === 0);
  }, [caseInputValues, isFinished]);

  const onSubmit = (pageRef: React.RefObject<HTMLDivElement>): void => {
    getAllFormDataAndDispatch(pageRef, dispatch, PAGE);

    if (!isFinished) {
      dispatch(setPageFinished(PAGE));

      Object.values(caseStudyMap).forEach((value, index) => {
        if (value) {
          // @ts-ignore
          caseStudies.patch(value.id, {
            attributes: {
              // @ts-ignore
              answer: caseInputValues[index + 1] || '-',
            },
          });
        }
      });
    }
  };

  return (
    <DrijfverenCasuistiek
      data={data}
      onSubmit={onSubmit}
      onChange={setCaseInputValues}
      caseStudyMap={caseStudyMap}
      caseInputValues={caseInputValues}
      nextButtonIsLocked={nextButtonIsLocked}
      isLoading={isLoading}
      isFinished={isFinished}
    />
  );
};

export default ConnectedDrijfverenCasuistiek;

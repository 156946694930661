import { ModuleSlugType } from '../Routes';

const moduleEstimates: Partial<Record<ModuleSlugType, number>> = {
  start: 10,
  drijfveren: 30,
  succesfactoren: 30,
  opbrengsten: 30,
  'aan-de-slag': 90,
};

export default moduleEstimates;

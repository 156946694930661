import React, { FC, ReactElement } from 'react';

import './Logo.scss';

interface LogoProps {
  className?: string;
}


const Logo: FC<LogoProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 279.5 112.8" className={`logo ${className}`}>
    <g className="logo__text">
      <polygon points="31.5,110.7 0,25.7 18.9,25.7 43.3,91.5 66.9,25.7 79.6,25.7 49.1,110.7" />
      <path d="M89.9,25.7h17.7v52.1c0,9.4,1.6,15.7,4.8,18.7c3.2,3.1,7.3,4.6,12.4,4.6c5,0,9-1.5,12-4.5c3-3,4.5-8.9,4.5-17.5V25.7h15.4v52.2c0,9.8-1.2,16.9-3.7,21.2c-2.5,4.3-6,7.6-10.7,10.1c-4.7,2.4-10.6,3.7-17.8,3.7c-7,0-13.1-1.1-18.2-3.4c-5.1-2.3-9.1-5.7-12-10.2c-3-4.5-4.4-11.7-4.4-21.5V25.7z" />
    </g>
    <g className="logo__griffin">
      <path d="M192.3,64.9c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.4,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7C192.5,65.5,192.3,65.2,192.3,64.9" />
      <path d="M234.2,85.5c-1-2.8-3.7-4.7-7.5-4.4c-2.1-3.9-5.3-2.1-6.3-0.9c1,0.9,1.9,1.3,3.1,1.5v0.8c-2.3-0.1-3.1-1-4.7-2.7c-0.7,5.6,5.9,7.6,8,3c3.1-0.1,5,1.3,5.6,3.3c0.6,2,0,4.1-1,5.2c-1.9,2.3-4.9,2.3-7.6,0.8c-1.7-0.8-3.5-2.6-5.1-4.3c-2.3-2.6-3.7-3.8-5.5-4.7c-1-0.5-2-0.7-2.9-0.8c-0.6-0.1-1.1-0.2-1.7-0.2c-0.5,0-1,0.1-1.5,0.1c-4,0.4-8.1,2.4-13,2.9h-2.6c-1.6,1.4-3.1,2.7-4.3,3.8l-3.1,2.7c0,0,0,0,0,0.1c-1.3,1.2-2.1,1.9-2.1,1.9c0.4,2.9,0.5,4.3,0.5,6.9c0,2.2-0.1,3.2-0.1,4.1c-0.1,0.9-0.3,1.7-0.6,2.4c-0.2,0.7-0.8,1.2-1.7,1.3c-0.8,0.2-2.8-1.3-3.5-0.4c-0.6,0.8,0.1,2.5,0.6,3h5.5c0.7-1.4,0.9-2.2,1.3-3.1c0.5-0.7,0.9-1.7,1.2-2.4c0.4-0.6,0.8-1.6,0.9-2.2c0.1-0.7,0.4-1.3,0.5-1.8c0.4-1,0.9-2,1.3-2.5c0.1-0.2,0.2-0.3,0.3-0.5c0.2,0.4,0.5,0.7,0.6,1c1.3,2.1,2.4,3.7,4.3,4.4c0.7,0.4,1.2,0.7,1.5,0.9c0.3,0.3,0.3,1.1,0.2,1.7c0,0.8-0.3,1.4-1,1.7c-1.2,0.5-3.2-1.3-3.9-0.4c-0.7,0.7,0.1,2.2,0.8,3.1h5.1c1.2-2,1.7-4.7,1.7-6.9c0-2.3,0-2.9-1.4-3.5c-0.5-0.3-0.9-1.4-1.3-2.6c0-0.1,0-0.4,0-0.6c2.4-0.8,5.1-2.2,8-4.4c0,0.4,0,0.8,0.1,1.2c0.5,3.2,0.8,6.1,3.3,8.4c0.6,0.7,1.2,1.3,1.3,1.7c0,0.3-0.4,1.3-0.7,1.8c-0.6,1.2-1.8,2.2-2.7,2.3c-1.2,0.2-2.6-1.4-3.6-0.5c-0.8,0.8,0.3,2.6,0.8,3.1h5.2c1.7-3.1,4-5.7,4.9-7.4c0.5-1.2,0.5-1.7-0.5-2.1c-1.7-0.9-1.5-3-1-5.4c0.1-0.4,0.3-0.8,0.5-1.2c1.3,3.6,2.9,6.9,6.8,7.9c0.6,0.1,1.6,0.6,1.7,0.8c0,0.2,0,0.9-0.1,1.5c-0.2,1.1-0.8,2.4-1.7,3.1c-0.9,0.8-3.3-1.7-4-0.3c-0.6,1.1,0.4,2.6,0.9,3h5c1.4-3.1,2.6-5.8,3-7.8c0.4-2.4,0.4-3.2-1.4-3.6c-0.9-0.1-1.7-0.5-2-1.8c-0.7-2.3-0.6-6.3-2.3-9c2,2.3,4.1,4,6,5c4.3,2.3,8.8,1.5,11-1.6C234.8,89.8,234.8,87.2,234.2,85.5" />
      <path d="M277.3,26c-3.8-0.5-12.6,0.5-20.1,2.2c4.7-2.3,6-2.7,7.7-3.8c0.5-0.3,0.7-0.9,0.7-2c0-1.1-1-1.3-2.2-1.1c-1.2,0.1-3.9,1-7.1,2.2c-6.4,2.6-15.6,6.9-26.5,14.3c-9.6,6.6-15.9,12.2-22.9,19.2c-1.8,1.9-3.9,4.2-6.1,6.7c-0.5-0.8-0.8-1-1.6-0.8c2.4-5.5,2.7-5.8,4-9c0.6-1.5,0.3-2.6-0.3-3.3c-0.8-1.3-1.8-0.4-2.4,0.1c-1,1.2-1.3,1.5-3.6,4.3c2.8-9.4,4.3-10.1,6.9-15.5c0.3-0.7,0.3-1.8-0.1-2.6c-0.3-0.7-0.7-1-1.2-0.7c-1,0.5-1.3,1-3.3,3.4c2.8-5.3,6.7-11.6,7-12.7c0.5-1.1,1.1-1.3-0.1-3c-0.9-1.4-1.8-0.4-2.4,0.3c-0.6,0.3-1.7,1.6-3.7,3.9c4.1-7.7,8-11.3,8.7-12.6c0.9-1.5,1-1.9,0.3-3.6c-0.7-1.5-2-0.8-2.6,0c-2.9,2.8-2.7,2.2-7.6,7.8c2.5-6.7,7-11.7,8.5-14.4c0.7-1,0.8-1.4,0.1-3.9c-0.4-1.1-1.3-1.5-2.2-1.4c-1.4,0.3-2.7,1-5.3,3.3c-5.4,4.8-12.5,11.7-18.3,24.2c-5.6,11.7-6.8,22.8-6.8,30.4c0,11.8,3.1,22,5.9,28.5c0-0.2,0-0.4,0-0.7c-0.2-3.2,0.4-6.8,0.9-8.6c1.2-4.7,2.7-7.2,4-9.3l-2.3-3.1l3,0.9l-1.8-3.9c0,0,2.3,0.3,4,2.6c1.1-1,3.3-1.9,5.5-0.1c1.4-0.1,2.3,0.2,3,0.6c1.6,0.9,1.4,2.6,1.3,3c-0.8-0.8-3.2-2.3-5.7-0.3l4.7,1.6l-5.9,0.5v6.1l-7,6.3v2.4l4.8-4.2v2.2l-8.5,7.6v2.3l10.7-9.6v0l2.7-2.4l0,0c1.6-1.3,2.3-2,3.2-2.4c1.1-0.8,1.3,0.1,1.5,1.3c0.3,1,1.5,2.1,4-0.3c7.5-6.8,15.4-10.4,23.7-14.4c-4.5,3.1-12.8,7.7-15.7,10.8c-1.2,1.4,0,4.9,2,3.4c3.6-2.4,8.3-2.7,10.6-2.6c1.1,0,2.4-0.7,3-1.7c0.7-0.8,0.7-1.8-0.8-1.9c7.7-2.3,9.7-3,16-3.6c1.7-0.1,2.7-1,3-2.4c0.3-1.3-0.4-1.7-2-1.9c-1.9-0.3-5.2,0.3-8.5,0.8c7.4-3.2,16.4-4.9,23.6-6.9c2.3-0.7,2.5-1.3,2.6-2.6c0.3-1.4-0.9-1.4-3.4-1.4c-5.2,0.3-12.8,0.7-24.6,4.1c11.1-5.5,17.3-8.2,27.3-10.7c2.7-0.8,3.2-1.4,3.4-2.8c0.5-1.6-0.9-1.6-1.8-1.4c-1.4,0.1-4.8,0-11.6,2.1c6.7-2.9,14-6.3,19.2-8c1.1-0.4,1.8-1.1,1.8-2.4c0-0.9-0.3-1-1.4-1c-2.3,0-5.9,0.7-10.3,2.1c9-3.8,11.7-5.2,17.7-6.2c2-0.5,2.4-1.1,2.4-2.3C279.7,26,278.6,26.1,277.3,26M187.9,56.6c-0.1-0.7-0.5-1.2-1.1-1.6c1.3-3.2,2.6-5.7,5.2-9.1C189.6,50.6,188.5,54.5,187.9,56.6 M179.8,54c1.8-19.1,10.5-33.2,16.4-39.7C187.7,26.9,182.8,44.3,179.8,54 M211.7,59.4c11.2-10.6,26.5-21.9,45.8-26.6C241.2,38.6,218.7,53.9,211.7,59.4" />
    </g>
  </svg>
);


export default Logo;

import React, { FC, ReactElement } from 'react';
import { ProceedType } from '../../data';
import { JustifiedScoreScale } from '../index';

interface AccordionProceedScoreElementProps {
  score?: number;
  comment?: string;
  intro: string;
  onScoreChange?: (score: number, proceedType: ProceedType) => void;
  onCommentChange?: (comment: string, proceedType: ProceedType) => void;
  proceedType?: ProceedType;
}

const AccordionProceedScoreElement: FC<AccordionProceedScoreElementProps> = ({
  score,
  comment,
  intro,
  proceedType,
  ...props
}): ReactElement => {
  const onScoreChange = (value: number) => {
    if (proceedType && props.onScoreChange) {
      props.onScoreChange(value, proceedType);
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  const onCommentChange = props.onCommentChange ? (value: string) => {
    if (proceedType && props.onCommentChange) {
      props.onCommentChange(value, proceedType);
    }
  } : undefined;

  return (
    <JustifiedScoreScale
      intro={intro}
      comment={comment}
      score={score}
      onScoreChange={props.onScoreChange ? onScoreChange : undefined}
      onCommentChange={onCommentChange}
    />
  );
};

export default AccordionProceedScoreElement;

import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { DrijfverenStart } from '../../containers';

type DrijfverenStartProps = RouteComponentProps;


const Start: FC<DrijfverenStartProps> = (): ReactElement => (
  <main className="page">
    <DrijfverenStart />
  </main>
);


export default Start;

import {
  AuthLogoutAction,
  AuthRequestUserPasswordResetAction,
  AuthSetPasswordAction,
  AuthSetTokenAction,
  AuthUserWithCredentialsAction,
  AuthUserWithInviteTokenAction,
  AuthUserWithResetTokenAction,
} from './authTypes';

export const authenticateUserWithCredentials = (credentials: object, errorCallback: () => void): AuthUserWithCredentialsAction => ({
  type: 'AUTH_SAGA_AUTHENTICATE_USER_WITH_CREDENTIALS',
  credentials,
  errorCallback,
});

export const authenticateUserWithInviteToken = (token: string): AuthUserWithInviteTokenAction => ({
  type: 'AUTH_SAGA_AUTHENTICATE_USER_WITH_INVITE_TOKEN',
  token,
});

export const authenticateUserWithResetToken = (token: string): AuthUserWithResetTokenAction => ({
  type: 'AUTH_SAGA_AUTHENTICATE_USER_WITH_RESET_TOKEN',
  token,
});

export const requestUserPasswordReset = (email: string): AuthRequestUserPasswordResetAction => ({
  type: 'AUTH_SAGA_REQUEST_USER_PASSWORD_RESET',
  email,
});

export const setUserPassword = (password: string, history: any, errorCallback?: () => void): AuthSetPasswordAction => ({
  type: 'AUTH_SAGA_SET_PASSWORD',
  password,
  history,
  errorCallback,
});

export const logoutUser = (): AuthLogoutAction => ({
  type: 'AUTH_SAGA_LOGOUT',
});

export const setApiToken = (token: string): AuthSetTokenAction => ({
  type: 'AUTH_UPDATE_API_TOKEN',
  apiToken: token,
});

export const setRefreshToken = (token: string): AuthSetTokenAction => ({
  type: 'AUTH_UPDATE_REFRESH_TOKEN',
  refreshToken: token,
});

import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { HTMLContent, PageHeader } from '../../components';
import { BottomNavigation } from '../../containers';

import content from '../../content';

type OpbrengstenBibliotheekProps = RouteComponentProps;


const OpbrengstenBibliotheek: FC<OpbrengstenBibliotheekProps> = (): ReactElement => (
  <main className="page">
    <PageHeader title="Bibliotheek" />
    <HTMLContent html={content.opbrengsten.bibliotheek} />
    <BottomNavigation isLastPageOfModule />
  </main>
);


export default OpbrengstenBibliotheek;

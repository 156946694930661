import moduleEstimates from '../data/moduleEstimates';
import { dynamicRouteModules, ModuleSlugType } from '../Routes';
import { LocationIndexes } from './location/retrieveLocationIndexes';

export default function getTotalModulesProgression(locationIndexes: LocationIndexes): number {
  const duration = dynamicRouteModules.reduce((total, module, index) => {
    const estimate = moduleEstimates[module.slug as ModuleSlugType] || 0;

    if (index < locationIndexes.moduleIndex) {
      return total + estimate;
    }

    if (index === locationIndexes.moduleIndex) {
      return total + Math.max((locationIndexes.pageIndex / (module.pages.length)) * estimate, 0);
    }

    return total;
  }, 0);

  const totalDuration = dynamicRouteModules.reduce((total, module) => {
    return total + (moduleEstimates[module.slug as ModuleSlugType] || 0);
  }, 0);

  return Math.min(Math.round((duration / totalDuration) * 100), 100);
}

import { averages, ProceedType } from '../data';
import transformNormScoreToJustifiedScore from './transformNormScoreToJustifiedScore';

function getScoreForOrganisation(
  organization: string,
  proceedType: ProceedType,
  data: Record<ProceedType, any>,
): number {
  if (!data[proceedType][organization] || (data[proceedType][organization] && !data[proceedType][organization].total)) {
    return 1;
  }

  return transformNormScoreToJustifiedScore(proceedType, data[proceedType][organization].total);
}

export default function getJustifiedScoresForOrganisation(organization: string): Record<ProceedType, number> {
  const data = averages as Record<ProceedType, any>;

  return {
    [ProceedType.inclusiefLeiderschap]: getScoreForOrganisation(organization, ProceedType.inclusiefLeiderschap, data),
    [ProceedType.cultureleVeerkracht]: getScoreForOrganisation(organization, ProceedType.cultureleVeerkracht, data),
    [ProceedType.werkTevredenheid]: getScoreForOrganisation(organization, ProceedType.werkTevredenheid, data),
    [ProceedType.identificatieMetDeOrganisatie]: getScoreForOrganisation(organization, ProceedType.identificatieMetDeOrganisatie, data),
    [ProceedType.ervarenSfeerInDeWerkomgeving]: getScoreForOrganisation(organization, ProceedType.ervarenSfeerInDeWerkomgeving, data),
    [ProceedType.ervarenCarrierePerspectief]: getScoreForOrganisation(organization, ProceedType.ervarenCarrierePerspectief, data),
    [ProceedType.authenticiteit]: getScoreForOrganisation(organization, ProceedType.authenticiteit, data),
    [ProceedType.erkenning]: getScoreForOrganisation(organization, ProceedType.erkenning, data),
    [ProceedType.creativiteit]: getScoreForOrganisation(organization, ProceedType.creativiteit, data),
    [ProceedType.reflectieEnBesluitvorming]: getScoreForOrganisation(organization, ProceedType.reflectieEnBesluitvorming, data),
  };
}

import React, {
  ChangeEvent,
  createRef,
  FC,
  KeyboardEvent,
  ReactElement,
  useEffect,
} from 'react';
import { useToggle } from 'react-use';

import { Icon } from '..';
import './Input.scss';

export interface InputProps {
  label?: string;
  name?: string;
  isPassword?: boolean;
  type?: 'text' | 'number' | 'email' | 'tel' | 'password';
  tabIndex?: 0 | -1;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  className?: string;
}


const Input: FC<InputProps> = ({
  label,
  name,
  isPassword,
  type = 'text',
  tabIndex,
  defaultValue,
  disabled,
  onChange,
  className = '',
}): ReactElement => {
  const [passwordIsVisible, togglePasswordVisibility] = useToggle(false);

  const ref = createRef<HTMLInputElement>();
  useEffect((): void => {
    if (onChange && ref.current) {
      onChange(ref.current.value);
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  const enableVisibility = (): void => togglePasswordVisibility(true);
  const disableVisibility = (): void => togglePasswordVisibility(false);
  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>): void => {
    togglePasswordVisibility(event.keyCode === 13 || event.keyCode === 32);
  };

  return (
    <label className="input">
      {label && <span className="input__label">{label}</span>}
      {isPassword ? (
        <div className="input__password-container">
          <input
            ref={ref}
            type={passwordIsVisible ? 'text' : 'password'}
            name={name}
            tabIndex={tabIndex}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={handleChange}
            className={`input__field input__field--is-password ${className}`}
          />
          <button
            type="button"
            title={passwordIsVisible ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
            onMouseDown={enableVisibility}
            onMouseUp={disableVisibility}
            onPointerDown={enableVisibility}
            onPointerUp={disableVisibility}
            onKeyDown={handleKeyDown}
            onKeyUp={disableVisibility}
            className="input__visibility-button"
          >
            <Icon name={passwordIsVisible ? 'visibility-on' : 'visibility-off'} className="input__visibility-icon" />
          </button>
        </div>
      ) : (
        <input
          ref={ref}
          type={type}
          name={name}
          tabIndex={tabIndex}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={handleChange}
          className={`input__field ${className}`}
        />
      )}
    </label>
  );
};


export default Input;

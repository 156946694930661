import { AUTH_UNSET_TOKENS } from '../auth/authTypes';

export const USER_SET_FURTHEST_LOCATION = 'USER_SET_FURTHEST_LOCATION';
export const USER_SAGA_SET_FURTHEST_LOCATION = 'USER_SAGA_SET_FURTHEST_LOCATION';
export const USER_SET_MOTIVATIONS = 'USER_SET_MOTIVATIONS';
export const USER_SAGA_SET_MOTIVATIONS = 'USER_SAGA_SET_MOTIVATIONS';
export const USER_SET_MOST_IMPORTANT_PROCEED = 'USER_SET_MOST_IMPORTANT_PROCEED';
export const USER_SAGA_SET_MOST_IMPORTANT_PROCEED = 'USER_SAGA_SET_MOST_IMPORTANT_PROCEED';
export const USER_SET_MOST_IMPORTANT_PROCEED_RATING = 'USER_SET_MOST_IMPORTANT_PROCEED_RATING';
export const USER_SAGA_SET_MOST_IMPORTANT_PROCEED_RATING = 'USER_SAGA_SET_MOST_IMPORTANT_PROCEED_RATING';
export const USER_SET_ORGANIZATION_PROCEED = 'USER_SET_ORGANIZATION_PROCEED';
export const USER_SAGA_SET_ORGANIZATION_PROCEED = 'USER_SAGA_SET_ORGANIZATION_PROCEED';
export const USER_SET_ORGANIZATION_PROCEED_RATING = 'USER_SET_ORGANIZATION_PROCEED_RATING';
export const USER_SAGA_SET_ORGANIZATION_PROCEED_RATING = 'USER_SAGA_SET_ORGANIZATION_PROCEED_RATING';
export const USER_SET_SUCCESS_FACTOR_LEADERSHIP = 'USER_SET_SUCCESS_FACTOR_LEADERSHIP';
export const USER_SAGA_SET_SUCCESS_FACTOR_LEADERSHIP = 'USER_SAGA_SET_SUCCESS_FACTOR_LEADERSHIP';
export const USER_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE = 'USER_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE';
export const USER_SAGA_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE = 'USER_SAGA_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE';
export const USER_SET_TOP_3_PROCEEDS_INITIAL = 'USER_SET_TOP_3_PROCEEDS_INITIAL';
export const USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL = 'USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL';
export const USER_SET_TOP_3_PROCEEDS_INITIAL_RATING = 'USER_SET_TOP_3_PROCEEDS_INITIAL_RATING';
export const USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL_RATING = 'USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL_RATING';
export const USER_SET_TOP_3_PROCEEDS_DEFINITIVE = 'USER_SET_TOP_3_PROCEEDS_DEFINITIVE';
export const USER_SAGA_SET_TOP_3_PROCEEDS_DEFINITIVE = 'USER_SAGA_SET_TOP_3_PROCEEDS_DEFINITIVE';
export const USER_SET_WORKPLACE = 'USER_SET_WORKPLACE';
export const USER_SAGA_SET_WORKPLACE = 'USER_SAGA_SET_WORKPLACE';

export interface FurthestLocation {
  module: string;
  page: string;
}

export interface Motivations {
  kwaliteit: number;
  rechtvaardigheid: number;
  toegang: number;
  leren: number;
}

export interface Workplace {
  organization: string | null;
  subOrganization: string | null;
  workunit: string | null;
}

export interface SetUserFurthestLocationAction {
  type: typeof USER_SAGA_SET_FURTHEST_LOCATION;
  furthestLocation: FurthestLocation | any;
}

export interface SetUserMostImportantProceedAction {
  type: typeof USER_SAGA_SET_MOST_IMPORTANT_PROCEED;
  mostImportantProceed: string;
}

export interface SetUserMostImportantProceedRatingAction {
  type: typeof USER_SAGA_SET_MOST_IMPORTANT_PROCEED_RATING;
  mostImportantProceedRating: number;
}

export interface SetUserMotivationsAction {
  type: typeof USER_SAGA_SET_MOTIVATIONS;
  motivations: Motivations | any;
}

export interface SetUserOrganizationProceedAction {
  type: typeof USER_SAGA_SET_ORGANIZATION_PROCEED;
  organizationProceed: string;
}

export interface SetUserOrganizationProceedRatingAction {
  type: typeof USER_SAGA_SET_ORGANIZATION_PROCEED_RATING;
  organizationProceedRating: number;
}

export interface SetUserSuccessFactorLeaderShipAction {
  type: typeof USER_SAGA_SET_SUCCESS_FACTOR_LEADERSHIP;
  successFactorLeaderShip: number;
}

export interface SetUserSuccessFactorCulturalResilienceAction {
  type: typeof USER_SAGA_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE;
  successFactorCulturalResilience: number;
}

export interface SetUserTop3ProceedsDefinitiveAction {
  type: typeof USER_SAGA_SET_TOP_3_PROCEEDS_DEFINITIVE;
  top3ProceedsDefinitive: string[];
}

export interface SetUserTop3ProceedsInitialAction {
  type: typeof USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL;
  top3ProceedsInitial: string[];
}

export interface SetUserTop3ProceedsInitialRatingAction {
  type: typeof USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL_RATING;
  top3ProceedsInitialRating: { [key: string]: number };
}

export interface SetUserWorkplaceAction {
  type: typeof USER_SAGA_SET_WORKPLACE;
  workplace: Workplace;
}

export interface UserReducerTypes {
  type: typeof USER_SET_FURTHEST_LOCATION |
    typeof USER_SET_MOTIVATIONS |
    typeof USER_SET_MOST_IMPORTANT_PROCEED |
    typeof USER_SET_MOST_IMPORTANT_PROCEED_RATING |
    typeof USER_SET_ORGANIZATION_PROCEED |
    typeof USER_SET_ORGANIZATION_PROCEED_RATING |
    typeof USER_SET_SUCCESS_FACTOR_LEADERSHIP |
    typeof USER_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE |
    typeof USER_SET_TOP_3_PROCEEDS_DEFINITIVE |
    typeof USER_SET_TOP_3_PROCEEDS_INITIAL |
    typeof USER_SET_TOP_3_PROCEEDS_INITIAL_RATING |
    typeof USER_SET_WORKPLACE |
    typeof AUTH_UNSET_TOKENS;
  furthestLocation: FurthestLocation | any;
  motivations: Motivations | any;
  mostImportantProceed: string;
  mostImportantProceedRating: number;
  organizationProceed: string;
  organizationProceedRating: number;
  successFactorLeaderShip: number;
  successFactorCulturalResilience: number;
  top3ProceedsDefinitive: string[];
  top3ProceedsInitial: string[];
  top3ProceedsInitialRating: { [key: string]: number };
  workplace: Workplace | any;
}

import React, { FC, ReactElement } from 'react';
import { OpbrengstenIntroductie } from '../../containers';

const Introductie: FC = (): ReactElement => (
  <main className="page">
    <OpbrengstenIntroductie />
  </main>
);

export default Introductie;

import React, { FC, ReactElement } from 'react';

import { BottomNavigation, ModuleEstimation } from '..';
import { StartTitle } from '../../components';
import content from '../../content';
import moduleEstimates from '../../data/moduleEstimates';
import './OpbrengstenStart.scss';


const OpbrengstenStart: FC = (): ReactElement => (
  <div className="opbrengsten-start">
    <StartTitle text="Module 3: Opbrengsten" />
    <ModuleEstimation description={content.opbrengsten.start} minutes={moduleEstimates.opbrengsten || 0} />
    <ModuleEstimation description="Opdracht" minutes={20} />
    <BottomNavigation />
  </div>
);

export default OpbrengstenStart;

import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { Accordion, StartTitle } from '../../components';
import content from '../../content';


const OpbrengstenOpbrengsten: FC = (): ReactElement => (
  <>
    <StartTitle text="Positieve en negatieve opbrengsten van diversiteit" />
    <iframe
      title="Kwaliteit"
      src={content.opbrengsten.opbrengsten.videoUrl}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
    <Accordion
      id="opbrengsten-opbrengsten-video"
      items={[
        { label: content.opbrengsten.opbrengsten.videoButton, htmlContent: content.opbrengsten.opbrengsten.videoTranscript },
      ]}
    />
    <BottomNavigation />
  </>
);

export default OpbrengstenOpbrengsten;

export const PAGES_SAGA_SET_PAGE_DATA = 'PAGES_SAGA_SET_PAGE_DATA';
export const PAGES_SET_PAGE_DATA = 'PAGES_SET_PAGE_DATA';

export const PAGES_SAGA_SET_PAGE_FINISHED = 'PAGES_SAGA_SET_PAGE_FINISHED';
export const PAGES_SET_PAGE_FINISHED = 'PAGES_SET_PAGE_FINISHED';

export const PAGES_SET_PAGE_DATA_REQUEST = 'PAGES_SET_PAGE_DATA_REQUEST';
export const PAGES_SET_PAGE_DATA_SUCCESS = 'PAGES_SET_PAGE_DATA_SUCCESS';
export const PAGES_SET_PAGE_DATA_FAILURE = 'PAGES_SET_PAGE_DATA_FAILURE';

// setPageData

export interface SetPageDataAction {
  type: typeof PAGES_SAGA_SET_PAGE_DATA;
  page: string;
  data: object;
}

// setPageFinished

export interface SetPageFinishedAction {
  type: typeof PAGES_SAGA_SET_PAGE_FINISHED;
  page: string;
}

export interface PagesReducerTypes {
  type: typeof PAGES_SET_PAGE_DATA |
    typeof PAGES_SET_PAGE_FINISHED |
    typeof PAGES_SET_PAGE_DATA_REQUEST |
    typeof PAGES_SET_PAGE_DATA_FAILURE |
    typeof PAGES_SET_PAGE_DATA_SUCCESS;
  page: string;
  data: any;
}

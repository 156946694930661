import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { HTMLContent, PageHeader } from '../../components';

import content from '../../content';

type BibliotheekOpbrengstenProps = RouteComponentProps;


const BibliotheekOpbrengsten: FC<BibliotheekOpbrengstenProps> = (): ReactElement => (
  <main className="page">
    <PageHeader title="Bibliotheek: Opbrengsten" />
    <HTMLContent html={content.bibliotheek.opbrengsten} />
  </main>
);


export default BibliotheekOpbrengsten;

import { ReflectionType } from '../Reflections';

export default function getReflectionTranslation(type: ReflectionType): string {
  switch (type) {
    case ReflectionType.insight:
      return 'Reflectie';
    case ReflectionType.strength:
      return 'Kracht';
    case ReflectionType.danger:
      return 'Gevaar';
    case ReflectionType.solution:
      return 'Hoe kun je samen verder komen?';
    default:
      return '';
  }
};

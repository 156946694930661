import React, { FC, ReactElement, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useToggle } from 'react-use';

import { NavRouteModule } from '../../../Routes';

import { Icon } from '../../../components';
import '../SideNavigation.scss';

interface NavigationModuleProps {
  module: NavRouteModule;
  activeModule: string;
}


const NavigationModule: FC<NavigationModuleProps> = ({ module, activeModule }): ReactElement => {
  const [isCollapsed, toggleCollapse] = useToggle((activeModule === module.slug) || false);

  useEffect((): void => {
    toggleCollapse(activeModule === module.slug);
  }, [toggleCollapse, activeModule, module]);

  return (
    <div key={module.slug} className="side-navigation__module-container">
      <button onClick={toggleCollapse} className="side-navigation__module-button">
        <span className="side-navigation__module-button-title">{module.title}</span>
        <Icon name={isCollapsed ? 'minus' : 'plus'} className="side-navigation__module-button-icon" />
      </button>
      <nav className={`side-navigation__module-nav ${isCollapsed ? 'side-navigation__module-nav--is-collapsed' : ''}`}>
        <ul className="side-navigation__module-nav-list">
          {module.pages.map((page) => (
            <li key={page.slug} className="side-navigation__module-nav-item side-navigation__module-nav-item--static-module">
              <NavLink
                to={`/${module.slug}/${page.slug}`}
                className="side-navigation__module-nav-link"
                activeClassName="side-navigation__module-nav-link--is-active"
              >
                {page.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};


export default NavigationModule;

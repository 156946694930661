import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useState,
} from 'react';

import './CheckboxInput.scss';

interface CheckboxInputProps {
  defaultChecked?: boolean;
  label: string;
  value: string;
  name: string;
  order?: number;
  disabled?: boolean;
  onChange?: (value: string) => void;
  className?: string;
}


const CheckboxInput: FC<CheckboxInputProps> = ({
  defaultChecked = false,
  label,
  name,
  value,
  order,
  disabled,
  onChange,
  className = '',
}): ReactElement => {
  const [isChecked, setChecked] = useState(defaultChecked);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.currentTarget.checked);
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  return (
    <div className={`checkbox-input ${className}`}>
      <input
        id={`${name}-${value}`}
        defaultChecked={defaultChecked}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled || (order ? !isChecked && disabled : disabled)}
        onChange={handleChange}
        className="checkbox-input__input"
      />
      <label htmlFor={`${name}-${value}`} className="checkbox-input__label-container">
        <div className="checkbox-input__indication-box" />
        <span className="checkbox-input__label">{label}</span>
        {(order && isChecked) && <div className="checkbox-input__order">{order}</div>}
      </label>
    </div>
  );
};


export default CheckboxInput;

import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { OpbrengstenDiagnose } from '../../containers';
import { ProceedType } from '../../data';
import useQuestion from '../../helpers/useQuestion';
import { Reducers } from '../../redux/reducers';
import patchChosenProceedType from './helpers/patchChosenProceedType';
import patchEstimates from './helpers/patchEstimates';
import transformApiEstimatesToGivenEstimates from './helpers/transformApiEstimatesToGivenEstimates';
import patchImportance from './helpers/patchImportance';
import transformApiImportanceToGivenImportance from './helpers/transformApiImportanceToGivenImportance';

export const proceedTypes: ProceedType[] = [
  ProceedType.werkTevredenheid,
  ProceedType.identificatieMetDeOrganisatie,
  ProceedType.ervarenSfeerInDeWerkomgeving,
  ProceedType.ervarenCarrierePerspectief,
  ProceedType.authenticiteit,
  ProceedType.erkenning,
  ProceedType.creativiteit,
  ProceedType.reflectieEnBesluitvorming,
];

export interface WorkUnitEstimate {
  score: number;
  comment?: string;
}

const ConnectedOpbrengstenDiagnose: FC = (): ReactElement => {
  const [givenEstimates, setGivenEstimates] = useState<Partial<Record<ProceedType, WorkUnitEstimate>>>({});
  const [givenImportance, setGivenImportance] = useState<Partial<Record<ProceedType, number | null>>>({});
  const [chosenProceedType, setChosenProceedType] = useState<ProceedType | null>(null);
  const organization = useSelector((state: Reducers) => state.userReducer.workplace.organization);

  const apiEstimates = [
    useQuestion(1),
    useQuestion(2),
    useQuestion(3),
    useQuestion(4),
    useQuestion(5),
    useQuestion(6),
    useQuestion(7),
    useQuestion(8),
  ];

  const apiChosenProceedType = useQuestion(9);

  const apiImportance = [
    useQuestion(10),
    useQuestion(11),
    useQuestion(12),
    useQuestion(13),
    useQuestion(14),
    useQuestion(15),
    useQuestion(16),
    useQuestion(17),
  ];

  useEffect(() => {
    setGivenEstimates(transformApiEstimatesToGivenEstimates(apiEstimates, proceedTypes));
  }, [...apiEstimates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setGivenImportance(transformApiImportanceToGivenImportance(apiImportance, proceedTypes));
  }, [...apiImportance]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (apiChosenProceedType && apiChosenProceedType.attributes) {
      setChosenProceedType(apiChosenProceedType.attributes.answer as ProceedType);
    }
  }, [apiChosenProceedType]);

  const onGivenEstimatesChange = (data: Partial<Record<ProceedType, WorkUnitEstimate>>): void => {
    setGivenEstimates(data);
  };

  const onGivenImportanceChange = (data: Partial<Record<ProceedType, number | null>>): void => {
    setGivenImportance(data);
  };

  const onGivenEstimatesSubmit = () => {
    patchEstimates(givenEstimates, apiEstimates);
  };

  const onTableSubmit = () => {
    patchImportance(givenImportance, apiImportance);
  };

  const onChosenProceedTypeChange = (proceedType: ProceedType) => {
    setChosenProceedType(proceedType);

    if (apiChosenProceedType) {
      patchChosenProceedType(proceedType, apiChosenProceedType);
    }
  };

  if (!organization) {
    return (
      <span>Laden...</span>
    );
  }

  return (
    <OpbrengstenDiagnose
      givenEstimates={givenEstimates}
      givenImportance={givenImportance}
      chosenProceedType={chosenProceedType}
      onGivenEstimatesChange={onGivenEstimatesChange}
      onGivenEstimatesSubmit={onGivenEstimatesSubmit}
      onGivenImportantChange={onGivenImportanceChange}
      onChosenProceedTypeChange={onChosenProceedTypeChange}
      onTableSubmit={onTableSubmit}
      organization={organization}
    />
  );
};

export default ConnectedOpbrengstenDiagnose;

import React, { FC, ReactElement } from 'react';

import { BottomNavigation, ModuleEstimation } from '..';
import { StartTitle } from '../../components';
import content from '../../content';
import moduleEstimates from '../../data/moduleEstimates';


const SuccesfactorenStart: FC = (): ReactElement => (
  <>
    <StartTitle text="Module 2: Succesfactoren" />
    <ModuleEstimation description={content.succesfactoren.start} minutes={moduleEstimates.opbrengsten || 0} />
    <ModuleEstimation description="Opdracht" hours={1} />
    <BottomNavigation />
  </>
);

export default SuccesfactorenStart;

import React, { FC, ReactElement } from 'react';

import { QuizQuestion, Reflections } from '../../..';
import { Accordion, HTMLContent } from '../../../../components';
import SoundCloudPlayer from '../../../../components/SoundCloudPlayer/SoundCloudPlayer';
import { Case } from '../../../CaseSelection/CaseSelection';

import './DrijfverenCasuistiekCase.scss';

interface DrijfverenCasuistiekCase {
  caseOption: Case;
  defaultValue: string;
  isDisabled: boolean;
  data: any;
  onChange: (data: any) => void;
}


const DrijfverenCasuistiekCase: FC<DrijfverenCasuistiekCase> = ({
  caseOption,
  defaultValue,
  isDisabled,
  data,
  onChange,
}): ReactElement => (
  <div className="page__content">
    { caseOption.image && <img className="drijfveren-casuistiek-case__image" alt="diversiteit" src={caseOption.image} /> }
    <HTMLContent html={caseOption.intro} />
    { (caseOption.audio && caseOption.soundCloudToken) && <SoundCloudPlayer id={caseOption.audio} token={caseOption.soundCloudToken} /> }
    { caseOption.audioTranscript && <HTMLContent html={caseOption.audioTranscript} />}
    <QuizQuestion
      data={data}
      type="textarea"
      name={`drijfveren-casuistiek-${caseOption.index}`}
      defaultValue={defaultValue}
      question={caseOption.question}
      onChange={onChange}
      disabled={isDisabled}
    />
    <Accordion
      id={`drijfveren-casuistiek-${caseOption.index}-terugkoppeling`}
      items={[
        {
          label: 'Terugkoppeling',
          element: (
            <Reflections
              insight={caseOption.reflections ? caseOption.reflections.insight : undefined}
              strength={caseOption.reflections ? caseOption.reflections.strength : undefined}
              danger={caseOption.reflections ? caseOption.reflections.danger : undefined}
              solutions={caseOption.reflections ? caseOption.reflections.solutions : undefined}
            />
          ),
        },
      ]}
    />
  </div>
);

export default DrijfverenCasuistiekCase;

import React, { FC, ReactElement } from 'react';
import { OpbrengstenVerdieping } from '../../containers';

const Verdieping: FC = (): ReactElement => (
  <main className="page">
    <OpbrengstenVerdieping />
  </main>
);

export default Verdieping;

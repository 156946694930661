import { ProceedType } from '../../../data';
import { estimates } from '../../../fetches/estimates';
import { proceedTypes, WorkUnitEstimate } from '../ConnectedOpbrengstenDiagnose';

export default function patchEstimates(givenEstimates: Partial<Record<ProceedType, WorkUnitEstimate>>, apiEstimates: any): void {
  Object.keys(givenEstimates).map(((key) => {
    const index = proceedTypes.findIndex(proceedType => key === proceedType);
    const estimate = givenEstimates[key as ProceedType];
    const q = apiEstimates[index];

    if (q && estimate) {
      return estimates.patch(q.id, {
        attributes: {
          answer: String(estimate.score),
          comment: estimate.comment,
        },
        relationships: {},
      });
    }

    return null;
  }));
}

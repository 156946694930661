import React, { FC } from 'react';
import { Textarea } from '../../components';
import getScoreTranslation from './helpers/getScoreTranslation';
import JustifiedScoreButton from './subcomponents/JustifiedScoreButton/JustifiedScoreButton';

import './JustifiedScoreScale.scss';

interface JustifiedScoreScaleProps {
  title?: string;
  intro: string;
  score?: number;
  comment?: string;
  onScoreChange?: (score: number) => void;
  onCommentChange?: (comment: string) => void;
}

const JustifiedScoreScale: FC<JustifiedScoreScaleProps> = ({
  title,
  intro,
  score,
  comment,
  onScoreChange,
  onCommentChange,
}) => {
  const buttons = [1, 2, 3, 4, 5];

  return (
    <div className="justified-score-scale">
      <div className="justified-score-scale__text-and-scale-container">
        <div className="justified-score-scale__text-container">
          { title && (
            <h3 className="justified-score-scale__title">
              {title}
            </h3>
          )}
          <p>
            {intro}
          </p>
        </div>
        <div className="justified-score-scale__scale-container">
          <div className="justified-score-scale__scale-legend">
            <h3 className="justified-score-scale__title">Laag</h3>
            <h3 className="justified-score-scale__title">Hoog</h3>
          </div>

          <div className="justified-score-scale__buttons">
            {buttons.map(index => (
              <JustifiedScoreButton
                key={index}
                isActive={score ? index <= score : false}
                onClick={onScoreChange}
                index={index}
              />
            ))}
          </div>

          { score && (
            <div className="justified-score-scale__scale-description">
              {`Score ${score}, ${getScoreTranslation(score)}`}
            </div>
          )}
        </div>
      </div>

      { (score && onCommentChange) && (
        <Textarea
          className="justified-score-scale__textarea"
          label="Onderbouwing"
          onChange={onCommentChange}
          defaultValue={comment}
        />
      )}
    </div>
  );
};

export default JustifiedScoreScale;

import { useEffect, useState } from 'react';
import { SingleJsonApiResponseType } from '../fetches/JsonApiResponse';
import { getOrCreateMotivationForUser } from '../fetches/motivation';
import useStore from './useStore';

export default (): SingleJsonApiResponseType<'motivations'>['data']|undefined => {
  const userId = useStore('authReducer', 'userId');
  const [motivation, setMotivation] = useState<SingleJsonApiResponseType<'motivations'>['data']>();

  useEffect(() => {
    if (!userId) {
      return;
    }

    getOrCreateMotivationForUser(userId).then((response) => {
      setMotivation(response);
    });
  }, [userId]);

  return motivation;
};

interface Translations {
  [name: string]: any;
}

export default class Translator {
  private translations: Translations = {};

  constructor(translations = {}) {
    this.setTranslations(translations);
  }

  public setTranslations(translations: Translations): void {
    this.translations = translations;
  }

  public getTranslations(): Translations {
    return this.translations;
  }

  public translate(translation: string, options = {}): string {
    const splitLabels = translation.split('.');

    let result = this.translations[splitLabels[0]];

    if (!result) {
      return '';
    }

    splitLabels.forEach((splitLabel, index) => {
      if (index > 0 && result[splitLabel]) {
        result = result[splitLabel];
      }
    });

    return (typeof result === 'string') ? this.replaceOptions(result, options) : translation;
  }

  private replaceOptions = (translation: string, options: any = {}): string => {
    return translation.replace(/:(\w+)/gi, (word) => {
      const key = word.replace(/^:/, '');

      if (options[key] === undefined || options[key] === null || options[key] === false) {
        return '';
      }

      return options[key];
    }).trim();
  }
}

export const translatorInstance = new Translator();

export function translate(translation: string, options?: any) {
  return translatorInstance.translate(translation, options);
}

export function setTranslations(translations: Translations) {
  return translatorInstance.setTranslations(translations);
}

import { combineReducers } from 'redux';
import authReducer, { AuthState } from './auth/authReducer';
import pagesReducer, { PagesState } from './pages/pagesReducer';
import questionReducer, { QuestionState } from './questions/questionsReducer';
import userReducer, { UserState } from './user/userReducer';

export interface Reducers {
  authReducer: AuthState;
  pagesReducer: PagesState;
  questionReducer: QuestionState;
  userReducer: UserState;
}

export default combineReducers<Reducers>({
  authReducer,
  pagesReducer,
  questionReducer,
  userReducer,
});

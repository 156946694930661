import React, { FC, ReactElement } from 'react';

import { BottomNavigationWithExitButton } from '..';
import { HTMLContent, PageHeader } from '../../components';
import content from '../../content';


const DrijfverenBibliotheek: FC = (): ReactElement => (
  <>
    <PageHeader title="Bibliotheek" />
    <HTMLContent html={content.drijfveren.bibliotheek} />
    <BottomNavigationWithExitButton />
  </>
);

export default DrijfverenBibliotheek;

import averages from './averages';
import significanceWith from './significanceWith';

export enum ProceedType {
  inclusiefLeiderschap = 'inclusiefLeiderschap',
  cultureleVeerkracht = 'cultureleVeerkracht',
  werkTevredenheid = 'Werktevredenheid',
  identificatieMetDeOrganisatie = 'Identificatie met de organisatie',
  ervarenSfeerInDeWerkomgeving = 'Ervaren sfeer in de werkomgeving',
  ervarenCarrierePerspectief = 'Ervaren carrièreperspectief',
  authenticiteit = 'Authenticiteit',
  erkenning = 'Erkenning (eigenwaarde/zelfbewustzijn/zelfbeoordeling)',
  creativiteit = 'Creativiteit',
  reflectieEnBesluitvorming = 'Reflectie en besluitvorming',
}

const aliases = (name: string): string => {
  switch (name) {
    case 'Domein particulieren':
      return 'Particulieren';
    case 'Domein CIO/COO':
      return 'CIO/COO';
    case 'Randstad Groep Nederland':
      return 'RGN';
    case 'S&AM, SPARQ, Payrolling, Business Consultancy':
      return 'T-T Overig';
    case 'Engineering & SCM&P':
      return 'Engr & SCM&P';
    case 'Hoofdkantoor Den Haag':
      return 'Den Haag';
    case 'Voormalig hoofdkantoor Hoofddorp':
      return 'Voormalig Hoofddorp';
    case 'Ministerie van Sociale Zaken en Werkgelegenheid':
      return 'SZW';
    case 'Plv. Secretaris Generaal':
      return 'PLvSG';
    case 'Sociale Zekerheid en Integratie':
      return 'SZI';
    case 'Eenheid Amsterdam':
      return 'Amsterdam';
    case 'Eenheid Midden-Nederland':
      return 'Midden-Nederland';
    case 'Gooi en Vechtstreek Zuid':
      return 'G. en Vechtstreek Z.';
    case 'Bunschoten / Eemnes / De Bilt / Baarn / Soest':
      return 'Bunschoten e.o.';
    case 'Almere Stad Haven':
      return 'Almere Haven';
    default:
      return name;
  }
};

export {
  aliases,
  averages,
  significanceWith,
};

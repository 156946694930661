import React, { FC, ReactElement } from 'react';
import { HTMLContent } from '..';

import './AnswerBubble.scss';

interface AnswerBubbleProps {
  htmlContent: string;
  images?: string[];
  className?: string;
}


const AnswerBubble: FC<AnswerBubbleProps> = ({ htmlContent, images, className = '' }): ReactElement => (
  <div className={`answer-bubble ${className}`}>
    <HTMLContent html={htmlContent} />
    {images && (
      <ul className="answer-bubble__image-list">
        {images.map((image): ReactElement => (
          <li key={image}>
            <img src={image} alt="afbeelding" className="answer-bubble__image" />
          </li>
        ))}
      </ul>
    )}
  </div>
);


export default AnswerBubble;

import React, { FC, ReactElement, useState } from 'react';

import { BottomNavigation, PaginationPage, PaginationSwitcher } from '..';
import { Accordion, HTMLContent, StartTitle } from '../../components';
import content from '../../content';
import { ProceedType } from '../../data';
import { translate } from '../../helpers/translations';
import ProceedTypeInfo from '../ProceedTypeInfo/ProceedTypeInfo';

import './AanDeSlagVoorbereiding.scss';

interface AanDeSlagVoorbereidingProps {
  chosenProceedType: ProceedType;
}

const AanDeSlagVoorbereiding: FC<AanDeSlagVoorbereidingProps> = ({ chosenProceedType }): ReactElement => {
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  return (
    <>
      <StartTitle text="De Voorbereiding" />

      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <HTMLContent html={content.aanDeSlag.voorbereiding.pageOne.intro} />
        </PaginationPage>
        <PaginationPage id={2}>
          <HTMLContent html={translate('aanDeSlag.voorbereiding.pageTwo.intro', { proceedType: chosenProceedType.toLowerCase() })} />
          <Accordion
            id="aan-de-slag-voorbereiding"
            items={[
              {
                label: 'Samenvatting van kennis over jouw opbrengst uit de tool',
                element: <ProceedTypeInfo proceedType={chosenProceedType} />,
              },
              {
                label: 'Aansluiting op je drijfveer',
                htmlContent: content.aanDeSlag.voorbereiding.pageTwo.connection,
              },
              {
                label: 'Inzetten op inclusief leiderschap en culturele veerkracht',
                htmlContent: content.aanDeSlag.voorbereiding.pageTwo.inclusiefLeiderschap,
              },
              {
                label: 'Voorbeeld actieplan',
                element: (
                  <img
                    className="aan-de-slag-voorbereiding__opdracht-module4-voorbeeld"
                    src="/opdracht-previews/opdracht-module4.png"
                    alt="opdracht-module 4 voorbeeld"
                  />
                ),
              },
              {
                label: 'Instructie bij het maken van je actieplan',
                element: (
                  <Accordion
                    id="aan-de-slag-voorbereiding-actieplan"
                    items={[
                      {
                        label: 'Doelstelling',
                        htmlContent: content.aanDeSlag.voorbereiding.pageTwo.doelstelling,
                      },
                      {
                        label: 'Concrete acties',
                        htmlContent: content.aanDeSlag.voorbereiding.pageTwo.conreteActies,
                      },
                      {
                        label: 'Inclusief leiderschap en culturele veerkracht',
                        htmlContent: content.aanDeSlag.voorbereiding.pageTwo.inclusiefLeiderschapDetail,
                      },
                      {
                        label: 'Betrokkenheid',
                        htmlContent: content.aanDeSlag.voorbereiding.pageTwo.betrokkenheid,
                      },
                      {
                        label: 'Tijdspad',
                        htmlContent: content.aanDeSlag.voorbereiding.pageTwo.tijdspad,
                      },
                      {
                        label: 'Risico’s',
                        htmlContent: content.aanDeSlag.voorbereiding.pageTwo.risicos,
                      },
                      {
                        label: 'Impact',
                        htmlContent: content.aanDeSlag.voorbereiding.pageTwo.impact,
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </PaginationPage>
      </PaginationSwitcher>

      <BottomNavigation paginationSize={2} onPaginationChange={setActivePaginationPage} />
    </>
  );
};

export default AanDeSlagVoorbereiding;

import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { HTMLContent, LearningGoals, StartTitle } from '../../components';
import content from '../../content';


const DrijfverenIntroductie: FC = (): ReactElement => (
  <>
    <StartTitle text="Introductie" />
    <HTMLContent html={content.drijfveren.introductie.intro} />
    <LearningGoals
      knowledge={content.drijfveren.introductie.kennis}
      insight={content.drijfveren.introductie.inzicht}
      skill={content.drijfveren.introductie.skill}
    />
    <BottomNavigation />
  </>
);

export default DrijfverenIntroductie;

import React, { FC, ReactElement } from 'react';

import './Accordion.scss';

interface AccordionItem {
  label: string;
  htmlContent?: string;
  element?: ReactElement;
}

interface AccordionProps {
  id: string;
  firstItemIsOpen?: boolean;
  items: AccordionItem[];
  className?: string;
}


const Accordion: FC<AccordionProps> = ({
  id,
  items,
  className = '',
  firstItemIsOpen,
}): ReactElement => (
  <div className={`accordion ${className}`}>
    <ul className="accordion__list">
      {items.map((item, index) => (
        <li key={`${id}-${item.label}`} className="accordion__item">
          <input id={`${id}-${item.label}`} type="checkbox" defaultChecked={firstItemIsOpen && index === 0} className="accordion__checkbox" />
          <label htmlFor={`${id}-${item.label}`} className="accordion__label">{item.label}</label>
          { item.htmlContent && <div className="accordion__content" dangerouslySetInnerHTML={{ __html: item.htmlContent }} /> }
          { (!item.htmlContent && item.element) && <div className="accordion__content">{item.element}</div> }
        </li>
      ))}
    </ul>
  </div>
);


export default Accordion;

import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import getReflectionTranslation from '../../helpers/getReflectionTranslation';
import { ReflectionType } from '../../Reflections';

import './ReflectionItem.scss';

interface ReflectionItemProps {
  type: ReflectionType;
  hasReflectionTypePrefix: boolean;
  content?: string;
  className?: string;
}

const ReflectionItem: FC<ReflectionItemProps> = ({
  type,
  content = '',
  hasReflectionTypePrefix = true,
  children,
  className = '',
}): ReactElement => {
  const hasIcon = !!type && hasReflectionTypePrefix;
  const classNames = classnames('reflection-item', {
    [`reflection-item--is-${type}`]: hasIcon,
    'reflection-item--has-icon': hasIcon,
  }, className);

  return (
    <li className={classNames}>
      <p>
        {hasReflectionTypePrefix && (
          <em>
            {`${getReflectionTranslation(type)}: `}
          </em>
        )}
        {content}
      </p>
      { children }
    </li>
  );
};

export default ReflectionItem;

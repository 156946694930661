import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { SuccesfactorenStart } from '../../containers';

type SuccesfactorenStartProps = RouteComponentProps;


const Start: FC<SuccesfactorenStartProps> = (): ReactElement => (
  <main className="page">
    <SuccesfactorenStart />
  </main>
);


export default Start;

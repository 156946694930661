import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { SuccesfactorenToelichting } from '../../containers';

type SuccesfactorenUitlegEnScoreProps = RouteComponentProps;


const SuccesfactorenUitlegEnScore: FC<SuccesfactorenUitlegEnScoreProps> = (): ReactElement => (
  <main className="page">
    <SuccesfactorenToelichting />
  </main>
);

export default SuccesfactorenUitlegEnScore;

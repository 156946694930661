import moduleEstimates from '../data/moduleEstimates';
import { dynamicRouteModules, ModuleSlugType, NavRouteModule } from '../Routes';
import { LocationIndexes } from './location/retrieveLocationIndexes';

export interface ModuleProgression {
  title: string;
  path: string;
  progression: number;
  estimationInMinutes: number;
}

function getModuleProgression(module: NavRouteModule, locationIndexes: LocationIndexes, index: number) {
  if (index < locationIndexes.moduleIndex) {
    return 100;
  }

  if (index === locationIndexes.moduleIndex) {
    return Math.min((locationIndexes.pageIndex / (module.pages.length - 1)) * 100, 100);
  }

  return 0;
}

function getModulePageSlug(module: NavRouteModule, index: number, locationIndexes: LocationIndexes): string {
  if (index < locationIndexes.moduleIndex) {
    return module.pages[0].slug;
  }

  if (index === locationIndexes.moduleIndex) {
    return module.pages[locationIndexes.pageIndex].slug;
  }

  return module.pages[0].slug;
}

export default function getAllModuleProgressions(locationIndexes: LocationIndexes): ModuleProgression[] {
  return dynamicRouteModules.map((module, index) => {
    const modulePage = getModulePageSlug(module, index, locationIndexes);

    return {
      title: module.title,
      path: `/${module.slug}/${modulePage}`,
      progression: getModuleProgression(module, locationIndexes, index),
      estimationInMinutes: moduleEstimates[module.slug as ModuleSlugType] || 30,
    };
  });
}

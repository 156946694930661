import { put, takeLatest } from 'redux-saga/effects';
// import { postData } from '../../helpers/api/pagesApi';
import {
  USER_SET_FURTHEST_LOCATION,
  USER_SAGA_SET_FURTHEST_LOCATION,
  USER_SET_MOTIVATIONS,
  USER_SAGA_SET_MOTIVATIONS,
  USER_SET_MOST_IMPORTANT_PROCEED,
  USER_SAGA_SET_MOST_IMPORTANT_PROCEED,
  USER_SET_MOST_IMPORTANT_PROCEED_RATING,
  USER_SAGA_SET_MOST_IMPORTANT_PROCEED_RATING,
  USER_SET_ORGANIZATION_PROCEED,
  USER_SAGA_SET_ORGANIZATION_PROCEED,
  USER_SET_ORGANIZATION_PROCEED_RATING,
  USER_SAGA_SET_ORGANIZATION_PROCEED_RATING,
  USER_SET_SUCCESS_FACTOR_LEADERSHIP,
  USER_SAGA_SET_SUCCESS_FACTOR_LEADERSHIP,
  USER_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE,
  USER_SAGA_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE,
  USER_SET_TOP_3_PROCEEDS_DEFINITIVE,
  USER_SAGA_SET_TOP_3_PROCEEDS_DEFINITIVE,
  USER_SET_TOP_3_PROCEEDS_INITIAL,
  USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL,
  USER_SET_TOP_3_PROCEEDS_INITIAL_RATING,
  USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL_RATING,
  USER_SET_WORKPLACE,
  USER_SAGA_SET_WORKPLACE,
} from './userTypes';

function* setFurthestLocation({ furthestLocation }) {
  // postData(furthestLocation);

  yield put({ type: USER_SET_FURTHEST_LOCATION, furthestLocation });
}

function* setMotivations({ motivations }) {
  // postData(motivations);

  yield put({ type: USER_SET_MOTIVATIONS, motivations });
}

function* setOrganizationProceed({ organizationProceed }) {
  // postData(organizationProceed);

  yield put({ type: USER_SET_ORGANIZATION_PROCEED, organizationProceed });
}

function* setOrganizationProceedRating({ organizationProceedRating }) {
  // postData(organizationProceedRating);

  yield put({ type: USER_SET_ORGANIZATION_PROCEED_RATING, organizationProceedRating });
}

function* setMostImportantProceed({ mostImportantProceed }) {
  // postData(mostImportantProceed);

  yield put({ type: USER_SET_MOST_IMPORTANT_PROCEED, mostImportantProceed });
}

function* setMostImportantProceedRating({ mostImportantProceedRating }) {
  // postData(mostImportantProceedRating);

  yield put({ type: USER_SET_MOST_IMPORTANT_PROCEED_RATING, mostImportantProceedRating });
}

function* setSuccessFactorLeaderShip({ successFactorLeaderShip }) {
  // postData(successFactorLeaderShip);

  yield put({ type: USER_SET_SUCCESS_FACTOR_LEADERSHIP, successFactorLeaderShip });
}

function* setSuccessFactorCulturalResilience({ successFactorCulturalResilience }) {
  // postData(successFactorCulturalResilience);

  yield put({ type: USER_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE, successFactorCulturalResilience });
}

function* setTop3ProceedsDefinitive({ top3ProceedsDefinitive }) {
  // postData(top3ProceedsDefinitive);

  yield put({ type: USER_SET_TOP_3_PROCEEDS_DEFINITIVE, top3ProceedsDefinitive });
}

function* setTop3ProceedsInitial({ top3ProceedsInitial }) {
  // postData(top3ProceedsInitial);

  yield put({ type: USER_SET_TOP_3_PROCEEDS_INITIAL, top3ProceedsInitial });
}

function* setTop3ProceedsInitialRating({ top3ProceedsInitialRating }) {
  // postData(top3ProceedsInitialRating);

  yield put({ type: USER_SET_TOP_3_PROCEEDS_INITIAL_RATING, top3ProceedsInitialRating });
}

function* setWorkplace({ workplace }) {
  // postData(workplace);

  yield put({ type: USER_SET_WORKPLACE, workplace });
}

export default function* userWatcher() {
  yield takeLatest(USER_SAGA_SET_FURTHEST_LOCATION, setFurthestLocation);
  yield takeLatest(USER_SAGA_SET_MOTIVATIONS, setMotivations);
  yield takeLatest(USER_SAGA_SET_MOST_IMPORTANT_PROCEED, setMostImportantProceed);
  yield takeLatest(USER_SAGA_SET_MOST_IMPORTANT_PROCEED_RATING, setMostImportantProceedRating);
  yield takeLatest(USER_SAGA_SET_ORGANIZATION_PROCEED, setOrganizationProceed);
  yield takeLatest(USER_SAGA_SET_ORGANIZATION_PROCEED_RATING, setOrganizationProceedRating);
  yield takeLatest(USER_SAGA_SET_SUCCESS_FACTOR_LEADERSHIP, setSuccessFactorLeaderShip);
  yield takeLatest(USER_SAGA_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE, setSuccessFactorCulturalResilience);
  yield takeLatest(USER_SAGA_SET_TOP_3_PROCEEDS_DEFINITIVE, setTop3ProceedsDefinitive);
  yield takeLatest(USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL, setTop3ProceedsInitial);
  yield takeLatest(USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL_RATING, setTop3ProceedsInitialRating);
  yield takeLatest(USER_SAGA_SET_WORKPLACE, setWorkplace);
}

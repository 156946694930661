import React, {
  FC,
  FormEvent,
  ReactElement,
  useState,
} from 'react';
import { connect, DispatchProp } from 'react-redux';

import { Logo } from '../../components';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import LoginForm from '../../components/LoginForm/LoginForm';
import { authenticateUserWithCredentials, requestUserPasswordReset } from '../../redux/auth/authActions';
import { Reducers } from '../../redux/reducers';

import './Login.scss';


interface MapStateToPropsType {
  loading: boolean;
}

type LoginProps = DispatchProp;

const Login: FC<LoginProps> = ({ dispatch }): ReactElement => {
  const [activeForm, setActiveForm] = useState<'login' | 'forgot-password'>('login');
  const [userEmail, setUserEmail] = useState();
  const [userPassword, setUserPassword] = useState();
  const [userForgotPasswordSubmitted, setUserForgotPasswordSubmitted] = useState(false);
  const [loginFeedback, setLoginFeedback] = useState('');

  const handleLoginSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (userEmail && userPassword) {
      const credentials = {
        email: userEmail,
        password: userPassword,
      };

      dispatch(authenticateUserWithCredentials(credentials, (): void => {
        setLoginFeedback('De combinatie gebruikersnaam/wachtwoord is onjuist.');
      }));
    }
  };

  const handleForgotPasswordSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (userEmail) {
      dispatch(requestUserPasswordReset(userEmail));
      setUserForgotPasswordSubmitted(true);
    }
  };

  const handleForgotPasswordClick = () => {
    setActiveForm('forgot-password');
  };

  const handleBackButtonClick = () => {
    setActiveForm('login');
    setUserForgotPasswordSubmitted(false);
  };

  return (
    <main className="login-page">
      <div className="login-page__container">
        <div className="login-page__info">
          <Logo className="login-page__info-logo" />
          <h1 className="login-page__info-title">Welkom in de MKAT Online tool</h1>
          <p className="login-page__info-text">Vul uw gegevens in om in te loggen.</p>
          <p className="login-page__info-privacy-statement">
            De MKAT tool maakt gebruik van cookies.
            <br />
            <a href="/pdf/VU MKAT Online Tool - Privacy Statement 2020.pdf" target="_blank" rel="noopener noreferrer">Download ons privacy statement</a>
          </p>
        </div>
        <div className="login-page__card">
          { activeForm === 'login' ? (
            <LoginForm
              userEmail={userEmail}
              userPassword={userPassword}
              onSubmit={handleLoginSubmit}
              onForgotPasswordClick={handleForgotPasswordClick}
              onUserEmailChange={setUserEmail}
              onUserPasswordChange={setUserPassword}
              loginFeedback={loginFeedback}
            />
          ) : (
            <ForgotPasswordForm
              userEmail={userEmail}
              onSubmit={handleForgotPasswordSubmit}
              onUserEmailChange={setUserEmail}
              onBackButtonClick={handleBackButtonClick}
              submitted={userForgotPasswordSubmitted}
            />
          )}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  loading: state.authReducer.loading,
});

export default connect(mapStateToProps)(Login);

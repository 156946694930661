import React, { FC, ReactElement } from 'react';

import { Button, ProgressBar } from '../../components';
import DurationEstimation from '../../components/DurationEstimation/DurationEstimation';
import './ModuleStatusCard.scss';

export interface ModuleStatusCardProps {
  className?: string;
  title: string;
  path: string;
  isDisabled?: boolean;
  progress?: number;
  estimation?: number;
  intro?: string;
}


const ModuleStatusCard: FC<ModuleStatusCardProps> = ({
  className = '',
  title,
  path,
  isDisabled = false,
  progress,
  estimation,
  intro,
}): ReactElement => (
  <div className={`module-status-card ${className}`}>
    <div className="module-status-card__header">
      <div className="module-status-card__title">
        {title}
      </div>

      { estimation !== undefined && <DurationEstimation className="module-status-card__duration-estimation" minutes={estimation} /> }
    </div>

    { progress !== undefined && <ProgressBar className="module-status-card__progress-bar" progress={progress} /> }
    { intro && <p className="module-status-card__intro">{intro}</p>}

    <Button
      text="Naar module"
      icon="chevron-right"
      iconPos="right"
      path={path}
      disabled={isDisabled}
      className="module-status-card__button"
    />
  </div>
);

export default ModuleStatusCard;

import React, { FC, ReactElement } from 'react';
import { DrijfverenBibliotheek } from '../../containers';


const Bibliotheek: FC = (): ReactElement => (
  <main className="page">
    <DrijfverenBibliotheek />
  </main>
);

export default Bibliotheek;

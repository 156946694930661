import { ProceedType } from '../../../data';
import { estimates } from '../../../fetches/estimates';
import { proceedTypes } from '../ConnectedOpbrengstenDiagnose';

export default function patchImportance(givenImportance: Partial<Record<ProceedType, number | null>>, apiImportance: any): void {
  Object.keys(givenImportance).map(((key) => {
    const index = proceedTypes.findIndex(proceedType => key === proceedType);
    const importance = givenImportance[key as ProceedType];
    const question = apiImportance[index];

    if (question && importance) {
      return estimates.patch(question.id, {
        attributes: {
          answer: importance ? String(importance) : '',
        },
        relationships: {},
      });
    }

    return null;
  }));
}

import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StartCultureleDiversiteit } from '../../containers';
import { questions as questionJapi } from '../../fetches/questions';
import { getAllFormDataAndDispatch } from '../../helpers/tools';
import useQuestion from '../../helpers/useQuestion';
import { setPageFinished } from '../../redux/pages/pagesActions';
import { Reducers } from '../../redux/reducers';

const PAGE = 'start-culturele-diversiteit';

const ConnectedStartCultureleDiversiteit: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const data = useSelector((state: Reducers) => state.pagesReducer.pages[PAGE]);

  const questionOne = useQuestion(1);
  const questionTwo = useQuestion(2);
  const questionThree = useQuestion(3);

  const onPrevPaginationPageChange = (pageRef: React.RefObject<HTMLDivElement>) => {
    getAllFormDataAndDispatch(pageRef, dispatch, PAGE);
  };

  const onPageFinished = (questionOneAnswer: string, questionTwoAnswer: string, questionThreeAnswer: string) => {
    dispatch(setPageFinished(PAGE));

    if (questionOne) {
      questionJapi.patch(questionOne.id, {
        attributes: {
          answer: questionOneAnswer,
        },
        relationships: {},
      });
    }
    if (questionTwo) {
      questionJapi.patch(questionTwo.id, {
        attributes: {
          answer: questionTwoAnswer,
        },
        relationships: {},
      });
    }
    if (questionThree) {
      questionJapi.patch(questionThree.id, {
        attributes: {
          answer: questionThreeAnswer,
        },
        relationships: {},
      });
    }
  };

  return (
    <StartCultureleDiversiteit
      questionOne={questionOne}
      questionTwo={questionTwo}
      questionThree={questionThree}
      data={data}
      onPrevPaginationPageChange={onPrevPaginationPageChange}
      onPageFinished={onPageFinished}
    />
  );
};

export default ConnectedStartCultureleDiversiteit;

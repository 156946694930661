import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { OpbrengstenOpdracht } from '../../containers';
import { ProceedType } from '../../data';
import useQuestion from '../../helpers/useQuestion';

const ConnectedOpbrengstenOpdracht: FC = (): ReactElement => {
  const [chosenProceedType, setChosenProceedType] = useState<ProceedType | null>(null);

  const apiChosenProceedType = useQuestion(9, '/opbrengsten/diagnose');

  useEffect(() => {
    if (apiChosenProceedType && apiChosenProceedType.attributes) {
      setChosenProceedType(apiChosenProceedType.attributes.answer as ProceedType);
    }
  }, [apiChosenProceedType]);

  return (
    <OpbrengstenOpdracht chosenProceedType={chosenProceedType} />
  );
};

export default ConnectedOpbrengstenOpdracht;

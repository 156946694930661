import React, { FC, ReactElement } from 'react';

import './ProgressBar.scss';

interface ProgressBarProps {
  progress: number;
  className?: string;
}


const ProgressBar: FC<ProgressBarProps> = ({ progress, className = '' }): ReactElement => (
  <div className={`progress-bar ${className}`}>
    <div className="progress-bar__header">
      <div className="progress-bar__title">
        Voortgang
      </div>
      <div className="progress-bar__progress">
        {`${Math.round(progress)}%`}
      </div>
    </div>

    <div className="progress-bar__bar">
      <div className="progress-bar__bar-inner" style={{ width: `${progress}%` }} />
    </div>
  </div>
);


export default ProgressBar;

import React, { ChangeEvent, FC, ReactElement } from 'react';

import './RadioInput.scss';

interface RadioInputProps {
  label: string;
  defaultChecked?: boolean;
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  className?: string;
}


const RadioInput: FC<RadioInputProps> = ({
  label,
  defaultChecked,
  name,
  value,
  disabled,
  onChange,
  className = '',
}): ReactElement => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  return (
    <div className={`radio-input ${className}`}>
      <input
        id={`${name}-${value}`}
        defaultChecked={defaultChecked}
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        className="radio-input__input"
      />
      <label htmlFor={`${name}-${value}`} className="radio-input__label-container">
        <div className="radio-input__indication-box" />
        <span className="radio-input__label">{label}</span>
      </label>
    </div>
  );
};


export default RadioInput;

import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect, DispatchProp } from 'react-redux';
import useRouter from 'use-react-router';

import { Reducers } from '../../redux/reducers';
import { setFurthestLocation } from '../../redux/user/userActions';
import { FurthestLocation } from '../../redux/user/userTypes';

import { users } from '../../fetches/users';

import DynamicNavigationModule from './subcomponents/DynamicNavigationModule';
import NavigationModule from './subcomponents/NavigationModule';

import './SideNavigation.scss';

import getCleanLocation from '../../helpers/location/getCleanLocation';
import retrieveLocationIndexes from '../../helpers/location/retrieveLocationIndexes';
import {
  dynamicRouteModules,
  getRouteModuleIndex,
  getRoutePageIndex,
  initialPage,
  staticRouteModules,
} from '../../Routes';
import { Button } from '../../components';

interface MapStateToPropsType {
  furthestLocation: FurthestLocation | any;
  userId: string;
}

type SideNavigationProps = DispatchProp & MapStateToPropsType;


const SideNavigation: FC<SideNavigationProps> = ({ dispatch, furthestLocation, userId }): ReactElement => {
  const { history, location } = useRouter();

  const [activeModule, setActiveModule] = useState(dynamicRouteModules[0].slug);
  const [activePage, setActivePage] = useState(dynamicRouteModules[0].pages[0].slug);
  const [furthestModule, setFurthestModule] = useState(furthestLocation.module || activeModule);
  const [furthestPage, setFurthestPage] = useState(furthestLocation.page || activePage);
  const cleanLocation = getCleanLocation(location.pathname);

  useEffect((): void => {
    if (location.pathname === '/') {
      history.push(`/${initialPage}`);
    }
  }, [cleanLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    const currentModule = cleanLocation[0];
    const currentPage = cleanLocation[1];
    const isStaticModule = staticRouteModules.filter(route => route.slug === currentModule).length > 0;

    if (currentModule === 'login' || currentModule === 'wachtwoord-instellen') {
      return;
    }

    setActiveModule(currentModule);
    setActivePage(currentPage);

    if (!isStaticModule) {
      const storedFurthestLocationIndexes = retrieveLocationIndexes(furthestLocation.module, furthestLocation.page);
      const currentLocationIndexes = retrieveLocationIndexes(currentModule, currentPage);

      const curModuleIndex = currentLocationIndexes.moduleIndex;
      const curPageIndex = currentLocationIndexes.pageIndex;

      const storedModuleIndex = storedFurthestLocationIndexes.moduleIndex;
      const storedPageIndex = storedFurthestLocationIndexes.pageIndex;

      if ((curModuleIndex > storedModuleIndex) || (curModuleIndex === storedModuleIndex && curPageIndex > storedPageIndex)) {
        dispatch(setFurthestLocation({ module: currentModule, page: currentPage }));

        if (userId) {
          users.patch(String(userId), {
            attributes: { lastVisitedPage: `${currentModule}/${currentPage}` },
            relationships: {},
          });
        }
      }
    }
  }, [cleanLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    const activeModuleIndex = getRouteModuleIndex(activeModule);
    if (activeModuleIndex > getRouteModuleIndex(furthestModule)) {
      setFurthestModule(activeModule);
      setFurthestPage(dynamicRouteModules[activeModuleIndex].pages[0].slug);
    }
  }, [activeModule, furthestModule]);

  useEffect((): void => {
    const activeModuleIndex = getRouteModuleIndex(activeModule);
    if (activeModuleIndex === getRouteModuleIndex(furthestModule)) {
      if (getRoutePageIndex(activeModuleIndex, activePage) > getRoutePageIndex(activeModuleIndex, furthestPage)) {
        setFurthestPage(activePage);
      }
    }
  }, [activeModule, activePage, furthestModule, furthestPage]);

  useEffect((): void => {
    setFurthestModule(furthestLocation.module);
    setFurthestPage(furthestLocation.page);
  }, [furthestLocation]);

  return (
    <div className="side-navigation">
      <Button
        className="side-navigation__link-dashboard"
        iconClassName="side-navigation__link-dashboard-icon"
        text="Terug naar modules"
        icon="chevron-left"
        iconPos="left"
        path="/dashboard"
      />
      {dynamicRouteModules.map((module) => (
        <DynamicNavigationModule
          key={module.slug}
          module={module}
          canCollapse={getRouteModuleIndex(module.slug) <= getRouteModuleIndex(furthestModule)}
          activeModule={activeModule}
          furthestModule={furthestModule}
          furthestPage={furthestPage}
        />
      ))}
      {staticRouteModules.map((module) => (
        <NavigationModule
          key={module.slug}
          module={module}
          activeModule={activeModule}
        />
      ))}
    </div>
  );
};


const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  furthestLocation: state.userReducer.furthestLocation,
  userId: state.authReducer.userId,
});

export default connect(mapStateToProps)(SideNavigation);

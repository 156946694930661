import {
  FurthestLocation,
  Motivations,
  SetUserFurthestLocationAction,
  SetUserMostImportantProceedAction,
  SetUserMostImportantProceedRatingAction,
  SetUserMotivationsAction,
  SetUserOrganizationProceedAction,
  SetUserOrganizationProceedRatingAction,
  SetUserSuccessFactorCulturalResilienceAction,
  SetUserSuccessFactorLeaderShipAction,
  SetUserTop3ProceedsDefinitiveAction,
  SetUserTop3ProceedsInitialAction,
  SetUserTop3ProceedsInitialRatingAction,
  SetUserWorkplaceAction,
  Workplace,
} from './userTypes';

export const setFurthestLocation = (furthestLocation: FurthestLocation): SetUserFurthestLocationAction => ({
  type: 'USER_SAGA_SET_FURTHEST_LOCATION', furthestLocation,
});

export const setMotivations = (motivations: Motivations): SetUserMotivationsAction => ({
  type: 'USER_SAGA_SET_MOTIVATIONS', motivations,
});

export const setSuccessFactorLeaderShip = (successFactorLeaderShip: number): SetUserSuccessFactorLeaderShipAction => ({
  type: 'USER_SAGA_SET_SUCCESS_FACTOR_LEADERSHIP', successFactorLeaderShip,
});

export const setSuccessFactorCulturalResilience = (successFactorCulturalResilience: number): SetUserSuccessFactorCulturalResilienceAction => ({
  type: 'USER_SAGA_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE', successFactorCulturalResilience,
});

export const setOrganizationProceed = (organizationProceed: string): SetUserOrganizationProceedAction => ({
  type: 'USER_SAGA_SET_ORGANIZATION_PROCEED', organizationProceed,
});

export const setOrganizationProceedRating = (organizationProceedRating: number): SetUserOrganizationProceedRatingAction => ({
  type: 'USER_SAGA_SET_ORGANIZATION_PROCEED_RATING', organizationProceedRating,
});

export const setTop3ProceedsInitial = (top3ProceedsInitial: string[]): SetUserTop3ProceedsInitialAction => ({
  type: 'USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL', top3ProceedsInitial,
});

export const setTop3ProceedsInitialRating = (top3ProceedsInitialRating: { [key: string]: number }): SetUserTop3ProceedsInitialRatingAction => ({
  type: 'USER_SAGA_SET_TOP_3_PROCEEDS_INITIAL_RATING', top3ProceedsInitialRating,
});

export const setMostImportantProceed = (mostImportantProceed: string): SetUserMostImportantProceedAction => ({
  type: 'USER_SAGA_SET_MOST_IMPORTANT_PROCEED', mostImportantProceed,
});

export const setMostImportantProceedRating = (mostImportantProceedRating: number): SetUserMostImportantProceedRatingAction => ({
  type: 'USER_SAGA_SET_MOST_IMPORTANT_PROCEED_RATING', mostImportantProceedRating,
});

export const setTop3ProceedsDefinitive = (top3ProceedsDefinitive: string[]): SetUserTop3ProceedsDefinitiveAction => ({
  type: 'USER_SAGA_SET_TOP_3_PROCEEDS_DEFINITIVE', top3ProceedsDefinitive,
});

export const setWorkplace = (workplace: Workplace): SetUserWorkplaceAction => ({
  type: 'USER_SAGA_SET_WORKPLACE', workplace,
});

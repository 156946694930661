import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';
import { Dashboard } from '../../containers';
import getAllModuleProgressions, { ModuleProgression } from '../../helpers/getAllModuleProgressions';
import getTotalModulesProgression from '../../helpers/getTotalModulesProgression';
import getCleanLocation from '../../helpers/location/getCleanLocation';
import retrieveLocationIndexes from '../../helpers/location/retrieveLocationIndexes';
import { Reducers } from '../../redux/reducers';


const ConnectedDashboard: FC = (): ReactElement => {
  const { location } = useRouter();
  const furthestLocation = useSelector((state: Reducers) => state.userReducer.furthestLocation);
  const [moduleProgressions, setModuleProgressions] = useState<ModuleProgression[]>([]);
  const [totalProgress, setTotalProgress] = useState(0);
  const [cleanLocation] = useState(getCleanLocation(location.pathname));

  useEffect(() => {
    if (furthestLocation) {
      const storedFurthestLocationIndexes = retrieveLocationIndexes(furthestLocation.module, furthestLocation.page);
      setTotalProgress(getTotalModulesProgression(storedFurthestLocationIndexes));
      setModuleProgressions(getAllModuleProgressions(storedFurthestLocationIndexes));
    }
  }, [cleanLocation, furthestLocation]);

  return (
    <Dashboard totalProgress={totalProgress} moduleProgressions={moduleProgressions} />
  );
};

export default ConnectedDashboard;

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { SingleJsonApiResponseType } from '../../fetches/JsonApiResponse';
import { addPatch, getOrCreateQuestion, removePatch } from './questionsActions';

export type QuestionState = Readonly<{
  loading: boolean;
  question?: SingleJsonApiResponseType<'questions'>['data'];
  patching: boolean;
  patchingQueue: number;
}>;

const initialState: QuestionState = {
  loading: false,
  patching: false,
  patchingQueue: 0,
};

const questionReducer = reducerWithInitialState(initialState)
  .case(getOrCreateQuestion.done, ((state, payload) => ({
    ...state, question: payload.result,
  })))
  .case(addPatch, (state => ({
    ...state, patchingQueue: state.patchingQueue + 1, patching: true,
  })))
  .case(removePatch, (state => ({
    ...state, patchingQueue: state.patchingQueue - 1, patching: state.patchingQueue - 1 > 0,
  })));
export default questionReducer;

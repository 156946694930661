import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { HTMLContent, PageHeader } from '../../components';

import content from '../../content';

type BibliotheekDrijfverenProps = RouteComponentProps;


const BibliotheekDrijfveren: FC<BibliotheekDrijfverenProps> = (): ReactElement => (
  <main className="page">
    <PageHeader title="Bibliotheek: Drijfveren" />
    <HTMLContent html={content.bibliotheek.drijfveren} />
  </main>
);


export default BibliotheekDrijfveren;

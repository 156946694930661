import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconCheck: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 340.2 283.5" className={`icon ${className}`}>
    <path d="M308.2,9.2l23.4,25.5C343,47,343,68,331.7,80.3L179.7,244.7c-0.9,1.5-2,2.8-3.2,4.1l-23.4,25.5c-11.3,12.3-30.7,12.3-42,0L8.5,161.9c-11.3-12.3-11.3-33.3,0-45.6l23.4-25.5c11.3-12.3,30.7-12.3,42,0l58.1,63.5L266.2,9.2C277.5-3.1,296.9-3.1,308.2,9.2z" />
  </svg>
);


export default IconCheck;

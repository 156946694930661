import React, { FC, ReactElement } from 'react';
import { SuccesfactorenOpdracht } from '../../containers';

const Start: FC = (): ReactElement => (
  <main className="page">
    <SuccesfactorenOpdracht />
  </main>
);


export default Start;

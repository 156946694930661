import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { DrijfverenIntroductie } from '../../containers';

type DrijfverenIntroductieProps = RouteComponentProps;


const Introductie: FC<DrijfverenIntroductieProps> = (): ReactElement => (
  <main className="page">
    <DrijfverenIntroductie />
  </main>
);


export default Introductie;

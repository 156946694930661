import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconDot: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 283.5 283.5" className={`icon ${className}`}>
    <circle cx="141.7" cy="141.7" r="141.7" />
  </svg>
);


export default IconDot;

import japi from './japi';

const endpoint = 'questions';

export interface Question {
  attributes: {
    page: string;
    answer: string;
    number: number;
    comment?: string;
  };
  relationships: {
    user: {
      data: {
        type: 'users';
        id: string;
      };
    };
  };
}

export const questions = japi(endpoint, 'questions');

import React, { FC, FormEvent, ReactElement } from 'react';

import { convertStringToDash } from '../../helpers/tools';

import { RadioInput } from '../../components';
import './BinaryChoice.scss';

interface BinaryChoiceProps {
  data?: any;
  title: string;
  name: string;
  firstOption: string;
  secondOption: string;
  disabled?: boolean;
  onChange?: (values: string) => void;
  className?: string;
}

const BinaryChoice: FC<BinaryChoiceProps> = ({
  data,
  title,
  firstOption,
  secondOption,
  disabled,
  onChange,
  className = '',
  ...rest
}): ReactElement => {
  const name = rest.name || convertStringToDash(title);
  const defaultValue = data ? data[name] : undefined;

  const handleChange = (value: string): void => {
    if (onChange) {
      onChange(value);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => event.preventDefault();

  return (
    <form key={title} onSubmit={handleSubmit} className={`binary-choice ${className}`}>
      <legend className="binary-choice__title">{title}</legend>
      <div className="binary-choice__input-container">
        <RadioInput
          defaultChecked={defaultValue === firstOption}
          label={firstOption}
          name={name}
          value={firstOption}
          disabled={disabled}
          onChange={handleChange}
          className="binary-choice__input"
        />
        <RadioInput
          defaultChecked={defaultValue === secondOption}
          label={secondOption}
          name={name}
          value={secondOption}
          disabled={disabled}
          onChange={handleChange}
          className="binary-choice__input"
        />
      </div>
    </form>
  );
};

export default BinaryChoice;

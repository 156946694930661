import React, { FC, ReactElement } from 'react';

import { HTMLContent } from '..';
import content from '../../content';

interface OrganisationInfoProps {
  organization: string | null;
}

const MyOrganisationInfo: FC<OrganisationInfoProps> = ({ organization }): ReactElement => {

  if (!organization) {
    return (
      <p>
        <span>Uw account is niet gekoppeld met een organisatie. U kunt doorgaan door aan de onderkant van de pagina op </span>
        <strong>Volgende</strong>
        <span> te klikken.</span>
      </p>
    );
  }

  const intro = content.start.mijnOrganisatie.organizations[organization];
  const video = content.start.mijnOrganisatie.organizationVideo[organization];

  return (
    <>
      {intro && <HTMLContent html={intro} />}
      {video && (
        <iframe
          title="Kwaliteit"
          src={video}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </>
  );
};

export default MyOrganisationInfo;

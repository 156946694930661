import japi from './japi';
import { SingleJsonApiResponseType } from './JsonApiResponse';

const endpoint = 'case/studies';

export interface CaseStudies {
  attributes: {
    number: number;
    answer: string;
  };
  relationships: {
    user: {
      data: {
        type: 'users';
        id: string;
      };
    };
  };
}

export const caseStudies = japi(endpoint, 'case_studies');

export const getOrCreateCaseStudyForUser = async (userId: string, num: number): Promise<SingleJsonApiResponseType<'case_studies'>['data']> => {
  const fetchResponse = await caseStudies.fetch({
    filters: {
      'user.id': userId,
      number: num,
    },
  });

  if (fetchResponse.data.data.length === 0) {
    const postResponse = await caseStudies.post({
      attributes: {
        number: num,
        answer: '',
      },
      relationships: {
        user: {
          data: {
            type: 'users',
            id: userId.toString(),
          },
        },
      },
    });

    return postResponse.data.data;
  }

  return fetchResponse.data.data[fetchResponse.data.data.length - 1];
};

import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router';

import { connect, DispatchProp } from 'react-redux';

import { setPageFinished } from '../../redux/pages/pagesActions';
import { Reducers } from '../../redux/reducers';

import { HTMLContent, PageHeader } from '../../components';
import { BottomNavigation, LikertScale, QuizQuestion } from '../../containers';

import content from '../../content';
import useQuestion from '../../helpers/useQuestion';

import { questions } from '../../fetches/questions';

const PAGE = 'drijfveren-leerdoelen';

interface MapStateToPropsType {
  [PAGE]: any;
}

type DrijfverenLeerdoelenProps = RouteComponentProps & DispatchProp & MapStateToPropsType;


const DrijfverenLeerdoelen: FC<DrijfverenLeerdoelenProps> = ({ dispatch, [PAGE]: data }): ReactElement => {
  const [finishedPageState, setFinishedPageState] = useState(false);

  const [nextButtonLock, setNextButtonLock] = useState(true);
  const [givenAnswers, updateGivenAnswers] = useState<any[]>([null, null, null, null]);
  const [feedback, setFeedback] = useState('');
  const questionOne = useQuestion(1);
  const questionTwo = useQuestion(2);
  const questionThree = useQuestion(3);
  const questionFour = useQuestion(4);
  const questionFive = useQuestion(5);
  const questionSix = useQuestion(6);

  const handleLikertScaleChange = (question: string, range: number): void => {
    if (givenAnswers.indexOf(question) === -1) {
      const index = content.drijfveren.leerdoelen.ratings.findIndex(((value: string) => value === question));
      givenAnswers[index] = range;

      updateGivenAnswers(givenAnswers);
    }

    setNextButtonLock(givenAnswers.filter(answer => answer).length < 4);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect((): void => {
    if (
      questionOne
      && questionTwo
      && questionThree
      && questionFour
      && questionFive
    ) {
      if (
        questionOne.attributes.answer
        && questionTwo.attributes.answer
        && questionThree.attributes.answer
        && questionFour.attributes.answer
        && questionFive.attributes.answer
      ) {
        setFinishedPageState(true);
      }
    }
  });

  const finishedPage = data.finshed || finishedPageState;

  const handleSubmit = (): void => {
    if (!finishedPage) {
      dispatch(setPageFinished(PAGE));
      if (questionOne) {
        questions.patch(questionOne.id, {
          attributes: {
            answer: givenAnswers[0],
          },
          relationships: {},
        });
      }
      if (questionTwo) {
        questions.patch(questionTwo.id, {
          attributes: {
            answer: givenAnswers[1],
          },
          relationships: {},
        });
      }
      if (questionThree) {
        questions.patch(questionThree.id, {
          attributes: {
            answer: givenAnswers[2],
          },
          relationships: {},
        });
      }
      if (questionFour) {
        questions.patch(questionFour.id, {
          attributes: {
            answer: givenAnswers[3],
          },
          relationships: {},
        });
      }
      if (questionFive) {
        questions.patch(questionFive.id, {
          attributes: {
            answer: givenAnswers[4],
          },
          relationships: {},
        });
      }
    }

    if (questionSix && feedback) {
      questions.patch(questionSix.id, {
        attributes: {
          answer: feedback,
        },
        relationships: {},
      });
    }
  };

  return (
    <main className="page">
      <div className="page__content">
        <PageHeader title="Leerdoelen" />
        <p>{content.drijfveren.leerdoelen.intro}</p>

        {(questionOne && questionTwo && questionThree && questionFour && questionFive) !== undefined ? (
          <LikertScale
            name="drijfveren-leerdoelen"
            labelLeast="Helemaal niet bereikt"
            labelMost="Volledig bereikt"
            questions={content.drijfveren.leerdoelen.ratings}
            defaultValues={[
              questionOne ? Number(questionOne.attributes.answer) : undefined,
              questionTwo ? Number(questionTwo.attributes.answer) : undefined,
              questionThree ? Number(questionThree.attributes.answer) : undefined,
              questionFour ? Number(questionFour.attributes.answer) : undefined,
              questionFive ? Number(questionFive.attributes.answer) : undefined,
            ]}
            onChange={handleLikertScaleChange}
            disabled={finishedPage}
          />
        ) : <span>Laden...</span>}

        <br />
        <br />
        <HTMLContent html={content.drijfveren.leerdoelen.feedbackText} />
        <br />
        <QuizQuestion
          type="textarea"
          name="drijfveren-leerdoelen"
          question="Feedback?"
          defaultValue={questionSix ? questionSix.attributes.answer : undefined}
          onChange={setFeedback}
        />
      </div>

      <BottomNavigation
        isForm
        unblockedPagination={finishedPage}
        lockNextButton={nextButtonLock}
        onSubmit={handleSubmit}
      />
    </main>
  );
};


const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  [PAGE]: state.pagesReducer.pages[PAGE],
});

export default connect(mapStateToProps)(DrijfverenLeerdoelen);

import {
  put, select, takeLatest,
} from 'redux-saga/effects';
// import { postData } from '../../helpers/api/pagesApi';
import {
  PAGES_SAGA_SET_PAGE_DATA,
  PAGES_SAGA_SET_PAGE_FINISHED,
  PAGES_SET_PAGE_DATA,
  PAGES_SET_PAGE_FINISHED,
} from './pagesTypes';

function* setPageData({ data, page }) {
  const state = yield select();
  const { pages } = state.pagesReducer;

  yield put({ type: PAGES_SET_PAGE_DATA, data: { ...pages[page], ...data }, page });
}

function* setPageFinished({ page }) {
  const state = yield select();
  const { pages } = state.pagesReducer;

  // postData('statements', pages[page]);

  yield put({ type: PAGES_SET_PAGE_FINISHED, data: { ...pages[page], finished: true }, page });
}

export default function* pagesWatcher() {
  yield takeLatest(PAGES_SAGA_SET_PAGE_DATA, setPageData);
  yield takeLatest(PAGES_SAGA_SET_PAGE_FINISHED, setPageFinished);
}

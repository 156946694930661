import { ProceedType } from './index';

export const conversionTables: Record<ProceedType, [[number, number], [number, number], [number, number]]> = {
  inclusiefLeiderschap: [
    // Voorbeeldje, zo werkt de conversie naar score 1 t/m 5:
    // 2.57 < === 1
    [2.57, 3.56], // 2
    [3.56, 3.86], // 3
    [3.86, 4.56], // 4
    // 4.56 > === 5
  ],
  cultureleVeerkracht: [
    [3.62, 3.92],
    [3.92, 4.15],
    [4.15, 4.69],
  ],
  Werktevredenheid: [
    [3, 3.8],
    [3.8, 4],
    [4, 4.8],
  ],
  [ProceedType.identificatieMetDeOrganisatie]: [
    [1.75, 2.5],
    [2.5, 3],
    [3, 3.5],
  ],
  [ProceedType.ervarenSfeerInDeWerkomgeving]: [
    [3, 3.75],
    [3.75, 4],
    [4, 4.75],
  ],
  [ProceedType.ervarenCarrierePerspectief]: [
    [1.33, 2.67],
    [2.67, 3],
    [3, 4],
  ],
  Authenticiteit: [
    [2.75, 3.75],
    [3.75, 4],
    [4, 4.75],
  ],
  [ProceedType.erkenning]: [
    [3.38, 3.88],
    [3.88, 4.13],
    [4.13, 4.88],
  ],
  Creativiteit: [
    [2.75, 3.5],
    [3.5, 3.75],
    [3.75, 4.25],
  ],
  [ProceedType.reflectieEnBesluitvorming]: [
    [2.33, 3],
    [3, 3.33],
    [3.33, 4],
  ],
};

export default conversionTables;

import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';
import './JustifiedScoreButton.scss';

interface JustifiedScoreButtonProps {
  isActive: boolean;
  index: number;
  onClick?: (score: number) => void;
}

const JustifiedScoreButton: FC<JustifiedScoreButtonProps> = ({
  isActive,
  index,
  onClick,
}): ReactElement => {
  const className = classnames('justified-score-button', {
    'justified-score-button--is-active': isActive,
    'justified-score-button--is-interactive': onClick,
  });

  const onButtonClick = () => {
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <button
      className={className}
      onClick={onButtonClick}
      type="button"
      aria-label={`score-${index}`}
    />
  );
};

export default JustifiedScoreButton;

import React, {
  FC, ReactElement, useState,
} from 'react';
import { RouteComponentProps } from 'react-router';
import useQuestion from '../../helpers/useQuestion';

import { HTMLContent, PageHeader } from '../../components';

import { BottomNavigation, QuizQuestion } from '../../containers';
import content from '../../content';
import { questions as questionJapi } from '../../fetches/questions';

type StartVoortgangProps = RouteComponentProps;


const StartVoortgang: FC<StartVoortgangProps> = (): ReactElement => {
  const [answer, setAnswer] = useState<string>();
  const question = useQuestion(1);

  const submitData = (): void => {
    if (question !== undefined && answer) {
      questionJapi.patch(question.id, {
        attributes: {
          answer,
        },
        relationships: {},
      });
    }
  };

  return (
    <main className="page">
      <PageHeader title="Voortgang" />
      <HTMLContent html={content.start.voortgang} />
      <br />
      {question !== undefined ? (
        <QuizQuestion
          type="textarea"
          name="start-voortgang"
          question="Feedback?"
          onChange={setAnswer}
        />
      ) : <span>Laden....</span>}
      <BottomNavigation
        isForm
        onSubmit={submitData}
      />
    </main>
  );
};


export default StartVoortgang;

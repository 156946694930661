import japi from './japi2';
import { SingleJsonApiResponseType } from './JsonApiResponse';

const endpoint = 'motivations';

export interface Motivation {
  attributes: {
    qualityPercentage: number;
    justicePercentage: number;
    accessPercentage: number;
    learningPercentage: number;
  };
  relationships: {
    user: {
      data: {
        type: 'users';
        id: string;
      };
    };
  };
}

export const motivations = japi(endpoint, 'motivations');

export const getOrCreateMotivationForUser = async (userId: string): Promise<SingleJsonApiResponseType<'motivations'>['data']> => {
  const fetchResponse = await motivations.fetch({
    filters: {
      'user.id': userId,
    },
  });

  if (fetchResponse.data.data.length === 0) {
    const postResponse = await motivations.post({
      attributes: {
        qualityPercentage: 0,
        justicePercentage: 0,
        accessPercentage: 0,
        learningPercentage: 0,
      },
      relationships: {
        user: {
          data: {
            type: 'users',
            id: userId.toString(),
          },
        },
      },
    });

    return postResponse.data.data;
  }

  return fetchResponse.data.data[fetchResponse.data.data.length - 1];
};

import serialize from 'form-serialize';
import queryString from 'query-string';
import { RefObject } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Dispatch } from 'redux';
import underscore from 'underscore';
import { authenticateUserWithResetToken, authenticateUserWithInviteToken } from '../redux/auth/authActions';
import { setPageData } from '../redux/pages/pagesActions';

export const convertStringToDash = (text: string): string => text.replace(/ +/g, '-').toLowerCase();

export const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getObjProperty = (containerObj: any, str: string, defaultValueArg?: string) => {
  const defaultValue = typeof defaultValueArg !== 'undefined' ? defaultValueArg : null;

  try {
    return underscore(str.split('.')).reduce((obj: any, key: string) => obj[key], containerObj);
  } catch (e) {
    return defaultValue;
  }
};

const getAllFormDataFromPage = (ref: RefObject<HTMLDivElement>): object | null => {
  if (!ref.current) {
    return null;
  }

  const forms = Array.from(ref.current.getElementsByTagName('form'));
  const data = forms.reduce((total, form: HTMLFormElement) => {
    if (form.classList.contains('pagination')) {
      return total;
    }

    const formData = serialize(form, { hash: true, empty: false, disabled: true });
    return {
      ...total,
      ...formData,
    };
  }, {});

  if (Object.keys(data).length === 0) {
    return null;
  }

  return data;
};

export const getAllFormDataAndDispatch = (ref: RefObject<HTMLDivElement>, dispatch: Dispatch, page: string): void => {
  const formData = getAllFormDataFromPage(ref);
  if (formData) {
    dispatch(setPageData(page, formData));
  }
};

export const getInviteHashFromUrlAndAuthenticate = (store: any): void => {
  const { dispatch } = store;
  const url = window.location.search;
  const params = queryString.parse(url);
  if (params.inviteHash && typeof params.inviteHash === 'string' && params.inviteHash) {
    dispatch(authenticateUserWithInviteToken(params.inviteHash));
  } else if (params.resetToken && typeof params.resetToken === 'string' && params.resetToken) {
    dispatch(authenticateUserWithResetToken(params.resetToken));
  }
};

export const setStrictCookie = (key: string, value: string, expirationDate?: Date): void => {
  document.cookie = `${key}=${value}; SameSite=strict; path=/;${expirationDate && ` expires=${expirationDate.toUTCString()}; ${process.env.NODE_ENV === 'production' && 'Secure;'}`}`;
};

export const getCookieValue = (key: string, cookieString: string = document.cookie): string => cookieString.replace(new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*=\\s*([^;]*).*$|^.*$)`), '$1');

export const removeCookie = (key: string): void => {
  document.cookie = `${key}=false; SameSite=strict; path=/; expires=${new Date().toUTCString()};`;
};

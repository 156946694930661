import { all } from 'redux-saga/effects';
import authenticationWatcher from './auth/authSaga';
import pagesWatcher from './pages/pagesSaga';
import questionWatcher from './questions/questionsSaga';
import userWatcher from './user/userSaga';

export default function* rootSaga() {
  yield all([
    authenticationWatcher(),
    pagesWatcher(),
    questionWatcher(),
    userWatcher(),
  ]);
}

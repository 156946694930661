import React, {FC, ReactElement, useEffect, useState} from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { setPageFinished } from '../../redux/pages/pagesActions';
import { Reducers } from '../../redux/reducers';

import { HTMLContent, PageHeader } from '../../components';
import { BottomNavigation, LikertScale, QuizQuestion } from '../../containers';
import content from '../../content';
import { questions as questionJapi } from '../../fetches/questions';
import useQuestion from '../../helpers/useQuestion';

const PAGE = 'succesfactoren-leerdoelen';

interface MapStateToPropsType {
  [PAGE]: any;
}

type SuccesfactorenLeerdoelenProps = RouteComponentProps & DispatchProp & MapStateToPropsType;


const SuccesfactorenLeerdoelen: FC<SuccesfactorenLeerdoelenProps> = ({ dispatch, [PAGE]: data }): ReactElement => {
  const [finishedPageState, setFinishedPageState] = useState(false);

  const [nextButtonLock, setNextButtonLock] = useState(true);
  const [givenAnswers, updateGivenAnswers] = useState<any[]>([null, null, null, null]);
  const [feedback, setFeedback] = useState('');

  const questions = [
    useQuestion(1),
    useQuestion(2),
    useQuestion(3),
    useQuestion(4),
  ];

  const feedbackQuestion = useQuestion(5);

  const handleLikertScaleChange = (question: string, range: number): void => {
    if (givenAnswers.indexOf(question) === -1) {
      const index = content.succesfactoren.leerdoelen.ratings.findIndex(((value: string) => value === question));
      givenAnswers[index] = range;

      updateGivenAnswers(givenAnswers);
    }

    setNextButtonLock(givenAnswers.filter(answer => answer).length < 4);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect((): void => {
    if (
      questions[0]
      && questions[1]
      && questions[2]
      && questions[3]
    ) {
      if (
        questions[0].attributes.answer
        && questions[1].attributes.answer
        && questions[2].attributes.answer
        && questions[3].attributes.answer
      ) {
        setFinishedPageState(true);
      }
    }
  });

  const finishedPage = data.finished || finishedPageState;

  const handleSubmit = (): void => {
    if (!finishedPage) {
      dispatch(setPageFinished(PAGE));
      givenAnswers.forEach(((value, index) => {
        const q = questions[index];

        if (q) {
          questionJapi.patch(q.id, {
            attributes: {
              answer: value,
            },
            relationships: {},
          });
        }
      }));
    }

    if (feedbackQuestion && feedback) {
      questionJapi.patch(feedbackQuestion.id, {
        attributes: {
          answer: feedback,
        },
        relationships: {},
      });
    }
  };

  return (
    <main className="page">
      <div className="page__content">
        <PageHeader title="Leerdoelen" />
        <p>{content.succesfactoren.leerdoelen.intro}</p>

        {(questions[0] && questions[1] && questions[2] && questions[3]) ? (
          <LikertScale
            name="succesfactoren-leerdoelen"
            labelLeast="Helemaal niet bereikt"
            labelMost="Volledig bereikt"
            questions={content.succesfactoren.leerdoelen.ratings}
            defaultValues={[
              questions[0] ? Number(questions[0].attributes.answer) : undefined,
              questions[1] ? Number(questions[1].attributes.answer) : undefined,
              questions[2] ? Number(questions[2].attributes.answer) : undefined,
              questions[3] ? Number(questions[3].attributes.answer) : undefined,
            ]}
            disabled={finishedPage}
            onChange={handleLikertScaleChange}
          />
        ) : <span>Laden...</span>}

        <br />
        <br />
        <HTMLContent html={content.succesfactoren.leerdoelen.feedbackText} />
        <br />
        {feedbackQuestion ? (
          <QuizQuestion
            type="textarea"
            name="drijfveren-leerdoelen"
            question="Feedback?"
            onChange={setFeedback}
          />
        ) : <span>Laden..</span>}
      </div>

      <BottomNavigation
        isForm
        unblockedPagination={finishedPage}
        lockNextButton={nextButtonLock}
        onSubmit={handleSubmit}
      />
    </main>
  );
};


const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  [PAGE]: state.pagesReducer.pages[PAGE],
});

export default connect(mapStateToProps)(SuccesfactorenLeerdoelen);

import React, { FC, ReactElement } from 'react';
import { ConnectedDrijfverenCasuistiek } from '../../connectors';


const Casuistiek: FC = (): ReactElement => (
  <main className="page">
    <ConnectedDrijfverenCasuistiek />
  </main>
);


export default Casuistiek;

import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { StartOverviewTool } from '../../containers';

type StartOverviewToolProps = RouteComponentProps;


const OverviewTool: FC<StartOverviewToolProps> = (): ReactElement => (
  <main className="page">
    <StartOverviewTool />
  </main>
);

export default OverviewTool;

import React, { FC, ReactElement } from 'react';

import './SoundCloudPlayer.scss';

interface SoundCloudPlayerProps {
  id: string;
  token: string;
  className?: string;
}


const SoundCloudPlayer: FC<SoundCloudPlayerProps> = ({ id, token }): ReactElement => {
  // eslint-disable-next-line max-len
  const src = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}%3Fsecret_token%3Ds-${token}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`;
  const title = `soundcloud-player-${id}`;

  return (
    <iframe
      className="sound-cloud-player"
      title={title}
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      src={src}
    />
  );
};

export default SoundCloudPlayer;

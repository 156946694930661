import { ProceedType } from '../data';
import conversionTables from '../data/conversionTables';

export default function transformNormScoreToJustifiedScore(proceedType: ProceedType, score: number): number {
  const tables = conversionTables[proceedType];

  if (score < tables[0][0]) {
    return 1;
  }

  if (score > tables[2][1]) {
    return 5;
  }

  return tables.findIndex(table => score >= table[0] && score <= table[1]) + 2;
}

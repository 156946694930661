import React, { FC, ReactElement } from 'react';

import { BottomNavigation, ModuleEstimation } from '..';
import { StartTitle } from '../../components';
import content from '../../content';
import moduleEstimates from '../../data/moduleEstimates';


const AanDeSlagStart: FC = (): ReactElement => (
  <>
    <StartTitle text="Module 4: Aan de Slag" />
    <ModuleEstimation description={content.aanDeSlag.start} minutes={moduleEstimates['aan-de-slag'] || 0} />
    <ModuleEstimation description="Opdracht" hours={1.5} />
    <BottomNavigation />
  </>
);

export default AanDeSlagStart;

import React, {
  FC,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Icon, Input, Logo } from '../../components';
import { setUserPassword } from '../../redux/auth/authActions';
import { Reducers } from '../../redux/reducers';

import './Login.scss';

interface MapStateToPropsType {
  loading: boolean;
  userName: string;
}

type LoginPasswordProps = DispatchProp & MapStateToPropsType & RouteComponentProps;

const LoginPassword: FC<LoginPasswordProps> = ({
  dispatch, userName, loading, history,
}): ReactElement => {
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [validationLength, setValidationLength] = useState(false);
  const [validationLowerCase, setValidationLowerCase] = useState(false);
  const [validationUpperCase, setValidationUpperCase] = useState(false);
  const [validationNumber, setValidationNumber] = useState(false);
  const [validationSpecial, setValidationSpecial] = useState(false);
  const [formError, setFormError] = useState('');

  const setPasswordError = (): void => setFormError('Er is iets misgegaan. Probeer het opnieuw.');

  useEffect(() => {
    setValidationLength(newPassword && newPassword.length >= 8);
    setValidationLowerCase(newPassword && new RegExp(/(?=.*[a-z])/).test(newPassword));
    setValidationUpperCase(newPassword && new RegExp(/(?=.*[A-Z])/).test(newPassword));
    setValidationNumber(newPassword && new RegExp(/(?=.*[0-9])/).test(newPassword));
    setValidationSpecial(newPassword && new RegExp(/\W|_/).test(newPassword));
  }, [newPassword]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    dispatch(setUserPassword(newPassword, history, setPasswordError));
  };

  return (
    <main className="login-page">
      <div className="login-page__container">
        <div className="login-page__info">
          <Logo className="login-page__info-logo" />
          <h1 className="login-page__info-title">Welkom in de MKAT Online tool</h1>
          <p className="login-page__info-text">
            Maak hier een wachtwoord aan om uw account compleet te maken. Zo kunt u altijd inloggen om uw resultaten te bekijken.
          </p>
          <p className="login-page__info-privacy-statement">
            De MKAT tool maakt gebruik van cookies.
            <br />
            <a href="/pdf/VU MKAT Online Tool - Privacy Statement 2020.pdf" target="_blank" rel="noopener noreferrer">Download ons privacy statement</a>
          </p>
        </div>
        <div className="login-page__card">
          <form onSubmit={handleSubmit} className="login-page__form">
            <fieldset className="login-page__form-fieldset">
              <legend className="login-page__form-legend">Wachtwoord instellen</legend>
              <div className="login-page__form-username">
                <h3 className="login-page__form-username-title">Gebruikersnaam</h3>
                <p className="login-page__form-username-value">{userName}</p>
              </div>
              <Input
                label="Vul hier een wachtwoord in"
                isPassword
                onChange={setNewPassword}
                className="login-page__form-input"
              />
              <Input
                label="Vul hier nogmaals het wachtwoord in"
                isPassword
                onChange={setConfirmPassword}
                className="login-page__form-input"
              />
            </fieldset>
            <div className="login-page__form-password-feedback">
              <em>Het wachtwoord dient te bestaan uit:</em>
              <ul className="login-page__form-password-feedback-list">
                <li className={`login-page__form-password-feedback-item ${validationLength ? 'login-page__form-password-feedback-item--is-valid' : ''}`}>
                  {validationLength ? (
                    <Icon name="check" className="login-page__form-password-feedback-check" />
                  ) : <span className="login-page__form-password-feedback-bullet">•</span>}
                  Minimaal 8 karakters
                </li>
                <li className={`login-page__form-password-feedback-item ${validationLowerCase ? 'login-page__form-password-feedback-item--is-valid' : ''}`}>
                  {validationLowerCase ? (
                    <Icon name="check" className="login-page__form-password-feedback-check" />
                  ) : <span className="login-page__form-password-feedback-bullet">•</span>}
                  Minimaal één kleine letter
                </li>
                <li className={`login-page__form-password-feedback-item ${validationUpperCase ? 'login-page__form-password-feedback-item--is-valid' : ''}`}>
                  {validationUpperCase ? (
                    <Icon name="check" className="login-page__form-password-feedback-check" />
                  ) : <span className="login-page__form-password-feedback-bullet">•</span>}
                  Minimaal één hoofdletter
                </li>
                <li className={`login-page__form-password-feedback-item ${validationNumber ? 'login-page__form-password-feedback-item--is-valid' : ''}`}>
                  {validationNumber ? (
                    <Icon name="check" className="login-page__form-password-feedback-check" />
                  ) : <span className="login-page__form-password-feedback-bullet">•</span>}
                  Minimaal één nummer
                </li>
                <li className={`login-page__form-password-feedback-item ${validationSpecial ? 'login-page__form-password-feedback-item--is-valid' : ''}`}>
                  {validationSpecial ? (
                    <Icon name="check" className="login-page__form-password-feedback-check" />
                  ) : <span className="login-page__form-password-feedback-bullet">•</span>}
                  Minimaal één speciaal karakter
                </li>
              </ul>
            </div>

            {formError && <p className="login-page__form-error">{formError}</p>}

            <input
              type="submit"
              value="Sla op en start de tool"
              disabled={!(
                validationLength
                && validationLowerCase
                && validationUpperCase
                && validationNumber
                && validationSpecial
                && (newPassword === confirmPassword)
                && !loading
              )}
              className="login-page__form-submit"
            />
          </form>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  loading: state.authReducer.loading,
  userName: state.authReducer.userName,
});

export default connect(mapStateToProps)(withRouter(LoginPassword));

import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { ConnectedDrijfverenMijnDrijfveer } from '../../connectors';

type DrijfverenStartProps = RouteComponentProps;


const MijnDrijfveer: FC<DrijfverenStartProps> = (): ReactElement => (
  <main className="page">
    <ConnectedDrijfverenMijnDrijfveer />
  </main>
);


export default MijnDrijfveer;

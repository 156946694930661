import content from '../../../content';

export default function getQuizQuestion3Answer(question: string): string {
  if (question === content.start.cultureleDiversiteit.questionThree.optionOne) {
    return content.start.cultureleDiversiteit.questionThree.answerOne;
  }

  if (question === content.start.cultureleDiversiteit.questionThree.optionTwo) {
    return content.start.cultureleDiversiteit.questionThree.answerTwo;
  }

  return content.start.cultureleDiversiteit.questionThree.answerThree;
}

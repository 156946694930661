import React, { FC, ReactElement } from 'react';

import './Table.scss';

interface TableProps {
  heading: string[];
  className?: string;
}


const Table: FC<TableProps> = ({ heading, className = '', children }): ReactElement => (
  <div className="table-container">
    <table className={`table ${className}`}>
      <thead>
        <tr>
          {heading.map((item): ReactElement => <th key={item}>{item}</th>)}
        </tr>
      </thead>
      {children}
    </table>
  </div>
);


export default Table;

import React, { FC, ReactElement } from 'react';

import { Icon } from '../../components';
import './Modal.scss';

interface ModalProps {
  toggleModal: () => void;
}


const Modal: FC<ModalProps> = ({ toggleModal, children }): ReactElement => (
  <div className="modal">
    <button onClick={toggleModal} className="modal__close-button">
      <Icon name="close" className="modal__close-icon" />
    </button>
    <button onClick={toggleModal} className="modal__backdrop-button">Sluit pop-up</button>
    <div className="modal__content">
      {children}
    </div>
  </div>
);


export default Modal;

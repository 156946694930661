import React, { FC, ReactElement } from 'react';

import { ModuleStatusCardsContainer } from '..';
import { HTMLContent, PageHeader, TotalProgressionText } from '../../components';
import content from '../../content';
import { ModuleProgression } from '../../helpers/getAllModuleProgressions';
import './Dashboard.scss';

interface DashboardProps {
  totalProgress: number;
  moduleProgressions: ModuleProgression[];
}

const Dashboard: FC<DashboardProps> = ({ totalProgress, moduleProgressions }): ReactElement => (
  <div className="dashboard">
    <PageHeader title="Dashboard" />
    <HTMLContent html={content.start.overviewTool.pageOne.partOne} />
    <TotalProgressionText className="dashboard__total-progression-text" progress={totalProgress} />

    <ModuleStatusCardsContainer
      className="dashboard__module-status-cards-container"
      progressions={moduleProgressions}
    />
  </div>
);

export default Dashboard;

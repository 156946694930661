import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { getAllFormDataAndDispatch } from '../../helpers/tools';

import { Reducers } from '../../redux/reducers';

import { setPageFinished } from '../../redux/pages/pagesActions';
import { setMotivations } from '../../redux/user/userActions';

import { HTMLContent, PageHeader } from '../../components';
import {
  BinaryChoice,
  BottomNavigation,
  PaginationPage,
  PaginationSwitcher,
  QuizQuestion,
} from '../../containers';

import content from '../../content';

import { motivations as motivationJapi } from '../../fetches/motivation';
import { questions as questionJapi } from '../../fetches/questions';
import useMotivation from '../../helpers/useMotivation';
import useQuestion from '../../helpers/useQuestion';

import { statements } from '../../fetches/statements';
import useStore from '../../helpers/useStore';

export type Category = 'kwaliteit'|'rechtvaardigheid'|'toegang'|'leren';
type Choices = { [key in Category]: string[] };

type Option = string;

interface TransformedChoice {
  category: Category;
  identifier: string;
  text: Option;
}

interface Answer { category: Category; selectedOptionIndex: number }
interface Answers { [key: string]: Answer }

const PAGE = 'drijfveren-zelfdiagnose';

interface MapStateToPropsType {
  [PAGE]: any;
}

type DrijfverenZelfdiagnoseProps = RouteComponentProps & DispatchProp & MapStateToPropsType;

const DrijfverenZelfdiagnose: FC<DrijfverenZelfdiagnoseProps> = ({ dispatch, [PAGE]: data }): ReactElement => {
  const [finishedPageState, setFinishedPageState] = useState(false);

  const [activePaginationPage, setActivePaginationPage] = useState<number>(1);
  const [allChoices, initializeChoices] = useState<Choices>();
  const [randomizedChoices, setRandomizedChoices] = useState<TransformedChoice[][][]>();
  const [givenAnswers, updateGivenAnswers] = useState<Answers>({});
  const userId = useStore('authReducer', 'userId');

  const [questionOneAnswer, setQuestionOneAnswer] = useState('');
  const [questionTwoAnswer, setQuestionTwoAnswer] = useState('');
  const questionOne = useQuestion(1);
  const questionTwo = useQuestion(2);
  const motivation = useMotivation();
  const hasMotivation = motivation && (
    motivation.attributes.accessPercentage
    + motivation.attributes.justicePercentage
    + motivation.attributes.learningPercentage
    + motivation.attributes.qualityPercentage
  ) > 0;

  const checkNextButtonLock = (): boolean => {
    if (activePaginationPage === 1) {
      return questionOneAnswer.length === 0;
    }
    if (activePaginationPage === 2) {
      return questionTwoAnswer.length === 0;
    }
    if (activePaginationPage === 3) {
      return Object.keys(givenAnswers).length < 4;
    }
    if (activePaginationPage === 4) {
      return Object.keys(givenAnswers).length < 8;
    }
    if (activePaginationPage === 5) {
      return Object.keys(givenAnswers).length < 12;
    }

    return false;
  };

  const nextButtonLock = checkNextButtonLock();

  // Pseudo random combinations of categories
  const categoryPattern: Category[][][] = [
    [['kwaliteit', 'rechtvaardigheid'], ['toegang', 'leren'], ['rechtvaardigheid', 'toegang'], ['rechtvaardigheid', 'kwaliteit']],
    [['leren', 'toegang'], ['rechtvaardigheid', 'kwaliteit'], ['toegang', 'leren'], ['kwaliteit', 'leren']],
    [['toegang', 'rechtvaardigheid'], ['leren', 'rechtvaardigheid'], ['kwaliteit', 'toegang'], ['kwaliteit', 'leren']],
  ];

  // Format choices of each category to an array
  const choices: Choices = {
    kwaliteit: Object.keys(content.drijfveren.zelfDiagnose.choiceSets.k).map(key => content.drijfveren.zelfDiagnose.choiceSets.k[key]),
    rechtvaardigheid: Object.keys(content.drijfveren.zelfDiagnose.choiceSets.r).map(key => content.drijfveren.zelfDiagnose.choiceSets.r[key]),
    toegang: Object.keys(content.drijfveren.zelfDiagnose.choiceSets.t).map(key => content.drijfveren.zelfDiagnose.choiceSets.t[key]),
    leren: Object.keys(content.drijfveren.zelfDiagnose.choiceSets.l).map(key => content.drijfveren.zelfDiagnose.choiceSets.l[key]),
  };

  const [prevPaginationPage, setBeforePaginationChange] = useState(1);
  const pageRef = React.createRef<HTMLDivElement>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect((): void => {
    if (
      questionOne
      && questionTwo
      && motivation
    ) {
      if (
        questionOne.attributes.answer
        && questionTwo.attributes.answer
        && hasMotivation
      ) {
        setFinishedPageState(true);
      }
    }
  });

  useEffect((): void => {
    getAllFormDataAndDispatch(pageRef, dispatch, PAGE);
  }, [prevPaginationPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // Save copy of formatted choices to page state
  useEffect((): void => initializeChoices({ ...choices }), []); // eslint-disable-line react-hooks/exhaustive-deps

  // Randomize options per choice based on category and remaining options
  useEffect((): void => {
    if (!allChoices) {
      return;
    }

    const constructedChoices = categoryPattern.map((page) => page.map((choice) => choice.map((category) => {
      const randomIndex = Math.floor(Math.random() * choices[category].length);
      const randomOption = choices[category][randomIndex];
      const absoluteIndex = allChoices[category].findIndex(option => option === randomOption);

      choices[category].splice(randomIndex, 1);

      return {
        category,
        identifier: `${category[0].toUpperCase()}${absoluteIndex + 1}`,
        text: randomOption,
      };
    })));

    setRandomizedChoices(constructedChoices);
  }, [allChoices]); // eslint-disable-line react-hooks/exhaustive-deps

  // Calculate percentage of categories behind selected options
  const calculatePercentages = (): { [key in Category]: number } => {
    const categoryPercentages: { [key in Category]: number } = motivation ? {
      kwaliteit: motivation.attributes.qualityPercentage,
      rechtvaardigheid: motivation.attributes.justicePercentage,
      toegang: motivation.attributes.accessPercentage,
      leren: motivation.attributes.learningPercentage,
    } : {
      kwaliteit: 0, rechtvaardigheid: 0, toegang: 0, leren: 0,
    };

    if (!givenAnswers) { return categoryPercentages; }

    const categoryValues = Object.keys(givenAnswers).map(answer => givenAnswers[answer].category);

    categoryValues.forEach((value: Category): void => {
      categoryPercentages[value] += (100 / categoryValues.length);
    });

    return categoryPercentages;
  };

  if (!randomizedChoices) {
    return <p>Aan het laden...</p>;
  }

  const finishedPage = data.finished || finishedPageState;

  const handleOnSubmit = (): void => {
    if (!finishedPage) {
      const motivations = calculatePercentages();
      dispatch(setMotivations(motivations));
      dispatch(setPageFinished(PAGE));

      if (motivation) {
        motivationJapi.patch(motivation.id, {
          attributes: {
            qualityPercentage: motivations.kwaliteit,
            justicePercentage: motivations.rechtvaardigheid,
            accessPercentage: motivations.toegang,
            learningPercentage: motivations.leren,
          },
          relationships: {},
        });
      }

      if (questionOne) {
        questionJapi.patch(questionOne.id, {
          attributes: {
            answer: questionOneAnswer,
          },
          relationships: {},
        });
      }

      if (questionTwo) {
        questionJapi.patch(questionTwo.id, {
          attributes: {
            answer: questionTwoAnswer,
          },
          relationships: {},
        });
      }

      const givenKeys = Object.keys(givenAnswers);
      const givenChoices = randomizedChoices.flat(1);

      for (let i = 0; i < givenKeys.length; i += 1) {
        const c = givenChoices[i];
        const answer = givenAnswers[`${c[0].identifier}/${c[1].identifier}`];
        statements.post({
          attributes: {
            selectedStatement: c[answer.selectedOptionIndex - 1].identifier,
            firstStatement: c[0].identifier,
            secondStatement: c[1].identifier,
          },
          relationships: {
            user: {
              data: {
                type: 'users',
                id: userId.toString(),
              },
            },
          },
        });
      }
    }
  };

  return (
    <main ref={pageRef} className="page">
      <PageHeader title="Zelfdiagnose" />
      <PaginationSwitcher activePage={activePaginationPage}>
        {[
          <PaginationPage key="static-1" id={1}>
            {questionOne !== undefined ? (
              <QuizQuestion
                data={data}
                type="textarea"
                questionNumber={1}
                name="drijfveren-zelfdiagnose-question-1"
                defaultValue={questionOne.attributes.answer}
                question={content.drijfveren.zelfDiagnose.questionOne}
                onChange={setQuestionOneAnswer}
                disabled={finishedPage}
              />
            ) : <span>Laden...</span>}
          </PaginationPage>,
          <PaginationPage key="static-2" id={2}>
            {questionTwo !== undefined ? (
              <QuizQuestion
                data={data}
                type="textarea"
                questionNumber={2}
                name="drijfveren-zelfdiagnose-question-2"
                defaultValue={questionTwo.attributes.answer}
                question={content.drijfveren.zelfDiagnose.questionTwo}
                onChange={setQuestionTwoAnswer}
                disabled={finishedPage}
              />
            ) : <span>Laden...</span>}
          </PaginationPage>,
          ...randomizedChoices.map((page, pageIndex: number): ReactElement => (
            <PaginationPage key={`${page}`} id={pageIndex + 4}>
              {!(finishedPage || hasMotivation) ? (
                <>
                  <HTMLContent html={content.drijfveren.zelfDiagnose.choiceDisclaimer} />

                  {page.map((choice, choiceIndex: number): ReactElement => {
                    const choiceIdentifier = `${choice[0].identifier}/${choice[1].identifier}`;
                    const choiceNumber = (pageIndex * 4) + (choiceIndex + 1);

                    const handleChoiceSelect = (value: Option): void => {
                      const selectedOptionIndex = choice.findIndex(option => option.text === value) + 1;
                      const selectedOption = choice.find(option => option.text === value);

                      if (selectedOption) {
                        const { category } = selectedOption;

                        updateGivenAnswers({
                          ...givenAnswers,
                          [choiceIdentifier]: { category, selectedOptionIndex },
                        });
                      }
                    };

                    return (
                      <BinaryChoice
                        key={choiceIdentifier}
                        data={data}
                        title={`Stelling ${choiceNumber}`}
                        name={choiceIdentifier}
                        firstOption={choice[0].text}
                        secondOption={choice[1].text}
                        onChange={handleChoiceSelect}
                        disabled={finishedPage || hasMotivation}
                      />
                    );
                  })}
                </>
              ) : (
                <HTMLContent html={content.drijfveren.zelfDiagnose.finishedStatements} />
              )}
            </PaginationPage>
          ))]}
      </PaginationSwitcher>
      <BottomNavigation
        paginationSize={5}
        isForm={!finishedPage}
        unblockedPagination={finishedPage}
        lockNextButton={nextButtonLock}
        onPaginationChange={setActivePaginationPage}
        onBeforePaginationChange={setBeforePaginationChange}
        onSubmit={handleOnSubmit}
      />
    </main>
  );
};

const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  [PAGE]: state.pagesReducer.pages[PAGE],
});

export default connect(mapStateToProps)(DrijfverenZelfdiagnose);

import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useToggle } from 'react-use';

import { Reducers } from '../../redux/reducers';
import { Workplace } from '../../redux/user/userTypes';

import { setStrictCookie } from '../../helpers/tools';
import useQuestion from '../../helpers/useQuestion';

import { Button, HTMLContent, PageHeader } from '../../components';
import {
  BarChart,
  BottomNavigation, Modal,
  PaginationPage,
  PaginationSwitcher,
} from '../../containers';

import content from '../../content';
import { aliases, averages, significanceWith } from '../../data';

interface MapStateToPropsType {
  successFactorLeaderShip: number;
  successFactorCulturalResilience: number;
  workplace: Workplace;
}

type SuccesfactorenUitlegEnScoreProps = RouteComponentProps & MapStateToPropsType;


const SuccesfactorenUitlegEnScore: FC<SuccesfactorenUitlegEnScoreProps> = ({
  successFactorLeaderShip,
  successFactorCulturalResilience,
  workplace,
}): ReactElement => {
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  const [modalActive, toggleModal] = useToggle(false);
  const [modalType, setModalType] = useState('');

  const [leadershipAverage, setLeadershipAverage] = useState(successFactorLeaderShip);
  const [culturalResilienceAverage, setCulturealResilienceAverage] = useState(successFactorCulturalResilience);

  const findAVG = (arr: number[]): number => Number((arr.reduce((a: number, b: number) => a + b, 0) / arr.length).toFixed(2));

  const leadershipValuesAPI = [
    useQuestion(1, '/succesfactoren/introductie'),
    useQuestion(2, '/succesfactoren/introductie'),
    useQuestion(3, '/succesfactoren/introductie'),
    useQuestion(4, '/succesfactoren/introductie'),
    useQuestion(5, '/succesfactoren/introductie'),
    useQuestion(6, '/succesfactoren/introductie'),
    useQuestion(7, '/succesfactoren/introductie'),
  ];

  useEffect((): void => {
    if (
      leadershipValuesAPI[0]
      && leadershipValuesAPI[1]
      && leadershipValuesAPI[2]
      && leadershipValuesAPI[3]
      && leadershipValuesAPI[4]
      && leadershipValuesAPI[5]
      && leadershipValuesAPI[6]
    ) {
      if (
        leadershipValuesAPI[0].attributes.answer
        && leadershipValuesAPI[1].attributes.answer
        && leadershipValuesAPI[2].attributes.answer
        && leadershipValuesAPI[3].attributes.answer
        && leadershipValuesAPI[4].attributes.answer
        && leadershipValuesAPI[5].attributes.answer
        && leadershipValuesAPI[6].attributes.answer
      ) {
        setLeadershipAverage(findAVG([...leadershipValuesAPI].map(item => (item ? Number(item.attributes.answer) : 0))));
      }
    }
  }, [leadershipValuesAPI]);

  const culturalResilienceValuesAPI = [
    useQuestion(8, '/succesfactoren/introductie'),
    useQuestion(9, '/succesfactoren/introductie'),
    useQuestion(10, '/succesfactoren/introductie'),
    useQuestion(11, '/succesfactoren/introductie'),
    useQuestion(12, '/succesfactoren/introductie'),
    useQuestion(13, '/succesfactoren/introductie'),
    useQuestion(14, '/succesfactoren/introductie'),
    useQuestion(15, '/succesfactoren/introductie'),
    useQuestion(16, '/succesfactoren/introductie'),
    useQuestion(17, '/succesfactoren/introductie'),
    useQuestion(18, '/succesfactoren/introductie'),
  ];

  useEffect((): void => {
    if (
      culturalResilienceValuesAPI[0]
      && culturalResilienceValuesAPI[1]
      && culturalResilienceValuesAPI[2]
      && culturalResilienceValuesAPI[3]
      && culturalResilienceValuesAPI[4]
      && culturalResilienceValuesAPI[5]
      && culturalResilienceValuesAPI[6]
      && culturalResilienceValuesAPI[7]
      && culturalResilienceValuesAPI[8]
      && culturalResilienceValuesAPI[9]
      && culturalResilienceValuesAPI[10]
    ) {
      if (
        culturalResilienceValuesAPI[0].attributes.answer
        && culturalResilienceValuesAPI[1].attributes.answer
        && culturalResilienceValuesAPI[2].attributes.answer
        && culturalResilienceValuesAPI[3].attributes.answer
        && culturalResilienceValuesAPI[4].attributes.answer
        && culturalResilienceValuesAPI[5].attributes.answer
        && culturalResilienceValuesAPI[6].attributes.answer
        && culturalResilienceValuesAPI[7].attributes.answer
        && culturalResilienceValuesAPI[8].attributes.answer
        && culturalResilienceValuesAPI[9].attributes.answer
        && culturalResilienceValuesAPI[10].attributes.answer
      ) {
        setCulturealResilienceAverage(findAVG([...culturalResilienceValuesAPI].map(item => (item ? Number(item.attributes.answer) : 0))));
      }
    }
  }, [culturalResilienceValuesAPI]);

  useEffect((): void => {
    if (leadershipAverage) {
      setStrictCookie('userSuccessFactorLeaderShip', String(leadershipAverage));
    }
  }, [leadershipAverage]);

  useEffect((): void => {
    if (culturalResilienceAverage) {
      setStrictCookie('userSuccessFactorCulturalResilience', String(culturalResilienceAverage));
    }
  }, [culturalResilienceAverage]);

  const leadershipData: any = (leadershipAverage && workplace.organization && averages.inclusiefLeiderschap[workplace.organization] && significanceWith.inclusiefLeiderschap[workplace.organization]) && {
    averages: {
      'Mijn score': leadershipAverage,
      ...(workplace.workunit && workplace.subOrganization && averages.inclusiefLeiderschap[workplace.organization][workplace.subOrganization] && {
        [aliases(workplace.workunit)]: averages.inclusiefLeiderschap[workplace.organization][workplace.subOrganization][workplace.workunit],
      }),
      ...(workplace.subOrganization && averages.inclusiefLeiderschap[workplace.organization][workplace.subOrganization] && {
        [aliases(workplace.subOrganization)]: averages.inclusiefLeiderschap[workplace.organization][workplace.subOrganization].total,
      }),
      [aliases(workplace.organization)]: averages.inclusiefLeiderschap[workplace.organization].total,
      benchmark: averages.inclusiefLeiderschap.total,
    },
    significance: {
      ...(workplace.workunit && workplace.subOrganization && significanceWith.inclusiefLeiderschap[workplace.organization][workplace.subOrganization] && {
        [workplace.workunit]: significanceWith.inclusiefLeiderschap[workplace.organization][workplace.subOrganization][workplace.workunit],
      }),
      ...(workplace.subOrganization && significanceWith.inclusiefLeiderschap[workplace.organization][workplace.subOrganization] && {
        [workplace.subOrganization]: significanceWith.inclusiefLeiderschap[workplace.organization][workplace.subOrganization].self,
      }),
      [workplace.organization]: significanceWith.inclusiefLeiderschap[workplace.organization].self,
    },
  };

  const culturalResilienceData: any = (culturalResilienceAverage && workplace.organization && averages.cultureleVeerkracht[workplace.organization] && significanceWith.cultureleVeerkracht[workplace.organization]) && {
    averages: {
      'Mijn score': culturalResilienceAverage,
      ...(workplace.workunit && workplace.subOrganization && averages.cultureleVeerkracht[workplace.organization][workplace.subOrganization] && {
        [aliases(workplace.workunit)]: averages.cultureleVeerkracht[workplace.organization][workplace.subOrganization][workplace.workunit],
      }),
      ...(workplace.subOrganization && averages.cultureleVeerkracht[workplace.organization][workplace.subOrganization] && {
        [aliases(workplace.subOrganization)]: averages.cultureleVeerkracht[workplace.organization][workplace.subOrganization].total,
      }),
      [aliases(workplace.organization)]: averages.cultureleVeerkracht[workplace.organization].total,
      benchmark: averages.cultureleVeerkracht.total,
    },
    significance: {
      ...(workplace.workunit && workplace.subOrganization && significanceWith.cultureleVeerkracht[workplace.organization][workplace.subOrganization] && {
        [workplace.workunit]: significanceWith.cultureleVeerkracht[workplace.organization][workplace.subOrganization][workplace.workunit],
      }),
      ...(workplace.subOrganization && significanceWith.cultureleVeerkracht[workplace.organization][workplace.subOrganization] && {
        [workplace.subOrganization]: significanceWith.cultureleVeerkracht[workplace.organization][workplace.subOrganization].self,
      }),
      [workplace.organization]: significanceWith.cultureleVeerkracht[workplace.organization].self,
    },
  };

  const handleSignificanceExplanationClick = (): void => {
    setModalType('significanceExplanation');
    toggleModal(true);
  };

  // @ts-ignore
  return (
    <main className="page">
      <PaginationSwitcher activePage={activePaginationPage}>
        <PaginationPage id={1}>
          <PageHeader title="Uitleg en score - Achtergrond" />
          <HTMLContent html={content.succesfactoren.uitlegEnScore.pageOneFull} />
        </PaginationPage>
        <PaginationPage id={2}>
          <PageHeader title="Uitleg succesfactoren" />
          <HTMLContent html={content.succesfactoren.uitlegEnScore.pageTwoFull} />
        </PaginationPage>
        <PaginationPage id={3}>
          <PageHeader title="Score inclusief leiderschap" />
          <h3>Hoe scoren ikzelf, mijn werkeenheid en organisatie op inclusief leiderschap?</h3>
          {leadershipData ? (
            <>
              <BarChart data={leadershipData.averages} isScore />

              <br />
              <h2>Betekenisvolle verschillen</h2>
              {Object.keys(leadershipData.significance).filter((environment) => leadershipData.significance[environment]).length ? (
                <>
                  <ul>
                    {Object.keys(leadershipData.significance).map((environment) => (
                      leadershipData.significance[environment] && (
                        <li key={environment}>
                          <div className="info">
                            <h4>{aliases(environment)}</h4>
                            <ul>
                              {leadershipData.significance[environment].map((item: string) => (
                                <li key={item}>{`met ${aliases(item)}`}</li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      )
                    ))}
                  </ul>
                  <br />
                  <br />
                  <Button text="Uitleg betekenisvolle verschillen" onClick={handleSignificanceExplanationClick} />
                </>
              ) : <p>Er zijn geen betekenisvolle verschillen tussen de mate van inclusief leiderschap gevonden.</p>}
            </>
          ) : <p>Geen data gevonden voor uw organisatie.</p>}
        </PaginationPage>
        <PaginationPage id={4}>
          <PageHeader title="Uitleg culturele veerkracht" />
          <HTMLContent html={content.succesfactoren.uitlegEnScore.pageFourFull} />
          <HTMLContent html={content.succesfactoren.uitlegEnScore.significanceExplanation} />
        </PaginationPage>
        <PaginationPage id={5}>
          <PageHeader title="Score culturele veerkracht" />
          <h3>Hoe scoren ikzelf, mijn werkeenheid en organisatie op culturele veerkracht?</h3>
          {culturalResilienceData ? (
            <>
              <BarChart data={culturalResilienceData.averages} isScore />

              <br />
              <h2>Betekenisvolle verschillen</h2>
              {Object.keys(culturalResilienceData.significance).filter((environment) => culturalResilienceData.significance[environment]).length ? (
                <>
                  <ul>
                    {Object.keys(culturalResilienceData.significance).map((environment) => (
                      culturalResilienceData.significance[environment] && (
                        <li key={environment}>
                          <div className="info">
                            <h4>{aliases(environment)}</h4>
                            <ul>
                              {culturalResilienceData.significance[environment].map((item: string) => (
                                <li key={item}>{`met ${aliases(item)}`}</li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      )
                    ))}
                  </ul>
                  <br />
                  <br />
                  <Button text="Uitleg betekenisvolle verschillen" onClick={handleSignificanceExplanationClick} />
                </>
              ) : <p>Er zijn geen betekenisvolle verschillen tussen de mate van culterele veerkracht gevonden.</p>}
            </>
          ) : <p>Geen data gevonden voor uw organisatie.</p>}
        </PaginationPage>
      </PaginationSwitcher>

      {modalActive && (
        <Modal toggleModal={toggleModal}>
          {modalType === 'significanceExplanation' && (
            <HTMLContent html={content.succesfactoren.uitlegEnScore.significanceExplanation} />
          )}
        </Modal>
      )}

      <BottomNavigation paginationSize={5} onPaginationChange={setActivePaginationPage} />
    </main>
  );
};


const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  successFactorLeaderShip: state.userReducer.successFactorLeaderShip,
  successFactorCulturalResilience: state.userReducer.successFactorCulturalResilience,
  workplace: state.userReducer.workplace,
});

export default connect(mapStateToProps)(SuccesfactorenUitlegEnScore);

import React, { FC, ReactElement } from 'react';
import { AanDeSlagBibliotheek } from '../../containers';


const BestPractices: FC = (): ReactElement => (
  <main className="page">
    <AanDeSlagBibliotheek />
  </main>
);

export default BestPractices;

import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { connect, DispatchProp } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router';
import useStore from './helpers/useStore';

import { Reducers } from './redux/reducers';
import { setFurthestLocation, setWorkplace } from './redux/user/userActions';

import Login from './pages/Login/Login';
import LoginPassword from './pages/Login/LoginPassword';
import Routes from './Routes';

import { TopBar } from './components';
import { SideNavigation } from './containers';

import { users } from './fetches/users';

interface MapStateToPropsType {
  isAuthenticated: boolean;
  userId: string;
  userName: string;
}

type AppProps = RouteComponentProps & DispatchProp & MapStateToPropsType;


// note to self: met eventueel vervolg op pilot opnieuw beginnen
const App: FC<AppProps> = ({
  dispatch,
  location,
  isAuthenticated,
  userId,
  userName,
}): ReactElement => {
  const [isLogin, setPageIsLogin] = useState(false);
  const [isChangePassword, setPageIsChangePassword] = useState(false);
  const [hasSideNavigation, setPageHasSideNavigation] = useState(false);
  const systemIsPatching = useStore('questionReducer', 'patching');
  const systemPatchingQueue = useStore('questionReducer', 'patchingQueue');

  useEffect((): void => {
    if (userId) {
      users.fetch(String(userId), { includes: ['organization', 'subOrganization', 'workUnit'] }).then((response) => {
        if (response && response.data) {
          const userData = response.data.data;

          // @ts-ignore
          const storedLastVisitedLocation = userData.attributes.lastVisitedPage;

          if (storedLastVisitedLocation) {
            dispatch(setFurthestLocation({
              module: storedLastVisitedLocation.split('/')[0],
              page: storedLastVisitedLocation.split('/')[1],
            }));
          }

          if (response.data.included) {
            const includedData = response.data.included;

            const userOrganization = includedData.filter(level => level.type === 'organisations')[0]
              ? includedData.filter(level => level.type === 'organisations')[0].attributes.name
              : null;
            const userSubOrganization = includedData.filter(level => level.type === 'sub_organizations')[0]
              ? includedData.filter(level => level.type === 'sub_organizations')[0].attributes.name
              : null;
            const userWorkUnit = includedData.filter(level => level.type === 'work_units')[0]
              ? includedData.filter(level => level.type === 'work_units')[0].attributes.name
              : null;

            dispatch(setWorkplace({
              organization: userOrganization,
              subOrganization: userSubOrganization,
              workunit: userWorkUnit,
            }));
          }
        }
      });
    }
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    const pathname = location.pathname.split('/').filter(item => item.length)[0];
    setPageIsLogin(pathname === 'login');
    setPageIsChangePassword(pathname === 'wachtwoord-instellen');
    setPageHasSideNavigation(pathname !== 'dashboard');

    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [location]);

  if (isChangePassword) {
    return <LoginPassword />;
  }

  if (isLogin || !isAuthenticated) {
    return <Login />;
  }

  return (
    <>
      <TopBar userName={userName} />
      <div className="content-container">
        { hasSideNavigation && <SideNavigation /> }
        <section className={`page-container ${hasSideNavigation ? 'page-container--has-side-navigation' : ''}`}>
          {systemIsPatching && (
            <div className="system-patching">
              <span>Antwoorden opslaan...</span>
              <br />
              <span>Nog </span>
              <span>{systemPatchingQueue}</span>
              <span> te gaan</span>
              <br />
              <span>Een ogenblik geduld aub</span>
            </div>
          )}
          <Switch>
            {Routes.map((route): ReactElement => <Route {...route} key={route.key} />)}
          </Switch>
        </section>
      </div>
    </>
  );
};


const mapStateToProps = (state: Reducers): MapStateToPropsType => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  userName: state.authReducer.userName,
  userId: state.authReducer.userId,
});

export default connect(mapStateToProps)(withRouter(App));

import React, { FC, ReactElement } from 'react';

import { AanDeSlagIntroductie } from '../../containers';


const Introductie: FC = (): ReactElement => (
  <main className="page">
    <AanDeSlagIntroductie />
  </main>
);

export default Introductie;

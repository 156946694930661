import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { Button, HTMLContent, StartTitle } from '../../components';
import content from '../../content';

import './DrijfverenOpdracht.scss';


const DrijfverenOpdracht: FC = (): ReactElement => {

  const onButtonDownloadClick = () => {
    const a = document.createElement('a');
    a.href = '/docx/opdracht-module1.docx';
    a.download = '';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <>
      <StartTitle text="Opdracht" />
      <HTMLContent html={content.drijfveren.opdracht.intro} />
      <Button onClick={onButtonDownloadClick} className="drijfveren-opdracht__download-button" text="Download formulier" />

      <img className="drijfveren-opdracht__opdracht-preview" src="/opdracht-previews/opdracht-module1.png" alt="opdracht 1 preview" />

      <BottomNavigation />
    </>
  );
};

export default DrijfverenOpdracht;

import React, { FC, ReactElement } from 'react';
import { ConnectedOpbrengstenDiagnose } from '../../connectors';

const Diagnose: FC = (): ReactElement => (
  <main className="page">
    <ConnectedOpbrengstenDiagnose />
  </main>
);

export default Diagnose;

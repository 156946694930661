import React, { FC, ReactElement } from 'react';
import { ConnectedDashboard } from '../../connectors';

const Dashboard: FC = (): ReactElement => {

  return (
    <main className="page">
      <ConnectedDashboard />
    </main>
  );
};

export default Dashboard;

import React, { FC, ReactElement } from 'react';

import './StartTitle.scss';

interface StartTitleProps {
  text: string;
}


const StartTitle: FC<StartTitleProps> = ({ text }): ReactElement => <h1 className="start-title">{text}</h1>;


export default StartTitle;

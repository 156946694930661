import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { HTMLContent, StartTitle } from '../../components';
import content from '../../content';


const AanDeSlagIntroductie: FC = (): ReactElement => (
  <>
    <StartTitle text="Introductie" />
    <HTMLContent html={content.aanDeSlag.introductie} />
    <BottomNavigation />
  </>
);

export default AanDeSlagIntroductie;

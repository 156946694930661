import React, { FC, ReactElement } from 'react';
import { connect, DispatchProp } from 'react-redux';
import useRouter from 'use-react-router';
import { Icon, Logo } from '..';
import { logoutUser } from '../../redux/auth/authActions';
import './TopBar.scss';

interface TopBarProps extends DispatchProp {
  userName: string | null;
}

const TopBar: FC<TopBarProps> = ({ userName, dispatch }): ReactElement => {
  const { history } = useRouter();

  const handleLogout = (): void => {
    dispatch(logoutUser());
    history.push('/');
  };

  return (
    <header className="top-bar">
      <Logo className="top-bar__logo" />
      <span className="top-bar__app-title">MKAT - Online tool</span>
      {userName && (
        <div className="top-bar__username-container">
          <span className="top-bar__username">{userName}</span>
          <button title="Uitloggen" onClick={handleLogout} className="top-bar__logout-button">
            <Icon name="logout" className="top-bar__logout-icon" />
          </button>
        </div>
      )}
    </header>
  );
};

export default connect()(TopBar);

import React, { FC, ReactElement } from 'react';

import './PageHeader.scss';

interface PageHeaderProps {
  title: string;
}


const PageHeader: FC<PageHeaderProps> = ({ title }): ReactElement => (
  <header className="page-header">
    <h1 className="page-header__title">{title}</h1>
  </header>
);


export default PageHeader;

import React, { FC, ReactElement } from 'react';

import { Icon } from '..';
import './DurationEstimation.scss';

interface DurationEstimationProps {
  hours?: number;
  minutes?: number;
  className?: string;
}


const DurationEstimation: FC<DurationEstimationProps> = ({ minutes, hours, className = '' }): ReactElement => (
  <div className={`duration-estimation ${className}`}>
    { hours && <span className="duration-estimation__label">{`+/- ${hours} ${hours >= 2 ? 'uren' : 'uur'}`}</span> }
    { (minutes && !hours) && <span className="duration-estimation__label">{`+/- ${minutes} minuten`}</span> }
    <Icon name="clock" className="duration-estimation__icon" />
  </div>
);


export default DurationEstimation;

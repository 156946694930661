import { useEffect, useState } from 'react';
import { getOrCreateCaseStudyForUser } from '../fetches/caseStudies';
import { SingleJsonApiResponseType } from '../fetches/JsonApiResponse';
import useStore from './useStore';

export default (num: number): SingleJsonApiResponseType<'case_studies'>['data']|undefined => {
  const userId = useStore('authReducer', 'userId');
  const [motivation, setCaseStudy] = useState<SingleJsonApiResponseType<'case_studies'>['data']>();

  useEffect(() => {
    if (!userId || !num) {
      return;
    }

    getOrCreateCaseStudyForUser(userId, num).then((response) => {
      setCaseStudy(response);
    });
  }, [userId, num]);

  return motivation;
};

import * as Router from 'react-router';
import {
  AanDeSlagActieplan,
  AanDeSlagAfronding,
  AanDeSlagBibliotheek,
  AanDeSlagIntroductie,
  AanDeSlagStart,
  AanDeSlagVoorbereiding,
  BibliotheekAlgemeen,
  BibliotheekDrijfveren,
  BibliotheekOpbrengsten,
  BibliotheekStart,
  BibliotheekSuccesfactoren,
  Dashboard,
  DrijfverenBibliotheek,
  DrijfverenCasuistiek,
  DrijfverenIntroductie,
  DrijfverenLeerdoelen,
  DrijfverenMijnDrijfveer,
  DrijfverenMijnOrganisatie,
  DrijfverenOpdracht,
  DrijfverenStart,
  DrijfverenZelfdiagnose,
  LoginPassword,
  OpbrengstenBibliotheek,
  OpbrengstenDiagnose,
  OpbrengstenIntroductie,
  OpbrengstenLeerdoelen,
  OpbrengstenOpbrengsten,
  OpbrengstenOpdracht,
  OpbrengstenStart,
  OpbrengstenVerdieping,
  StartBibliotheek,
  StartCultureleDiversiteit,
  StartMijnOrganisatie,
  StartOverviewTool,
  StartVoortgang,
  SuccesfactorenBibliotheek,
  SuccesfactorenIntroductie,
  SuccesfactorenLeerdoelen,
  SuccesfactorenOpdracht,
  SuccesfactorenStart,
  SuccesfactorenToelichting,
  SuccesfactorenUitlegEnScore,
} from './pages';

interface RouteProps extends Router.RouteProps{
  key: string;
}

interface NavRoutePage {
  title: string;
  slug: string;
}

export interface NavRouteModule {
  title: string;
  slug: string;
  pages: NavRoutePage[];
}

export enum ModuleSlugType {
  dashboard = 'dashboard',
  start = 'start',
  drijfveren = 'drijfveren',
  succesfactoren = 'succesfactoren',
  opbrengsten = 'opbrengsten',
  'aan-de-slag' = 'aan-de-slag',
  bibliotheek = 'bibliotheek',
}

export const initialPage = ModuleSlugType.dashboard;

export const dynamicRouteModules: NavRouteModule[] = [
  {
    title: '0. Start',
    slug: ModuleSlugType.start,
    pages: [
      {
        title: 'Overview tool',
        slug: 'overview-tool',
      },
      {
        title: 'Culturele diversiteit',
        slug: 'culturele-diversiteit',
      },
      {
        title: 'Mijn organisatie',
        slug: 'mijn-organisatie',
      },
      {
        title: 'Voortgang',
        slug: 'voortgang',
      },
      {
        title: 'Bibliotheek',
        slug: 'bibliotheek',
      },
    ],
  },
  {
    title: '1. Drijfveren',
    slug: ModuleSlugType.drijfveren,
    pages: [
      {
        title: 'Start',
        slug: 'start',
      },
      {
        title: 'Introductie',
        slug: 'introductie',
      },
      {
        title: 'Zelfdiagnose',
        slug: 'zelfdiagnose',
      },
      {
        title: 'Mijn drijfveer',
        slug: 'mijn-drijfveer',
      },
      {
        title: 'Mijn organisatie',
        slug: 'mijn-organisatie',
      },
      {
        title: 'Casuïstiek',
        slug: 'casuistiek',
      },
      {
        title: 'Opdracht',
        slug: 'opdracht',
      },
      {
        title: 'Leerdoelen',
        slug: 'leerdoelen',
      },
      {
        title: 'Bibliotheek',
        slug: 'bibliotheek',
      },
    ],
  },
  {
    title: '2. Succesfactoren',
    slug: ModuleSlugType.succesfactoren,
    pages: [
      {
        title: 'Start',
        slug: 'start',
      },
      {
        title: 'Introductie',
        slug: 'introductie',
      },
      {
        title: 'Toelichting',
        slug: 'toelichting',
      },
      {
        title: 'Opdracht',
        slug: 'opdracht',
      },
      {
        title: 'Leerdoelen',
        slug: 'leerdoelen',
      },
      {
        title: 'Bibliotheek',
        slug: 'bibliotheek',
      },
    ],
  },
  {
    title: '3. Opbrengsten',
    slug: ModuleSlugType.opbrengsten,
    pages: [
      {
        title: 'Start',
        slug: 'start',
      },
      {
        title: 'Introductie',
        slug: 'introductie',
      },
      {
        title: 'Opbrengsten',
        slug: 'opbrengsten',
      },
      {
        title: 'Verdieping',
        slug: 'verdieping',
      },
      {
        title: 'Diagnose',
        slug: 'diagnose',
      },
      {
        title: 'Opdracht',
        slug: 'opdracht',
      },
      {
        title: 'Leerdoelen',
        slug: 'leerdoelen',
      },
      {
        title: 'Bibliotheek',
        slug: 'bibliotheek',
      },
    ],
  },
  {
    title: '4. Aan de slag',
    slug: ModuleSlugType['aan-de-slag'],
    pages: [
      {
        title: 'Start',
        slug: 'start',
      },
      {
        title: 'Introductie',
        slug: 'introductie',
      },
      {
        title: 'Voorbereiding',
        slug: 'voorbereiding',
      },
      {
        title: 'Actieplan',
        slug: 'actieplan',
      },
      {
        title: 'Best practices',
        slug: 'best-practices',
      },
      {
        title: 'Afronding',
        slug: 'afronding',
      },
    ],
  },
];

export const staticRouteModules = [

  {
    title: 'Bibliotheek',
    slug: 'bibliotheek',
    pages: [
      {
        title: 'Algemeen',
        slug: 'algemeen',
      },
      {
        title: 'Start',
        slug: 'start',
      },
      {
        title: 'Drijfveren',
        slug: 'drijfveren',
      },
      {
        title: 'Succesfactoren',
        slug: 'succesfactoren',
      },
      {
        title: 'Opbrengsten',
        slug: 'opbrengsten',
      },
    ],
  },
];

export const getRouteModuleIndex = (slug: string): number => dynamicRouteModules
  .findIndex(module => module.slug === slug);

export const getRoutePageIndex = (module: number, pageSlug: string): number => dynamicRouteModules[module] && dynamicRouteModules[module].pages
  .findIndex(page => page.slug === pageSlug);

const Routes: RouteProps[] = [
  {
    component: StartOverviewTool,
    exact: true,
    key: 'home',
    path: '/',
  },
  {
    component: LoginPassword,
    exact: true,
    key: 'wachtwoord-instellen',
    path: '/wachtwoord-instellen',
  },
  {
    component: AanDeSlagActieplan,
    exact: true,
    key: 'aan-de-slag-actieplan',
    path: '/aan-de-slag/actieplan',
  },
  {
    component: AanDeSlagAfronding,
    exact: true,
    key: 'aan-de-slag-afronding',
    path: '/aan-de-slag/afronding',
  },
  {
    component: AanDeSlagBibliotheek,
    exact: true,
    key: 'aan-de-slag-best-practices',
    path: '/aan-de-slag/best-practices',
  },
  {
    component: AanDeSlagIntroductie,
    exact: true,
    key: 'aan-de-slag-introductie',
    path: '/aan-de-slag/introductie',
  },
  {
    component: AanDeSlagVoorbereiding,
    exact: true,
    key: 'aan-de-slag-voorbereiding',
    path: '/aan-de-slag/voorbereiding',
  },
  {
    component: AanDeSlagStart,
    exact: true,
    key: 'aan-de-slag-start',
    path: '/aan-de-slag/start',
  },
  {
    component: BibliotheekAlgemeen,
    exact: true,
    key: 'bibliotheek-algemeen',
    path: '/bibliotheek/algemeen',
  },
  {
    component: BibliotheekDrijfveren,
    exact: true,
    key: 'bibliotheek-drijfveren',
    path: '/bibliotheek/drijfveren',
  },
  {
    component: BibliotheekOpbrengsten,
    exact: true,
    key: 'bibliotheek-opbrengsten',
    path: '/bibliotheek/opbrengsten',
  },
  {
    component: BibliotheekStart,
    exact: true,
    key: 'bibliotheek-start',
    path: '/bibliotheek/start',
  },
  {
    component: BibliotheekSuccesfactoren,
    exact: true,
    key: 'bibliotheek-succesfactoren',
    path: '/bibliotheek/succesfactoren',
  },
  {
    component: Dashboard,
    exact: true,
    key: 'dashboard',
    path: '/dashboard',
  },
  {
    component: DrijfverenBibliotheek,
    exact: true,
    key: 'drijfveren-bibliotheek',
    path: '/drijfveren/bibliotheek',
  },
  {
    component: DrijfverenCasuistiek,
    exact: true,
    key: 'drijfveren-casuistiek',
    path: '/drijfveren/casuistiek',
  },
  {
    component: DrijfverenIntroductie,
    exact: true,
    key: 'drijfveren-introductie',
    path: '/drijfveren/introductie',
  },
  {
    component: DrijfverenLeerdoelen,
    exact: true,
    key: 'drijfveren-leerdoelen',
    path: '/drijfveren/leerdoelen',
  },
  {
    component: DrijfverenMijnDrijfveer,
    exact: true,
    key: 'drijfveren-mijn-drijfveer',
    path: '/drijfveren/mijn-drijfveer',
  },
  {
    component: DrijfverenMijnOrganisatie,
    exact: true,
    key: 'drijfveren-mijn-organisatie',
    path: '/drijfveren/mijn-organisatie',
  },
  {
    component: DrijfverenOpdracht,
    exact: true,
    key: 'drijfveren-opdracht',
    path: '/drijfveren/opdracht',
  },
  {
    component: DrijfverenStart,
    exact: true,
    key: 'drijfveren-start',
    path: '/drijfveren/start',
  },
  {
    component: DrijfverenZelfdiagnose,
    exact: true,
    key: 'drijfveren-zelfdiagnose',
    path: '/drijfveren/zelfdiagnose',
  },
  {
    component: OpbrengstenBibliotheek,
    exact: true,
    key: 'opbrengsten-bibliotheek',
    path: '/opbrengsten/bibliotheek',
  },
  {
    component: OpbrengstenIntroductie,
    exact: true,
    key: 'opbrengsten-introductie',
    path: '/opbrengsten/introductie',
  },
  {
    component: OpbrengstenLeerdoelen,
    exact: true,
    key: 'opbrengsten-leerdoelen',
    path: '/opbrengsten/leerdoelen',
  },
  {
    component: OpbrengstenDiagnose,
    exact: true,
    key: 'opbrengsten-diagnose',
    path: '/opbrengsten/diagnose',
  },
  {
    component: OpbrengstenOpbrengsten,
    exact: true,
    key: 'opbrengsten-opbrengsten',
    path: '/opbrengsten/opbrengsten',
  },
  {
    component: OpbrengstenStart,
    exact: true,
    key: 'opbrengsten-start',
    path: '/opbrengsten/start',
  },
  {
    component: OpbrengstenOpdracht,
    exact: true,
    key: 'opbrengsten-opdracht',
    path: '/opbrengsten/opdracht',
  },
  {
    component: OpbrengstenVerdieping,
    exact: true,
    key: 'opbrengsten-verdieping',
    path: '/opbrengsten/verdieping',
  },
  {
    component: StartBibliotheek,
    exact: true,
    key: 'start-bibliotheek',
    path: '/start/bibliotheek',
  },
  {
    component: StartCultureleDiversiteit,
    exact: true,
    key: 'start-culturele-diversiteit',
    path: '/start/culturele-diversiteit',
  },
  {
    component: StartMijnOrganisatie,
    exact: true,
    key: 'start-mijn-organisatie',
    path: '/start/mijn-organisatie',
  },
  {
    component: StartOverviewTool,
    exact: true,
    key: 'start-overview-tool',
    path: '/start/overview-tool',
  },
  {
    component: StartVoortgang,
    exact: true,
    key: 'start-voortgang',
    path: '/start/voortgang',
  },
  {
    component: SuccesfactorenBibliotheek,
    exact: true,
    key: 'succesfactoren-bibliotheek',
    path: '/succesfactoren/bibliotheek',
  },
  {
    component: SuccesfactorenIntroductie,
    exact: true,
    key: 'succesfactoren-introductie',
    path: '/succesfactoren/introductie',
  },
  {
    component: SuccesfactorenLeerdoelen,
    exact: true,
    key: 'succesfactoren-leerdoelen',
    path: '/succesfactoren/leerdoelen',
  },
  {
    component: SuccesfactorenStart,
    exact: true,
    key: 'succesfactoren-start',
    path: '/succesfactoren/start',
  },
  {
    component: SuccesfactorenUitlegEnScore,
    exact: true,
    key: 'succesfactoren-uitleg-en-score',
    path: '/succesfactoren/uitleg-en-score',
  },
  {
    component: SuccesfactorenToelichting,
    exact: true,
    key: 'succesfactoren-toelichting',
    path: '/succesfactoren/toelichting',
  },
  {
    component: SuccesfactorenOpdracht,
    exact: true,
    key: 'succesfactoren-opdracht',
    path: '/succesfactoren/opdracht',
  },
];


export default Routes;

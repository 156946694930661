/* eslint-disable quote-props */

import { ProceedType } from './index';

const averages: Record<ProceedType, any> = {
  inclusiefLeiderschap: {
    total: 3.7173,
    'Rabobank': {
      total: 3.6966,
      'Centrale eenheden': {
        total: 3.5897,
        'Domein particulieren': 3.5540,
        'Domein CIO/COO': 3.6090,
      },
      'Lokale banken': {
        total: 3.7705,
      },
    },
    'Randstad Groep Nederland': {
      total: 3.7824,
      'Randstad Nederland': {
        total: 3.8285,
        'RUB': 3.8309,
        'RIS': 3.8715,
        'S&AM': 3.7030,
        'HRS': 3.8484,
        'RCS': 3.6514,
      },
      'Tempo-Team': {
        total: 3.8380,
        'TTU': 3.8747,
        'TTIS': 3.9534,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 3.3992,
      },
      'Yacht': {
        total: 3.8382,
        'SCS': 3.7741,
        'Finance & HLM': 3.9202,
        'Engineering & SCM&P': 3.8946,
        'IT': 3.9486,
      },
      'BMC': {
        total: 3.6411,
        'Implementatie': 3.6751,
        'Advies': 3.5635,
      },
      'RG Staven': {
        total: 3.6932,
        'Business Services': 3.6647,
        'Health@Work': 3.8182,
        'IT + CPM': 3.3320,
        'Staven Overig': 3.8969,
      },
    },
    'PostNL': {
      total: 3.6782,
      'Hoofdkantoor Den Haag': {
        total: 3.7268,
        'Den Haag HR': 3.7469,
        'Den Haag IT': 3.8043,
        'Den Haag CBS': 3.8745,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.5266,
      },
      'Productieomgeving': {
        total: 3.6739,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.5332,
      'Plv. Secretaris Generaal': {
        total: 3.6944,
        'OBP': 3.6179,
        'DSU': 3.6917,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 3.9152,
        'S&I': null,
        'WR': 3.9429,
      },
      'Werk': {
        total: 3.4554,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.3278,
        'Toezicht': 3.1752,
        'M&M': 3.3925,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.7259,
        'FEZ': 3.7792,
        'C': null,
      },
    },
    'Politie': {
      total: 3.3721,
      'Eenheid Amsterdam': {
        total: 3.4382,
        'Centrum Burgwallen': 3.0784,
        'Centrum Jordaan': 3.2037,
        'Boven IJ': 3.4815,
        'Zuid Buitenveldert': 3.8068,
      },
      'Eenheid Den Haag': {
        total: 3.4318,
        'Laak': 3.8268,
        'Zoetermeer': 3.4281,
      },
      'Eenheid Oost-Nederland': {
        total: 3.3754,
        'Enschede': 3.4236,
        'Veluwe West': 3.2026,
      },
      'Eenheid Noord-Holland': {
        total: 3.3789,
        'Den Helder': 2.9928,
        'Hoorn': 3.75,
      },
      'Eenheid Midden-Nederland': {
        total: 3.2769,
        'Gooi en Vechtstreek Zuid': 3.3061,
        'Almere Stad Haven': 2.97,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 3.3214,
        'Utrecht-Centrum': 3.6111,
        'Infrastructuur': 3.3569,
      },
    },
  },
  cultureleVeerkracht: {
    total: 4.0727,
    'Rabobank': {
      total: 4.0613,
      'Centrale eenheden': {
        total: 4.0214,
        'Domein particulieren': 4.0244,
        'Domein CIO/COO': 4.0197,
      },
      'Lokale banken': {
        total: 4.0890,
      },
    },
    'Randstad Groep Nederland': {
      total: 4.1378,
      'Randstad Nederland': {
        total: 4.1496,
        'RUB': 4.1631,
        'RIS': 4.0606,
        'S&AM': 4.1400,
        'HRS': 4.2792,
        'RCS': 4.2945,
      },
      'Tempo-Team': {
        total: 4.1804,
        'TTU': 4.2196,
        'TTIS': 4.1670,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 4.0455,
      },
      'Yacht': {
        total: 4.1729,
        'SCS': 4.1564,
        'Finance & HLM': 4.3235,
        'Engineering & SCM&P': 4.1234,
        'IT': 4.1855,
      },
      'BMC': {
        total: 4.1038,
        'Implementatie': 4.0946,
        'Advies': 4.1380,
      },
      'RG Staven': {
        total: 4.0800,
        'Business Services': 4.0429,
        'Health@Work': 4.3361,
        'IT + CPM': 4.8305,
        'Staven Overig': 4.1197,
      },
    },
    'PostNL': {
      total: 4.0030,
      'Hoofdkantoor Den Haag': {
        total: 3.9706,
        'Den Haag HR': 4.0303,
        'Den Haag IT': 3.9802,
        'Den Haag CBS': 4.0496,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.9894,
      },
      'Productieomgeving': {
        total: 4.1561,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.9491,
      'Plv. Secretaris Generaal': {
        total: 3.9916,
        'OBP': 3.0592,
        'DSU': 3.8772,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 3.9290,
        'S&I': null,
        'WR': 3.9527,
      },
      'Werk': {
        total: 3.6818,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.9848,
        'Toezicht': 3.9365,
        'M&M': 4.0178,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.7912,
        'FEZ': 3.7355,
        'C': null,
      },
    },
    'Politie': {
      total: 4.1393,
      'Eenheid Amsterdam': {
        total: 4.1717,
        'Centrum Burgwallen': 4.1176,
        'Centrum Jordaan': 4.1752,
        'Boven IJ': 4.2094,
        'Zuid Buitenveldert': 4.0792,
      },
      'Eenheid Den Haag': {
        total: 4.1596,
        'Laak': 4.052,
        'Zoetermeer': 4.1652,
      },
      'Eenheid Oost-Nederland': {
        total: 4.1386,
        'Enschede': 4.1769,
        'Veluwe West': 4.0928,
      },
      'Eenheid Noord-Holland': {
        total: 4.1287,
        'Den Helder': 4.0323,
        'Hoorn': 4.2055,
      },
      'Eenheid Midden-Nederland': {
        total: 4.1081,
        'Gooi en Vechtstreek Zuid': 4.1495,
        'Almere Stad Haven': 4.0104,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 4.1264,
        'Utrecht-Centrum': 4.1755,
        'Infrastructuur': 4.0559,
      },
    },
  },
  'Werktevredenheid': {
    total: 3.9447,
    'Rabobank': {
      total: 3.9171,
      'Centrale eenheden': {
        total: 3.8974,
        'Domein particulieren': 3.8366,
        'Domein CIO/COO': 3.9303,
      },
      'Lokale banken': {
        total: 3.9308,
      },
    },
    'Randstad Groep Nederland': {
      total: 4.0136,
      'Randstad Nederland': {
        total: 3.9683,
        'RUB': 3.9684,
        'RIS': 3.8704,
        'S&AM': 3.9868,
        'HRS': 4.1653,
        'RCS': 3.8000,
      },
      'Tempo-Team': {
        total: 4.0628,
        'TTU': 4.1077,
        'TTIS': 3.9696,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 4.0706,
      },
      'Yacht': {
        total: 4.0509,
        'SCS': 4.1395,
        'Finance & HLM': 4.2059,
        'Engineering & SCM&P': 3.9429,
        'IT': 4.2320,
      },
      'BMC': {
        total: 4.1024,
        'Implementatie': 4.0890,
        'Advies': 4.1667,
      },
      'RG Staven': {
        total: 4.0000,
        'Business Services': 3.9583,
        'Health@Work': 4.1212,
        'IT + CPM': 3.7243,
        'Staven Overig': 4.1620,
      },
    },
    'PostNL': {
      total: 3.8392,
      'Hoofdkantoor Den Haag': {
        total: 3.8533,
        'Den Haag HR': 3.9298,
        'Den Haag IT': 3.9783,
        'Den Haag CBS': 3.9333,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.8244,
      },
      'Productieomgeving': {
        total: 3.8000,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.9027,
      'Plv. Secretaris Generaal': {
        total: 3.9000,
        'OBP': 3.9070,
        'DSU': 3.8842,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 4.0313,
        'S&I': null,
        'WR': 4.0320,
      },
      'Werk': {
        total: 3.7875,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.9133,
        'Toezicht': 3.8792,
        'M&M': 3.9271,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.8000,
        'FEZ': 3.7939,
        'C': null,
      },
    },
    'Politie': {
      total: 3.9822,
      'Eenheid Amsterdam': {
        total: 3.9614,
        'Centrum Burgwallen': 3.8882,
        'Centrum Jordaan': 3.9389,
        'Boven IJ': 4.1667,
        'Zuid Buitenveldert': 3.9942,
      },
      'Eenheid Den Haag': {
        total: 3.9822,
        'Laak': 4.1,
        'Zoetermeer': 4.0235,
      },
      'Eenheid Oost-Nederland': {
        total: 4.0772,
        'Enschede': 4.165,
        'Veluwe West': 4.1118,
      },
      'Eenheid Noord-Holland': {
        total: 3.9263,
        'Den Helder': 3.7355,
        'Hoorn': 3.9219,
      },
      'Eenheid Midden-Nederland': {
        total: 3.9506,
        'Gooi en Vechtstreek Zuid': 4.0302,
        'Almere Stad Haven': 3.8811,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 4.0107,
        'Utrecht-Centrum': 4.175,
        'Infrastructuur': 3.9273,
      },
    },
  },
  'Identificatie met de organisatie': {
    total: 3.6091,
    'Rabobank': {
      total: 3.6385,
      'Centrale eenheden': {
        total: 3.4498,
        'Domein particulieren': 3.4665,
        'Domein CIO/COO': 3.4408,
      },
      'Lokale banken': {
        total: 3.7692,
      },
    },
    'Randstad Groep Nederland': {
      total: 3.6762,
      'Randstad Nederland': {
        total: 3.6501,
        'RUB': 3.6938,
        'RIS': 3.5220,
        'S&AM': 3.7664,
        'HRS': 3.7143,
        'RCS': 3.6100,
      },
      'Tempo-Team': {
        total: 3.7634,
        'TTU': 3.8846,
        'TTIS': 3.6658,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 3.6103,
      },
      'Yacht': {
        total: 3.7702,
        'SCS': 3.7907,
        'Finance & HLM': 4.0662,
        'Engineering & SCM&P': 3.6905,
        'IT': 3.8300,
      },
      'BMC': {
        total: 3.6890,
        'Implementatie': 3.6798,
        'Advies': 3.6944,
      },
      'RG Staven': {
        total: 3.6069,
        'Business Services': 3.4896,
        'Health@Work': 3.7348,
        'IT + CPM': 3.1824,
        'Staven Overig': 3.8734,
      },
    },
    'PostNL': {
      total: 3.5899,
      'Hoofdkantoor Den Haag': {
        total: 3.6218,
        'Den Haag HR': 3.6754,
        'Den Haag IT': 3.7120,
        'Den Haag CBS': 3.7955,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.5363,
      },
      'Productieomgeving': {
        total: 3.5267,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.2842,
      'Plv. Secretaris Generaal': {
        total: 3.1296,
        'OBP': 3.2326,
        'DSU': 2.9693,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 3.4766,
        'S&I': null,
        'WR': 3.7500,
      },
      'Werk': {
        total: 3.0938,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.3454,
        'Toezicht': 3.2500,
        'M&M': 3.4065,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.3649,
        'FEZ': 3.3182,
        'C': null,
      },
    },
    'Politie': {
      total: 3.8546,
      'Eenheid Amsterdam': {
        total: 3.7175,
        'Centrum Burgwallen': 3.4926,
        'Centrum Jordaan': 3.7708,
        'Boven IJ': 3.8403,
        'Zuid Buitenveldert': 3.7283,
      },
      'Eenheid Den Haag': {
        total: 3.9196,
        'Laak': 4,
        'Zoetermeer': 3.8971,
      },
      'Eenheid Oost-Nederland': {
        total: 3.9669,
        'Enschede': 3.9531,
        'Veluwe West': 3.9485,
      },
      'Eenheid Noord-Holland': {
        total: 3.9158,
        'Den Helder': 3.8548,
        'Hoorn': 3.9063,
      },
      'Eenheid Midden-Nederland': {
        total: 3.8056,
        'Gooi en Vechtstreek Zuid': 3.7642,
        'Almere Stad Haven': 3.9662,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 3.7723,
        'Utrecht-Centrum': 4.2266,
        'Infrastructuur': 3.6439,
      },
    },
  },
  'Ervaren sfeer in de werkomgeving': {
    total: 3.9651,
    'Rabobank': {
      total: 3.9108,
      'Centrale eenheden': {
        total: 3.8355,
        'Domein particulieren': 3.7409,
        'Domein CIO/COO': 3.8865,
      },
      'Lokale banken': {
        total: 3.9630,
      },
    },
    'Randstad Groep Nederland': {
      total: 4.0122,
      'Randstad Nederland': {
        total: 3.9829,
        'RUB': 4.0419,
        'RIS': 3.8522,
        'S&AM': 4.0329,
        'HRS': 3.9617,
        'RCS': 3.9700,
      },
      'Tempo-Team': {
        total: 4.0364,
        'TTU': 4.1538,
        'TTIS': 3.9022,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 3.8750,
      },
      'Yacht': {
        total: 4.1040,
        'SCS': 4.0756,
        'Finance & HLM': 4.2647,
        'Engineering & SCM&P': 4.1071,
        'IT': 4.1900,
      },
      'BMC': {
        total: 4.0768,
        'Implementatie': 4.0873,
        'Advies': 4.0556,
      },
      'RG Staven': {
        total: 3.9692,
        'Business Services': 3.9965,
        'Health@Work': 4.0303,
        'IT + CPM': 3.5338,
        'Staven Overig': 4.1677,
      },
    },
    'PostNL': {
      total: 3.9410,
      'Hoofdkantoor Den Haag': {
        total: 4.0018,
        'Den Haag HR': 4.0439,
        'Den Haag IT': 4.1033,
        'Den Haag CBS': 4.1439,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.8445,
      },
      'Productieomgeving': {
        total: 3.8130,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.8791,
      'Plv. Secretaris Generaal': {
        total: 3.9352,
        'OBP': 3.9360,
        'DSU': 3.8991,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 4.2734,
        'S&I': null,
        'WR': 4.3900,
      },
      'Werk': {
        total: 3.9844,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.6691,
        'Toezicht': 3.5094,
        'M&M': 3.7757,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 4.3108,
        'FEZ': 4.3561,
        'C': null,
      },
    },
    'Politie': {
      total: 3.9667,
      'Eenheid Amsterdam': {
        total: 4.043,
        'Centrum Burgwallen': 4.1029,
        'Centrum Jordaan': 3.9097,
        'Boven IJ': 4.1389,
        'Zuid Buitenveldert': 4.1014,
      },
      'Eenheid Den Haag': {
        total: 3.9703,
        'Laak': 4.1324,
        'Zoetermeer': 3.8676,
      },
      'Eenheid Oost-Nederland': {
        total: 4.0763,
        'Enschede': 4.1719,
        'Veluwe West': 4.0294,
      },
      'Eenheid Noord-Holland': {
        total: 3.8697,
        'Den Helder': 3.4435,
        'Hoorn': 4.1602,
      },
      'Eenheid Midden-Nederland': {
        total: 3.8681,
        'Gooi en Vechtstreek Zuid': 3.9387,
        'Almere Stad Haven': 3.5743,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 3.933,
        'Utrecht-Centrum': 4.1172,
        'Infrastructuur': 3.9545,
      },
    },
  },
  'Ervaren carrièreperspectief': {
    total: 3.0159,
    'Rabobank': {
      total: 2.8864,
      'Centrale eenheden': {
        total: 2.8390,
        'Domein particulieren': 2.6748,
        'Domein CIO/COO': 2.9276,
      },
      'Lokale banken': {
        total: 2.9191,
      },
    },
    'Randstad Groep Nederland': {
      total: 3.1067,
      'Randstad Nederland': {
        total: 3.0800,
        'RUB': 3.1930,
        'RIS': 3.0901,
        'S&AM': 2.9123,
        'HRS': 2.8571,
        'RCS': 2.9733,
      },
      'Tempo-Team': {
        total: 3.1839,
        'TTU': 3.1359,
        'TTIS': 3.3370,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 2.9216,
      },
      'Yacht': {
        total: 3.1214,
        'SCS': 2.9612,
        'Finance & HLM': 3.1176,
        'Engineering & SCM&P': 3.3810,
        'IT': 3.1600,
      },
      'BMC': {
        total: 3.1626,
        'Implementatie': 3.2352,
        'Advies': 2.9506,
      },
      'RG Staven': {
        total: 3.0615,
        'Business Services': 3.0370,
        'Health@Work': 2.9293,
        'IT + CPM': 2.8739,
        'Staven Overig': 3.3713,
      },
    },
    'PostNL': {
      total: 3.0059,
      'Hoofdkantoor Den Haag': {
        total: 3.0201,
        'Den Haag HR': 3.0468,
        'Den Haag IT': 3.1812,
        'Den Haag CBS': 3.3939,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.0678,
      },
      'Productieomgeving': {
        total: 2.8651,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 2.8078,
      'Plv. Secretaris Generaal': {
        total: 2.8611,
        'OBP': 3.0233,
        'DSU': 2.6784,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 3.1563,
        'S&I': null,
        'WR': 3.1867,
      },
      'Werk': {
        total: 2.7292,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 2.6358,
        'Toezicht': 2.4403,
        'M&M': 2.7508,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.1892,
        'FEZ': 3.2727,
        'C': null,
      },
    },
    'Politie': {
      total: 2.6203,
      'Eenheid Amsterdam': {
        total: 2.5345,
        'Centrum Burgwallen': 2.5686,
        'Centrum Jordaan': 2.2315,
        'Boven IJ': 2.537,
        'Zuid Buitenveldert': 2.57,
      },
      'Eenheid Den Haag': {
        total: 2.6617,
        'Laak': 3.0686,
        'Zoetermeer': 2.7059,
      },
      'Eenheid Oost-Nederland': {
        total: 2.7096,
        'Enschede': 2.5625,
        'Veluwe West': 3.049,
      },
      'Eenheid Noord-Holland': {
        total: 2.4632,
        'Den Helder': 2.0323,
        'Hoorn': 2.6094,
      },
      'Eenheid Midden-Nederland': {
        total: 2.6759,
        'Gooi en Vechtstreek Zuid': 2.673,
        'Almere Stad Haven': 2.6036,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 2.7619,
        'Utrecht-Centrum': 2.7708,
        'Infrastructuur': 2.4545,
      },
    },
  },
  'Authenticiteit': {
    total: 3.8781,
    'Rabobank': {
      total: 3.8654,
      'Centrale eenheden': {
        total: 3.7724,
        'Domein particulieren': 3.6860,
        'Domein CIO/COO': 3.8191,
      },
      'Lokale banken': {
        total: 3.9297,
      },
    },
    'Randstad Groep Nederland': {
      total: 3.9424,
      'Randstad Nederland': {
        total: 3.9475,
        'RUB': 3.9778,
        'RIS': 3.8805,
        'S&AM': 3.8388,
        'HRS': 4.0434,
        'RCS': 4.0700,
      },
      'Tempo-Team': {
        total: 4.0479,
        'TTU': 4.1231,
        'TTIS': 3.9674,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 3.9265,
      },
      'Yacht': {
        total: 3.9928,
        'SCS': 4.0698,
        'Finance & HLM': 4.0588,
        'Engineering & SCM&P': 4.0536,
        'IT': 3.9100,
      },
      'BMC': {
        total: 3.9049,
        'Implementatie': 3.9349,
        'Advies': 3.8519,
      },
      'RG Staven': {
        total: 3.8441,
        'Business Services': 3.7604,
        'Health@Work': 3.8106,
        'IT + CPM': 3.5068,
        'Staven Overig': 4.1614,
      },
    },
    'PostNL': {
      total: 3.8487,
      'Hoofdkantoor Den Haag': {
        total: 3.8878,
        'Den Haag HR': 3.8684,
        'Den Haag IT': 4.0924,
        'Den Haag CBS': 3.8864,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.8358,
      },
      'Productieomgeving': {
        total: 3.7023,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.6619,
      'Plv. Secretaris Generaal': {
        total: 3.7361,
        'OBP': 3.8605,
        'DSU': 3.6184,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 3.8906,
        'S&I': null,
        'WR': 3.9400,
      },
      'Werk': {
        total: 3.6406,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.5405,
        'Toezicht': 3.3821,
        'M&M': 3.6168,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.8243,
        'FEZ': 3.8409,
        'C': null,
      },
    },
    'Politie': {
      total: 3.7521,
      'Eenheid Amsterdam': {
        total: 3.7658,
        'Centrum Burgwallen': 3.5662,
        'Centrum Jordaan': 3.8194,
        'Boven IJ': 3.9028,
        'Zuid Buitenveldert': 3.7174,
      },
      'Eenheid Den Haag': {
        total: 3.745,
        'Laak': 3.9779,
        'Zoetermeer': 3.7794,
      },
      'Eenheid Oost-Nederland': {
        total: 3.8116,
        'Enschede': 3.9219,
        'Veluwe West': 3.6029,
      },
      'Eenheid Noord-Holland': {
        total: 3.7513,
        'Den Helder': 3.4839,
        'Hoorn': 3.9922,
      },
      'Eenheid Midden-Nederland': {
        total: 3.6965,
        'Gooi en Vechtstreek Zuid': 3.7358,
        'Almere Stad Haven': 3.6081,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 3.7902,
        'Utrecht-Centrum': 3.9375,
        'Infrastructuur': 3.7273,
      },
    },
  },
  'Erkenning (eigenwaarde/zelfbewustzijn/zelfbeoordeling)': {
    total: 4.1141,
    'Rabobank': {
      total: 4.1115,
      'Centrale eenheden': {
        total: 4.0962,
        'Domein particulieren': 3.9497,
        'Domein CIO/COO': 4.1752,
      },
      'Lokale banken': {
        total: 4.1220,
      },
    },
    'Randstad Groep Nederland': {
      total: 4.1799,
      'Randstad Nederland': {
        total: 4.1867,
        'RUB': 4.2025,
        'RIS': 4.1014,
        'S&AM': 4.1842,
        'HRS': 4.2934,
        'RCS': 4.2150,
      },
      'Tempo-Team': {
        total: 4.2179,
        'TTU': 4.2904,
        'TTIS': 4.1223,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 4.1801,
      },
      'Yacht': {
        total: 4.2449,
        'SCS': 4.2674,
        'Finance & HLM': 4.3051,
        'Engineering & SCM&P': 4.2321,
        'IT': 4.2000,
      },
      'BMC': {
        total: 4.0604,
        'Implementatie': 4.0488,
        'Advies': 4.0718,
      },
      'RG Staven': {
        total: 4.1746,
        'Business Services': 4.1545,
        'Health@Work': 4.0644,
        'IT + CPM': 3.9932,
        'Staven Overig': 4.3212,
      },
    },
    'PostNL': {
      total: 4.0586,
      'Hoofdkantoor Den Haag': {
        total: 4.0876,
        'Den Haag HR': 4.0285,
        'Den Haag IT': 4.1739,
        'Den Haag CBS': 4.1705,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 4.0189,
      },
      'Productieomgeving': {
        total: 3.9895,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.9344,
      'Plv. Secretaris Generaal': {
        total: 3.9433,
        'OBP': 3.9041,
        'DSU': 3.9364,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 4.0664,
        'S&I': null,
        'WR': 4.0900,
      },
      'Werk': {
        total: 4.0625,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.8764,
        'Toezicht': 3.8184,
        'M&M': 3.9065,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 4.0101,
        'FEZ': 4.0720,
        'C': null,
      },
    },
    'Politie': {
      total: 4.0183,
      'Eenheid Amsterdam': {
        total: 4.0654,
        'Centrum Burgwallen': 3.9963,
        'Centrum Jordaan': 3.8229,
        'Boven IJ': 4.2361,
        'Zuid Buitenveldert': 4.0145,
      },
      'Eenheid Den Haag': {
        total: 4.1058,
        'Laak': 4.1728,
        'Zoetermeer': 4.2169,
      },
      'Eenheid Oost-Nederland': {
        total: 4.0487,
        'Enschede': 4.1219,
        'Veluwe West': 3.9706,
      },
      'Eenheid Noord-Holland': {
        total: 3.9362,
        'Den Helder': 3.8468,
        'Hoorn': 4.0156,
      },
      'Eenheid Midden-Nederland': {
        total: 3.949,
        'Gooi en Vechtstreek Zuid': 3.9858,
        'Almere Stad Haven': 3.8142,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 4.0156,
        'Utrecht-Centrum': 4.1289,
        'Infrastructuur': 4.1402,
      },
    },
  },
  'Creativiteit': {
    total: 3.7335,
    'Rabobank': {
      total: 3.7295,
      'Centrale eenheden': {
        total: 3.7639,
        'Domein particulieren': 3.5823,
        'Domein CIO/COO': 3.8618,
      },
      'Lokale banken': {
        total: 3.7056,
      },
    },
    'Randstad Groep Nederland': {
      total: 3.8284,
      'Randstad Nederland': {
        total: 3.8561,
        'RUB': 3.8481,
        'RIS': 3.7877,
        'S&AM': 3.7796,
        'HRS': 3.9694,
        'RCS': 3.8900,
      },
      'Tempo-Team': {
        total: 3.8458,
        'TTU': 3.8385,
        'TTIS': 3.8587,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 3.7794,
      },
      'Yacht': {
        total: 3.6893,
        'SCS': 3.7965,
        'Finance & HLM': 3.6176,
        'Engineering & SCM&P': 3.7500,
        'IT': 3.5800,
      },
      'BMC': {
        total: 3.8012,
        'Implementatie': 3.8236,
        'Advies': 3.7593,
      },
      'RG Staven': {
        total: 3.8431,
        'Business Services': 3.7847,
        'Health@Work': 3.8864,
        'IT + CPM': 3.6351,
        'Staven Overig': 4.0380,
      },
    },
    'PostNL': {
      total: 3.6727,
      'Hoofdkantoor Den Haag': {
        total: 3.6779,
        'Den Haag HR': 3.6447,
        'Den Haag IT': 3.8315,
        'Den Haag CBS': 3.4924,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.6919,
      },
      'Productieomgeving': {
        total: 3.6260,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.4344,
      'Plv. Secretaris Generaal': {
        total: 3.4259,
        'OBP': 3.3953,
        'DSU': 3.3596,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 3.5703,
        'S&I': null,
        'WR': 3.6300,
      },
      'Werk': {
        total: 3.3125,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.4191,
        'Toezicht': 3.4009,
        'M&M': 3.3879,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.4662,
        'FEZ': 3.5076,
        'C': null,
      },
    },
    'Politie': {
      total: 3.4515,
      'Eenheid Amsterdam': {
        total: 3.4982,
        'Centrum Burgwallen': 3.4338,
        'Centrum Jordaan': 3.0486,
        'Boven IJ': 3.6667,
        'Zuid Buitenveldert': 3.6268,
      },
      'Eenheid Den Haag': {
        total: 3.4505,
        'Laak': 3.5074,
        'Zoetermeer': 3.4338,
      },
      'Eenheid Oost-Nederland': {
        total: 3.5441,
        'Enschede': 3.675,
        'Veluwe West': 3.6838,
      },
      'Eenheid Noord-Holland': {
        total: 3.3987,
        'Den Helder': 3.1774,
        'Hoorn': 3.6875,
      },
      'Eenheid Midden-Nederland': {
        total: 3.3674,
        'Gooi en Vechtstreek Zuid': 3.3915,
        'Almere Stad Haven': 3.0811,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 3.4732,
        'Utrecht-Centrum': 3.5938,
        'Infrastructuur': 3.6515,
      },
    },
  },
  'Reflectie en besluitvorming': {
    total: 3.2695,
    'Rabobank': {
      total: 3.2779,
      'Centrale eenheden': {
        total: 3.3917,
        'Domein particulieren': 3.3536,
        'Domein CIO/COO': 3.4122,
      },
      'Lokale banken': {
        total: 3.1992,
      },
    },
    'Randstad Groep Nederland': {
      total: 3.3485,
      'Randstad Nederland': {
        total: 3.3993,
        'RUB': 3.4451,
        'RIS': 3.3019,
        'S&AM': 3.3202,
        'HRS': 3.3401,
        'RCS': 3.6267,
      },
      'Tempo-Team': {
        total: 3.3282,
        'TTU': 3.3692,
        'TTIS': 3.3152,
        'S&AM, SPARQ, Payrolling, Business Consultancy': 3.1961,
      },
      'Yacht': {
        total: 3.2640,
        'SCS': 3.2171,
        'Finance & HLM': 3.5000,
        'Engineering & SCM&P': 3.1587,
        'IT': 3.0933,
      },
      'BMC': {
        total: 3.2098,
        'Implementatie': 3.2831,
        'Advies': 3.0988,
      },
      'RG Staven': {
        total: 3.3826,
        'Business Services': 3.4537,
        'Health@Work': 3.6061,
        'IT + CPM': 3.1351,
        'Staven Overig': 3.2743,
      },
    },
    'PostNL': {
      total: 3.2056,
      'Hoofdkantoor Den Haag': {
        total: 3.2026,
        'Den Haag HR': 3.1813,
        'Den Haag IT': 3.2899,
        'Den Haag CBS': 3.1313,
      },
      'Voormalig hoofdkantoor Hoofddorp': {
        total: 3.1609,
      },
      'Productieomgeving': {
        total: 3.2775,
      },
    },
    'Ministerie van Sociale Zaken en Werkgelegenheid': {
      total: 3.0364,
      'Plv. Secretaris Generaal': {
        total: 3.0432,
        'OBP': 3.1240,
        'DSU': 2.9357,
        'BO': null,
      },
      'Sociale Zekerheid en Integratie': {
        total: 2.8646,
        'S&I': null,
        'WR': 2.8533,
      },
      'Werk': {
        total: 2.7708,
        'IZ': null,
        'KO': null,
      },
      'Inspectie SZW': {
        total: 3.0520,
        'Toezicht': 3.0126,
        'M&M': 3.0872,
        'AP&S': null,
      },
      'Secretaris Generaal': {
        total: 3.2072,
        'FEZ': 3.1818,
        'C': null,
      },
    },
    'Politie': {
      total: 3.0873,
      'Eenheid Amsterdam': {
        total: 3.1482,
        'Centrum Burgwallen': 3,
        'Centrum Jordaan': 2.9375,
        'Boven IJ': 3.1319,
        'Zuid Buitenveldert': 3.2391,
      },
      'Eenheid Den Haag': {
        total: 3.1238,
        'Laak': 3.2868,
        'Zoetermeer': 3.0294,
      },
      'Eenheid Oost-Nederland': {
        total: 3.0809,
        'Enschede': 3.1375,
        'Veluwe West': 3.0956,
      },
      'Eenheid Noord-Holland': {
        total: 3.0987,
        'Den Helder': 2.9516,
        'Hoorn': 3.3203,
      },
      'Eenheid Midden-Nederland': {
        total: 3.0139,
        'Gooi en Vechtstreek Zuid': 3.0283,
        'Almere Stad Haven': 2.8986,
        'Bunschoten / Eemnes / De Bilt / Baarn / Soest': 3.1205,
        'Utrecht-Centrum': 3.1641,
        'Infrastructuur': 3.1364,
      },
    },
  },
};
/* eslint-enable quote-props */

export default averages;

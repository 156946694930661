import React, { FC, ReactElement } from 'react';

import ReflectionItem from './subcomponents/ReflectionItem/ReflectionItem';

import './Reflections.scss';

interface ReflectionsProps {
  insight?: string;
  strength?: string;
  danger?: string;
  solutions?: string[];
  className?: string;
}

export enum ReflectionType {
  insight = 'insight',
  strength = 'strength',
  danger = 'danger',
  solution = 'solution',
}


const Reflections: FC<ReflectionsProps> = ({
  insight,
  strength,
  danger,
  solutions = [],
  className = '',
}): ReactElement => {

  const renderListItem = (item: string, type: ReflectionType, hasReflectionTypePrefix: boolean) => (
    <ReflectionItem
      key={item}
      className="reflections__list-item"
      content={item}
      hasReflectionTypePrefix={hasReflectionTypePrefix}
      type={type}
    />
  );

  return (
    <div className={`reflections ${className}`}>
      <h2>Terugkoppeling</h2>
      <ul className="reflections__list">
        {insight && renderListItem(insight, ReflectionType.insight, true)}
        {strength && renderListItem(strength, ReflectionType.strength, true)}
        {danger && renderListItem(danger, ReflectionType.danger, true)}
        {!!solutions.length && (
          <ReflectionItem
            hasReflectionTypePrefix
            className="reflections__list-item"
            type={ReflectionType.solution}
          >
            <ul>
              {solutions.map((solution) => (
                renderListItem(solution, ReflectionType.solution, false)
              ))}
            </ul>
          </ReflectionItem>
        )}
      </ul>
    </div>
  );
};

export default Reflections;

import React, { FC, ReactElement } from 'react';

import { BottomNavigation, ModuleEstimation } from '..';
import { StartTitle } from '../../components';
import content from '../../content';
import moduleEstimates from '../../data/moduleEstimates';


const DrijfverenStart: FC = (): ReactElement => (
  <div className="drijfveren-start">
    <StartTitle text="Module 1: Drijfveren" />
    <ModuleEstimation description={content.drijfveren.start} minutes={moduleEstimates.opbrengsten || 0} />
    <ModuleEstimation description="Opdracht" hours={1} />
    <BottomNavigation />
  </div>
);

export default DrijfverenStart;

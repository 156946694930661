import classnames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '..';
import './Button.scss';

export interface ButtonProps {
  title?: string;
  text?: string;
  path?: string;
  icon?: string;
  type?: 'button' | 'submit' | 'reset';
  iconPos?: 'left' | 'right';
  tabIndex?: 0 | -1;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  iconClassName?: string;
}


const Button: FC<ButtonProps> = ({
  title,
  text,
  path,
  icon,
  type,
  iconPos = 'right',
  disabled,
  tabIndex,
  onClick,
  className = '',
  iconClassName = '',
}): ReactElement => {
  const linkClassNames = classnames('button button--link', {
    'button--is-disabled': disabled,
  }, className);

  const iconClassNames = classnames('button__icon', {
    [`button__icon--${iconPos}`]: text,
  }, iconClassName);

  const justifiedTabIndex = disabled ? -1 : tabIndex;

  return (path ? (
    <Link
      title={title}
      to={path}
      tabIndex={justifiedTabIndex}
      className={linkClassNames}
    >
      {iconPos === 'right' && text}
      {icon && <Icon name={icon} className={iconClassNames} /> }
      {iconPos === 'left' && text}
    </Link>
  ) : (
    <button
      type={type}
      title={title}
      tabIndex={justifiedTabIndex}
      disabled={disabled}
      onClick={onClick}
      className={`button ${className}`}
    >
      {iconPos === 'right' && text}
      {icon && <Icon name={icon} className={iconClassNames} /> }
      {iconPos === 'left' && text}
    </button>
  ));
}


export default Button;

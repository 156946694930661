import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconChevronRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 175 283.5" className={`icon ${className}`}>
    <polygon points="0,33.5 108.2,141.7 0,250.2 33.3,283.5 175,141.7 33.3,0" />
  </svg>
);


export default IconChevronRight;

import { ProceedType } from '../../../data';
import { SingleJsonApiResponseType } from '../../../fetches/JsonApiResponse';

export default function transformApiImportanceToGivenImportance(
  apiValues: Array<SingleJsonApiResponseType<'questions'>['data'] | undefined>,
  proceedTypes: ProceedType[],
): Partial<Record<ProceedType, number | null>> {
  return proceedTypes.reduce((total: Partial<Record<ProceedType, number | null>>, proceedType, index) => {
    if (!apiValues[index]) {
      return total;
    }

    const data = apiValues[index];

    return {
      ...total,
      [proceedType]: (data && data.attributes.answer) ? parseInt(data.attributes.answer, 0) : null,
    };
  }, {});
}

import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconVisibilityOff: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 283.7 245" className={`icon ${className}`}>
    <path d="M141.8,51.6c35.6,0,64.5,28.9,64.5,64.5c0,8.3-1.7,16.2-4.6,23.5l37.8,37.8c19.3-16.1,34.8-37.3,44.2-61.2c-22.3-56.6-77.4-96.7-141.8-96.7c-18.1,0-35.3,3.2-51.6,9l28,27.7C125.6,53.3,133.4,51.6,141.8,51.6z" />
    <path d="M139.6,77.4l40.9,40.7c0-0.6,0-1.4,0-2.1c0-21.4-17.3-38.7-38.7-38.7C141.1,77.4,140.4,77.4,139.6,77.4" />
    <path d="M29.3,0L12.9,16.4l29.4,29.4l5.8,5.8C26.8,68.3,10.1,90.3,0,116c22.3,56.6,77.4,96.7,141.8,96.7c20,0,39.1-3.9,56.5-10.8l5.5,5.4l37.6,37.6l16.4-16.4L29.3,0z M141.8,180.5c-35.6,0-64.5-28.9-64.5-64.5c0-10.2,2.6-19.7,6.8-28.4l20,20c-0.6,2.7-1,5.4-1,8.4c0,21.4,17.3,38.7,38.7,38.7c2.8,0,5.7-0.4,8.4-1l20,20C161.6,177.9,152,180.5,141.8,180.5z" />
  </svg>
);


export default IconVisibilityOff;

import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { Button, HTMLContent, StartTitle } from '../../components';
import content from '../../content';

import './AanDeSlagActieplan.scss';


const AanDeSlagActieplan: FC = (): ReactElement => {

  const onButtonDownloadClick = () => {
    const a = document.createElement('a');
    a.href = '/docx/opdracht-module4.docx';
    a.download = '';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <>
      <StartTitle text="Actieplan" />
      <HTMLContent html={content.aanDeSlag.actieplan.intro} />
      <Button onClick={onButtonDownloadClick} className="opbrengsten-opdracht__download-button" text="Download formulier" />

      <img className="aan-de-slag-actieplan__actieplan-preview" src="/opdracht-previews/opdracht-module4.png" alt="opdracht 4 preview" />

      <BottomNavigation />
    </>
  );
};

export default AanDeSlagActieplan;

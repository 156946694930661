import React, { FC, ReactElement, useState } from 'react';
import { useToggle } from 'react-use';

import { BottomNavigation, Modal } from '..';
import {
  Accordion,
  Button,
  HTMLContent,
  StartTitle,
} from '../../components';
import content from '../../content';
import { translate } from '../../helpers/translations';
import { Category } from '../../pages/Drijfveren/Zelfdiagnose';
import { Motivations } from '../../redux/user/userTypes';
import AccordionProceedScoreElement from '../AccordionProceedScoreElement/AccordionProceedScoreElement';
import transformPercentageToJustifiedScore from './helpers/transformPercentageToJustifiedScore';

interface DrijfverenMijnDrijfveerProps {
  motivations: Motivations;
}

const DrijfverenMijnDrijfveer: FC<DrijfverenMijnDrijfveerProps> = ({ motivations }): ReactElement => {
  const [modalActive, toggleModal] = useToggle(false);
  const [activeCategory, setActiveCategory] = useState<Category | null>();

  const handleQualityClick = (): void => {
    setActiveCategory('kwaliteit');
    toggleModal(true);
  };
  const handleJusticeClick = (): void => {
    setActiveCategory('rechtvaardigheid');
    toggleModal(true);
  };
  const handleAccessClick = (): void => {
    setActiveCategory('toegang');
    toggleModal(true);
  };
  const handleLearningClick = (): void => {
    setActiveCategory('leren');
    toggleModal(true);
  };

  return (
    <>
      <StartTitle text="Mijn Drijfveer" />
      <HTMLContent html={content.drijfveren.mijnDrijfveer.intro} />
      <Accordion
        id="drijfveren-mijn-drijfveer"
        items={[
          {
            label: 'Kwaliteit',
            element: <AccordionProceedScoreElement
              intro={translate('drijfveren.mijnDrijfveer.drijfveerScore', { drijfveer: 'kwaliteit' })}
              score={transformPercentageToJustifiedScore(motivations.kwaliteit)}
            />,
          },
          {
            label: 'Rechtvaardigheid',
            element: <AccordionProceedScoreElement
              intro={translate('drijfveren.mijnDrijfveer.drijfveerScore', { drijfveer: 'rechtvaardigheid' })}
              score={transformPercentageToJustifiedScore(motivations.rechtvaardigheid)}
            />,
          },
          {
            label: 'Toegang',
            element: <AccordionProceedScoreElement
              intro={translate('drijfveren.mijnDrijfveer.drijfveerScore', { drijfveer: 'toegang' })}
              score={transformPercentageToJustifiedScore(motivations.toegang)}
            />,
          },
          {
            label: 'Leren',
            element: <AccordionProceedScoreElement
              intro={translate('drijfveren.mijnDrijfveer.drijfveerScore', { drijfveer: 'leren' })}
              score={transformPercentageToJustifiedScore(motivations.leren)}
            />,
          },
        ]}
      />

      <ul className="flex-list">
        <li><Button text="Info over Kwaliteit" onClick={handleQualityClick} /></li>
        <li><Button text="Info over Rechvaardigheid" onClick={handleJusticeClick} /></li>
        <li><Button text="Info over Toegang" onClick={handleAccessClick} /></li>
        <li><Button text="Info over Leren" onClick={handleLearningClick} /></li>
      </ul>

      {modalActive && (
        <Modal toggleModal={toggleModal}>
          {activeCategory === 'kwaliteit' && (
            <>
              <iframe
                title="Kwaliteit"
                src={content.drijfveren.mijnDrijfveer.quality.animation}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Accordion
                id="drijfveren-mijn-drijfveer-kwaliteit"
                items={[
                  { label: 'Definitie', htmlContent: content.drijfveren.mijnDrijfveer.quality.definition },
                  { label: 'Doelen', htmlContent: content.drijfveren.mijnDrijfveer.quality.goals },
                  { label: 'Methoden', htmlContent: content.drijfveren.mijnDrijfveer.quality.methods },
                  { label: 'Kracht', htmlContent: content.drijfveren.mijnDrijfveer.quality.strength },
                  {
                    label: 'Valkuilen',
                    element: (
                      <>
                        <HTMLContent html={content.drijfveren.mijnDrijfveer.quality.pitfalls} />
                        <Accordion
                          id="drijfveren-mijn-drijfveer-kwaliteit-pitfalls"
                          items={content.drijfveren.mijnDrijfveer.quality.pitfallItems}
                        />
                      </>
                    ),
                  },
                  { label: 'Handvatten', htmlContent: content.drijfveren.mijnDrijfveer.quality.handles },
                ]}
              />
            </>
          )}

          {activeCategory === 'rechtvaardigheid' && (
            <>
              <iframe
                title="Rechtvaardigheid"
                src={content.drijfveren.mijnDrijfveer.justice.animation}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Accordion
                id="drijfveren-mijn-drijfveer-rechtvaardigheid"
                items={[
                  { label: 'Definitie', htmlContent: content.drijfveren.mijnDrijfveer.justice.definition },
                  { label: 'Doelen', htmlContent: content.drijfveren.mijnDrijfveer.justice.goals },
                  { label: 'Kracht', htmlContent: content.drijfveren.mijnDrijfveer.justice.strength },
                  {
                    label: 'Valkuilen',
                    element: (
                      <>
                        <HTMLContent html={content.drijfveren.mijnDrijfveer.justice.pitfalls} />
                        <Accordion
                          id="drijfveren-mijn-drijfveer-rechtvaardigheid-pitfalls"
                          items={content.drijfveren.mijnDrijfveer.justice.pitfallItems}
                        />
                      </>
                    ),
                  },
                  { label: 'Handvatten', htmlContent: content.drijfveren.mijnDrijfveer.justice.handles },
                ]}
              />
            </>
          )}

          {activeCategory === 'toegang' && (
            <>
              <iframe
                title="Toegang"
                src={content.drijfveren.mijnDrijfveer.access.animation}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Accordion
                id="drijfveren-mijn-drijfveer-toegang"
                items={[
                  {
                    label: 'Definitie',
                    element: (
                      <>
                        <HTMLContent html={content.drijfveren.mijnDrijfveer.access.definition} />
                        <Accordion
                          id="drijfveren-mijn-drijfveer-toegang-pitfalls"
                          items={content.drijfveren.mijnDrijfveer.access.definitionItems}
                        />
                      </>
                    ),
                  },
                  { label: 'Doelen', htmlContent: content.drijfveren.mijnDrijfveer.access.goals },
                  { label: 'Methoden', htmlContent: content.drijfveren.mijnDrijfveer.access.methods },
                  { label: 'Kracht', htmlContent: content.drijfveren.mijnDrijfveer.access.strength },
                  {
                    label: 'Valkuilen',
                    element: (
                      <>
                        <HTMLContent html={content.drijfveren.mijnDrijfveer.access.pitfalls} />
                        <Accordion
                          id="drijfveren-mijn-drijfveer-kwaliteit-pitfalls"
                          items={content.drijfveren.mijnDrijfveer.access.pitfallItems}
                        />
                      </>
                    ),
                  },
                  { label: 'Handvatten', htmlContent: content.drijfveren.mijnDrijfveer.access.handles },
                ]}
              />
            </>
          )}

          {activeCategory === 'leren' && (
            <>
              <iframe
                title="Leren"
                src={content.drijfveren.mijnDrijfveer.learning.animation}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Accordion
                id="drijfveren-mijn-drijfveer-leren"
                items={[
                  { label: 'Definitie', htmlContent: content.drijfveren.mijnDrijfveer.learning.definition },
                  { label: 'Doelen', htmlContent: content.drijfveren.mijnDrijfveer.learning.goals },
                  { label: 'Kracht', htmlContent: content.drijfveren.mijnDrijfveer.learning.strength },
                  {
                    label: 'Valkuilen',
                    element: (
                      <>
                        <HTMLContent html={content.drijfveren.mijnDrijfveer.learning.pitfalls} />
                        <Accordion
                          id="drijfveren-mijn-drijfveer-leren-pitfalls"
                          items={content.drijfveren.mijnDrijfveer.learning.pitfallItems}
                        />
                      </>
                    ),
                  },
                  { label: 'Handvatten', htmlContent: content.drijfveren.mijnDrijfveer.learning.handles },
                ]}
              />
            </>
          )}
        </Modal>
      )}

      <BottomNavigation />
    </>
  );
};

export default DrijfverenMijnDrijfveer;

import React, { FC, ReactElement, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useToggle } from 'react-use';

import { getRouteModuleIndex, getRoutePageIndex, NavRouteModule } from '../../../Routes';

import { Icon } from '../../../components';
import '../SideNavigation.scss';

interface DynamicNavigationModuleProps {
  module: NavRouteModule;
  canCollapse?: boolean;
  activeModule: string;
  furthestModule: string;
  furthestPage: string;
}


const DynamicNavigationModule: FC<DynamicNavigationModuleProps> = ({
  module,
  canCollapse,
  activeModule,
  furthestModule,
  furthestPage,
}): ReactElement => {
  const [isCollapsed, toggleCollapse] = useToggle((activeModule === module.slug) || false);

  useEffect((): void => {
    toggleCollapse(activeModule === module.slug);
  }, [toggleCollapse, activeModule, module]);

  const handleCollapse = (): void => {
    if (canCollapse) {
      toggleCollapse();
    }
  };

  return (
    <div key={module.slug} className="side-navigation__module-container">
      <button
        tabIndex={canCollapse ? 0 : -1}
        onClick={handleCollapse}
        className={`side-navigation__module-button ${!canCollapse ? 'side-navigation__module-button--is-inactive' : ''}`}
      >
        <span className="side-navigation__module-button-title">{module.title}</span>
        {canCollapse && <Icon name={isCollapsed ? 'minus' : 'plus'} className="side-navigation__module-button-icon" />}
      </button>
      <nav className={`side-navigation__module-nav ${isCollapsed ? 'side-navigation__module-nav--is-collapsed' : ''}`}>
        <ul className="side-navigation__module-nav-list">
          {module.pages.map((page) => {
            const furthestModuleIndex = getRouteModuleIndex(furthestModule);
            const furthestPageIndex = getRoutePageIndex(furthestModuleIndex, furthestPage);

            const moduleIndex = getRouteModuleIndex(module.slug);
            const pageIndex = getRoutePageIndex(moduleIndex, page.slug);

            const isActivePage = moduleIndex === furthestModuleIndex && pageIndex === furthestPageIndex;
            const isVisitedPage = (moduleIndex < furthestModuleIndex)
              || (moduleIndex === furthestModuleIndex && pageIndex < furthestPageIndex);

            return (
              <li key={page.slug} className="side-navigation__module-nav-item">
                {isActivePage || isVisitedPage ? (
                  <>
                    <div className="side-navigation__module-nav-icon-container">
                      {isActivePage ? (
                        <Icon name="dot" className="side-navigation__module-nav-icon side-navigation__module-nav-icon--active-page" />
                      ) : (
                        <Icon name="check" className="side-navigation__module-nav-icon" />
                      )}
                    </div>
                    <NavLink
                      to={`/${module.slug}/${page.slug}`}
                      className="side-navigation__module-nav-link"
                      activeClassName="side-navigation__module-nav-link--is-active"
                    >
                      {page.title}
                    </NavLink>
                  </>
                ) : (
                  <span className="side-navigation__module-nav-link side-navigation__module-nav-link--disabled">
                    {page.title}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};


export default DynamicNavigationModule;

import * as d3 from 'd3';
import React, {
  FC,
  ReactElement,
  useEffect,
  useRef,
} from 'react';

import './BarChart.scss';

interface Category {
  category: string;
  value: number;
}

interface BarChartProps {
  data: { [key: string]: number };
  isPercentage?: boolean;
  timesTwo?: boolean;
  isScore?: boolean;
  className?: string;
}


const BarChart: FC<BarChartProps> = ({
  data,
  isPercentage,
  timesTwo,
  isScore,
  className = '',
}): ReactElement => {
  const graphContainer = useRef(null);
  const margin = {
    top: 20,
    right: 20,
    bottom: 60,
    left: 60,
  };

  const width = 600 - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;

  useEffect(() => {
    if (graphContainer.current) {
      const svg = d3.select(graphContainer.current);
      const graph = svg
        .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
        .append('g')
        .attr('class', 'bar-chart__main')
        .attr('transform',
          `translate(${margin.left}, ${margin.top})`);

      graph.append('g')
        .attr('class', 'bar-chart__axis bar-chart__axis--x')
        .attr('transform', `translate(0, ${height})`);

      graph.append('g')
        .attr('class', 'bar-chart__axis bar-chart__axis--y');
    }
  }, [width, height, margin]);

  useEffect((): void => {
    if (data && graphContainer.current) {
      const cleanData = Object.keys(data).filter(entry => data[entry]);
      const iterableData = cleanData.map((key): Category => ({ category: key, value: data[key] }));
      const sortedData = iterableData.map(entry => entry.value).sort((a, b) => b - a);

      const midRange = 2.5;
      const minimumYScaleValue = sortedData[sortedData.length - 1] < midRange
        ? (sortedData[sortedData.length - 1] - 0.5)
        : midRange;

      const svg = d3.select(graphContainer.current);
      const graphMain = svg.select('.bar-chart__main');

      const xScale = d3.scaleBand()
        .domain(iterableData.map((d): string => d.category))
        .range([0, width]);

      const yScale = d3.scaleLinear()
      // eslint-disable-next-line no-nested-ternary
        .domain(isPercentage ? [0, 100] : (isScore ? [minimumYScaleValue, 5] : [sortedData[sortedData.length - 1], sortedData[0]]))
        .nice()
        .range([height, 0]);

      const xAxis = d3.axisBottom(xScale).ticks(data.length);
      const yAxis = d3.axisLeft(yScale).ticks(5).tickFormat((d): string => (isPercentage ? `${d}%` : String(d)));

      if (isPercentage) {
        const yLines = graphMain.selectAll('.bar-chart__y-line').data([20, 40, 60, 80]);
        yLines.enter().append('line')
          .attr('transform', (d): string => `translate(0, ${yScale(d) + 1 || 0})`)
          .attr('x2', width)
          .attr('class', 'bar-chart__y-line')
          .lower();
      }

      const bars = graphMain.selectAll('.bar-chart__bar').data(iterableData);
      bars.enter().append('rect')
        .attr('class', (d): string => `bar-chart__bar bar-chart__bar--${sortedData.indexOf(d.value) + 1}`)
        .attr('width', xScale.bandwidth() / 2)
        .attr('height', (d): number => height - yScale(timesTwo ? d.value * 2 : d.value))
        .attr('x', (d): number => (xScale(d.category) || 0) + (xScale.bandwidth() / 4))
        .attr('y', (d): number => yScale(timesTwo ? d.value * 2 : d.value));

      // @ts-ignore
      svg.select('.bar-chart__axis--x').call(xAxis);
      // @ts-ignore
      svg.select('.bar-chart__axis--y').call(yAxis);
    }
  }, [data, isPercentage, width, height, margin, timesTwo, isScore]);

  return <svg ref={graphContainer} className={`bar-chart ${className}`} />;
};


export default BarChart;

export default function getScoreTranslation(score: number): string {
  switch (score) {
    case 1:
      return 'laag';
    case 2:
      return 'laag gemiddeld';
    default:
      return 'gemiddeld';
    case 4:
      return 'hoog gemiddeld';
    case 5:
      return 'hoog';
  }
}

import React, { FC, ReactElement } from 'react';
import { SuccesfactorenBibliotheek } from '../../containers';

const Bibliotheek: FC = (): ReactElement => (
  <main className="page">
    <SuccesfactorenBibliotheek />
  </main>
);

export default Bibliotheek;

import React, { FC, ReactElement } from 'react';

import './HTMLContent.scss';

interface HTMLContentProps {
  html: string;
  className?: string;
}


const HTMLContent: FC<HTMLContentProps> = ({ html, className = '' }): ReactElement => (
  <span className={`html-content ${className}`} dangerouslySetInnerHTML={{ __html: html }} />
);


export default HTMLContent;

import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconLogout: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 283.5 283.5" className={`icon ${className}`}>
    <path d="M252,0c17.4,0,31.5,14.1,31.5,31.5v73.6L252,73.6V31.5H31.3v220.7H252v-42.1l31.5-31.5v73.6c0,17.4-14.1,31.5-31.5,31.5H31.3c-17.5,0-31.5-14.2-31.5-31.5V31.5C-0.2,14,13.8,0,31.3,0H252z" />
    <path d="M174.4,198.5l40.8-40.8H62.8v-31.5h152.4l-40.8-40.8l22.4-22.2l78.8,78.8l-78.8,78.8L174.4,198.5" />
  </svg>
);


export default IconLogout;

import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconPlus: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 283.5 283.5" className={`icon ${className}`}>
    <polygon points="283.5,162 162,162 162,283.5 121.5,283.5 121.5,162 0,162 0,121.5 121.5,121.5 121.5,0 162,0 162,121.5 283.5,121.5" />
  </svg>
);


export default IconPlus;

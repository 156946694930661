import ResponseTypes from './ResponseTypes';

export interface OptionalAttributes<Type extends keyof ResponseTypes> {
  attributes: Partial<ResponseTypes[Type]['attributes']>;
  relationships: ResponseTypes[Type]['relationships'] extends [] ? [] : Partial<ResponseTypes[Type]['relationships']>;
}

type Overwrite<T1, T2> = {
  [P in Exclude<keyof T1, keyof T2>]: T1[P]
} & T2;

export interface PostJsonApiRequestType<Type extends keyof ResponseTypes> {
  data: {
    type: Type;
    attributes: Overwrite<ResponseTypes[Type]['attributes'], {
      dynamic?: never;
    }>;
    relationships: ResponseTypes[Type]['relationships'];
  };
}

export interface PatchJsonApiRequestType<Type extends keyof ResponseTypes> {
  data: {
    type: Type;
    id: string;
    attributes: OptionalAttributes<Type>['attributes'];
    relationships: OptionalAttributes<Type>['relationships'];
  };
}

export const parsePostRequest = <Type extends keyof ResponseTypes>(attributes: ResponseTypes[Type]['attributes'], relationships: ResponseTypes[Type]['relationships'], type: Type): PostJsonApiRequestType<Type> => ({
  data: {
    type,
    attributes,
    relationships,
  },
});

export const parsePatchRequest = <Type extends keyof ResponseTypes>(id: string, attributes: OptionalAttributes<Type>['attributes'], relationships: OptionalAttributes<Type>['relationships'], type: Type): PatchJsonApiRequestType<Type> => ({
  data: {
    type,
    id,
    attributes,
    relationships,
  },
});

export interface GetListPayload {
  filters?: {[key: string]: string|number};
  sort?: string[];
  rql?: string;
  includes?: string[];
}

export const parseGetListPayload = (payload: GetListPayload): string => {
  const params: string[] = [];

  if (payload.includes) {
    params.push(`include=${payload.includes.join(',')}`);
  }

  if (payload.filters !== undefined) {
    const filters = Object.entries(payload.filters);

    for (let i = 0; i < filters.length; i += 1) {
      params.push(`filter[${filters[i][0]}]=${filters[i][1]}`);
    }
  }

  if (payload.rql !== undefined) {
    params.push(`rql=(${payload.rql})`);
  }

  if (payload.sort !== undefined) {
    params.push(`sort=${payload.sort}`);
  }

  return params.join('&');
};

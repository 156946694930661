import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { Accordion, HTMLContent, PageHeader } from '../../components';
import { CaseInputValues } from '../../connectors/ConnectedDrijfverenCasuistiek/ConnectedDrijfverenCasuistiek';
import content from '../../content';
import { Case } from '../CaseSelection/CaseSelection';
import DrijfverenCasuistiekCase from './subcomponents/DrijfverenCasuistiekCase/DrijfverenCasuistiekCase';

interface DrijfverenCasuistiekProps {
  caseStudyMap: { [key: number]: any };
  caseInputValues: CaseInputValues;
  onSubmit: (ref: React.RefObject<HTMLDivElement>) => void;
  onChange: (values: CaseInputValues) => void;
  nextButtonIsLocked: boolean;
  isLoading: boolean;
  isFinished: boolean;
  data: any;
}

const DrijfverenCasuistiek: FC<DrijfverenCasuistiekProps> = ({
  caseStudyMap,
  caseInputValues,
  onSubmit,
  onChange,
  nextButtonIsLocked,
  isLoading,
  isFinished,
  data,
}): ReactElement => {
  const pageRef = React.createRef<HTMLDivElement>();
  const { cases } = content.drijfveren.casuistiek;

  const onBottomNavigationSubmit = () => {
    onSubmit(pageRef);
  };

  const onCaseChange = (value: string|string[], caseOption: Case): void => {
    const newInputValues = { ...caseInputValues };
    newInputValues[caseOption.index] = value;
    onChange(newInputValues);
  };

  return (
    <div ref={pageRef}>
      <PageHeader title="Casuïstiek" />
      <HTMLContent html={content.drijfveren.casuistiek.intro} />

      {!isLoading ? (
        <Accordion
          id="drijfveren-casuistiek"
          items={
            cases
              .filter((caseOption: any) => caseStudyMap[caseOption.index] !== undefined)
              .map((caseOption: any) => (
                {
                  label: `Case ${caseOption.index}`,
                  element: (
                    <DrijfverenCasuistiekCase
                      data={data}
                      isDisabled={isFinished}
                      caseOption={caseOption}
                      defaultValue={caseStudyMap[caseOption.index].attributes.answer}
                      onChange={(value: string | string[]) => onCaseChange(value, caseOption)}
                    />
                  ),
                }
              ))
          }
        />
      ) : (
        <span>Laden...</span>
      )}
      <BottomNavigation
        isForm
        lockNextButton={nextButtonIsLocked}
        onSubmit={onBottomNavigationSubmit}
      />
    </div>
  );
};

export default DrijfverenCasuistiek;

import React, { FC, ReactElement } from 'react';

import { PageHeader } from '..';
import './LearningGoals.scss';

interface LearningGoalsProps {
  knowledge?: string[];
  insight?: string[];
  skill?: string[];
  className?: string;
}


const LearningGoals: FC<LearningGoalsProps> = ({
  knowledge = [],
  insight = [],
  skill = [],
  className = '',
}): ReactElement => {
  const itemClassName = 'learning-goals__list-item';

  const renderListItems = (items: string[], modifier: 'knowledge' | 'insight' | 'skill') => {
    return items.map(item => (
      <li key={item} className={`${itemClassName} ${itemClassName}--is-${modifier}`}>
        {item}
      </li>
    ));
  };

  return (
    <div className={`learning-goals ${className}`}>
      <PageHeader title="Leerdoelen" />

      <p>
        Na het doorlopen van deze module:
      </p>

      <ul className="learning-goals__list">
        {renderListItems(knowledge, 'knowledge')}
        {renderListItems(insight, 'insight')}
        {renderListItems(skill, 'skill')}
      </ul>
    </div>
  );
}


export default LearningGoals;

import React, { FC, ReactElement } from 'react';

import content from '../../content';
import { ProceedType } from '../../data';
import ProceedTypeInfoCard from './ProceedTypeInfoCard/ProceedTypeInfoCard';

interface ProceedInfoProps {
  proceedType: ProceedType;
}

const ProceedTypeInfo: FC<ProceedInfoProps> = ({ proceedType }): ReactElement => {

  if (proceedType === ProceedType.werkTevredenheid) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.werkTevredenheid}
        definition={content.opbrengsten.opbrengsten.werktevredenheid.definitie}
        diversity={content.opbrengsten.opbrengsten.werktevredenheid.diversiteit}
        accordionItems={[
          { label: 'Inclusief leiderschap', htmlContent: content.opbrengsten.opbrengsten.werktevredenheid.actiePunten[0] },
          { label: 'Informele bijeenkomsten', htmlContent: content.opbrengsten.opbrengsten.werktevredenheid.actiePunten[1] },
          { label: 'Open cultuur', htmlContent: content.opbrengsten.opbrengsten.werktevredenheid.actiePunten[2] },
        ]}
      />
    );
  }

  if (proceedType === ProceedType.identificatieMetDeOrganisatie) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.identificatieMetDeOrganisatie}
        definition={content.opbrengsten.opbrengsten.identificatieMetDeOrganisatie.definitie}
        diversity={content.opbrengsten.opbrengsten.identificatieMetDeOrganisatie.diversiteit}
        accordionItems={[
          { label: 'Inclusief leiderschap', htmlContent: content.opbrengsten.opbrengsten.identificatieMetDeOrganisatie.actiePunten[0] },
          { label: 'Informele bijeenkomsten', htmlContent: content.opbrengsten.opbrengsten.identificatieMetDeOrganisatie.actiePunten[1] },
          { label: 'Open cultuur', htmlContent: content.opbrengsten.opbrengsten.identificatieMetDeOrganisatie.actiePunten[2] },
        ]}
      />
    );
  }

  if (proceedType === ProceedType.ervarenSfeerInDeWerkomgeving) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.ervarenSfeerInDeWerkomgeving}
        definition={content.opbrengsten.opbrengsten.ervarenSfeerInDeWerkomgeving.definitie}
        diversity={content.opbrengsten.opbrengsten.ervarenSfeerInDeWerkomgeving.diversiteit}
        accordionItems={[
          { label: 'Inclusief leiderschap', htmlContent: content.opbrengsten.opbrengsten.ervarenSfeerInDeWerkomgeving.actiePunten[0] },
          { label: 'Informele bijeenkomsten', htmlContent: content.opbrengsten.opbrengsten.ervarenSfeerInDeWerkomgeving.actiePunten[1] },
          { label: 'Open cultuur', htmlContent: content.opbrengsten.opbrengsten.ervarenSfeerInDeWerkomgeving.actiePunten[2] },
        ]}
      />
    );
  }

  if (proceedType === ProceedType.ervarenCarrierePerspectief) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.ervarenCarrierePerspectief}
        definition={content.opbrengsten.opbrengsten.ervarenCarriereperspectief.definitie}
        diversity={content.opbrengsten.opbrengsten.ervarenCarriereperspectief.diversiteit}
        accordionItems={[
          { label: 'Diversiteit aan carrièrepaden', htmlContent: content.opbrengsten.opbrengsten.ervarenCarriereperspectief.actiePunten[0] },
          { label: 'Aandacht voor de culturele meerderheid', htmlContent: content.opbrengsten.opbrengsten.ervarenCarriereperspectief.actiePunten[1] },
          { label: 'Aandacht voor externe mobiliteit', htmlContent: content.opbrengsten.opbrengsten.ervarenCarriereperspectief.actiePunten[2] },
          { label: 'Multicultureel talent in vlootschouwen', htmlContent: content.opbrengsten.opbrengsten.ervarenCarriereperspectief.actiePunten[3] },
        ]}
      />
    );
  }

  if (proceedType === ProceedType.authenticiteit) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.authenticiteit}
        definition={content.opbrengsten.opbrengsten.authenticiteit.definitie}
        diversity={content.opbrengsten.opbrengsten.authenticiteit.diversiteit}
        accordionItems={[
          { label: 'Psychologische veiligheid', htmlContent: content.opbrengsten.opbrengsten.authenticiteit.actiePunten[0] },
          { label: 'Uitnodigen unieke perspectieven', htmlContent: content.opbrengsten.opbrengsten.authenticiteit.actiePunten[1] },
          { label: 'Bewustzijn beeld ‘typische medewerker’', htmlContent: content.opbrengsten.opbrengsten.authenticiteit.actiePunten[2] },
          { label: 'Ruimte voor persoonlijke verhalen', htmlContent: content.opbrengsten.opbrengsten.authenticiteit.actiePunten[3] },
          { label: 'Informele bijeenkomsten', htmlContent: content.opbrengsten.opbrengsten.authenticiteit.actiePunten[4] },
        ]}
      />
    );
  }

  if (proceedType === ProceedType.erkenning) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.erkenning}
        definition={content.opbrengsten.opbrengsten.erkenning.definitie}
        diversity={content.opbrengsten.opbrengsten.erkenning.diversiteit}
        accordionItems={[
          { label: 'Waardering ieders unieke bijdrage', htmlContent: content.opbrengsten.opbrengsten.erkenning.actiePunten[0] },
          { label: 'Open cultuur', htmlContent: content.opbrengsten.opbrengsten.erkenning.actiePunten[1] },
        ]}
      />
    );
  }

  if (proceedType === ProceedType.creativiteit) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.creativiteit}
        definition={content.opbrengsten.opbrengsten.creativiteit.definitie}
        diversity={content.opbrengsten.opbrengsten.creativiteit.diversiteit}
        accordionItems={[
          { label: 'Flexibele werkstructuren', htmlContent: content.opbrengsten.opbrengsten.creativiteit.actiePunten[0] },
          { label: 'Waarborging individuele inbreng', htmlContent: content.opbrengsten.opbrengsten.creativiteit.actiePunten[1] },
          { label: 'Gestructureerde aandacht voor ingebrachte ideeën', htmlContent: content.opbrengsten.opbrengsten.creativiteit.actiePunten[2] },
          { label: 'Concrete doelen', htmlContent: content.opbrengsten.opbrengsten.creativiteit.actiePunten[3] },
        ]}
      />
    );
  }

  if (proceedType === ProceedType.reflectieEnBesluitvorming) {
    return (
      <ProceedTypeInfoCard
        proceedType={ProceedType.reflectieEnBesluitvorming}
        definition={content.opbrengsten.opbrengsten.reflectieEnBesluitvorming.definitie}
        diversity={content.opbrengsten.opbrengsten.reflectieEnBesluitvorming.diversiteit}
        accordionItems={[
          { label: 'Flexibele werkstructuren', htmlContent: content.opbrengsten.opbrengsten.reflectieEnBesluitvorming.actiePunten[0] },
          { label: 'Waarborging individuele inbreng', htmlContent: content.opbrengsten.opbrengsten.reflectieEnBesluitvorming.actiePunten[1] },
          { label: 'Gestructureerde aandacht voor ingebrachte ideeën', htmlContent: content.opbrengsten.opbrengsten.reflectieEnBesluitvorming.actiePunten[2] },
          { label: 'Concrete doelen', htmlContent: content.opbrengsten.opbrengsten.reflectieEnBesluitvorming.actiePunten[3] },
        ]}
      />
    );
  }

  return <p>Opbrengst niet gevonden</p>;
};

export default ProceedTypeInfo;

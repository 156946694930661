import React, { FC, ReactElement } from 'react';
import ConnectedOpbrengstenOpdracht from '../../connectors/ConnectedOpbrengstenOpdracht/ConnectedOpbrengstenOpdracht';

const Opdracht: FC = (): ReactElement => (
  <main className="page">
    <ConnectedOpbrengstenOpdracht />
  </main>
);

export default Opdracht;

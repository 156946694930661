import React, { FC, ReactElement } from 'react';

import './TotalProgressionText.scss';

interface TotalProgressionTextProps {
  progress: number;
  className?: string;
}


const TotalProgressionText: FC<TotalProgressionTextProps> = ({ progress, className = '' }): ReactElement => (
  <div className={`total-progression-text ${className}`}>
    Je hebt al
    <span className="total-progression-text__progress">{`${progress}%`}</span>
    van de tool ingevuld
  </div>
);


export default TotalProgressionText;

import React, { ChangeEvent, FC } from 'react';
import { ProceedType } from '../../../../data';
import getJustifiedScore from '../helpers/getJustifiedScore';

interface OpbrengstenTableRowProps {
  proceedType: ProceedType;
  onImportanceChange: (importance: number | null, proceedType: ProceedType) => void;
  score?: number;
  importance?: number | null;
}

const OpbrengstenTableRow: FC<OpbrengstenTableRowProps> = ({
  proceedType,
  score,
  importance,
  onImportanceChange,
}) => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const justifiedValue = value ? parseInt(value, 0) : null;
    onImportanceChange(justifiedValue !== null ? getJustifiedScore(justifiedValue) : null, proceedType);
  };

  const potentialGain = 5 - (score || 0);
  const usefulness = importance ? (importance * (potentialGain || 1)) : '';

  return (
    <tr>
      <td>
        {proceedType}
      </td>
      <td>
        {score || ''}
      </td>
      <td>
        {potentialGain}
      </td>
      <td>
        <input
          type="number"
          className="input__field"
          value={importance || ''}
          onChange={onInputChange}
        />
      </td>
      <td>
        {usefulness}
      </td>
    </tr>
  );
};

export default OpbrengstenTableRow;

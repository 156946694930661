import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { HTMLContent, PageHeader } from '../../components';

import content from '../../content';

type BibliotheekSuccesfactorenProps = RouteComponentProps;


const BibliotheekSuccesfactoren: FC<BibliotheekSuccesfactorenProps> = (): ReactElement => (
  <main className="page">
    <PageHeader title="Bibliotheek: Succesfactoren" />
    <HTMLContent html={content.bibliotheek.succesfactoren} />
  </main>
);


export default BibliotheekSuccesfactoren;

import React, { FC } from 'react';
import { proceedTypes, WorkUnitEstimate } from '../../connectors/ConnectedOpbrengstenDiagnose/ConnectedOpbrengstenDiagnose';
import { ProceedType } from '../../data';
import Table from '../Table/Table';
import OpbrengstenTableRow from './subcomponents/OpbrengstenTableRow/OpbrengstenTableRow';

interface OpbrengstenTableProps {
  givenEstimates: Partial<Record<ProceedType, WorkUnitEstimate>>;
  givenImportance: Partial<Record<ProceedType, number | null>>;
  onProceedTypeImportanceChange: (importance: number | null, proceedType: ProceedType) => void;
}

const OpbrengstenDiagnose: FC<OpbrengstenTableProps> = ({
  givenEstimates,
  givenImportance,
  onProceedTypeImportanceChange,
}) => (
  <Table heading={['Opbrengst', 'Score', 'Te behalen winst', 'Belang', 'Nut']}>
    <tbody>
      {proceedTypes.map(proceedType => {
        const proceedTypeEstimate = givenEstimates[proceedType];
        const score = proceedTypeEstimate ? proceedTypeEstimate.score : 0;
        const importance = givenImportance[proceedType];

        return (
          <OpbrengstenTableRow
            key={proceedType}
            proceedType={proceedType}
            score={score}
            importance={importance}
            onImportanceChange={onProceedTypeImportanceChange}
          />
        );
      })}
    </tbody>
  </Table>
);

export default OpbrengstenDiagnose;

import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { HTMLContent, PageHeader } from '../../components';

import content from '../../content';

type BibliotheekAlgemeenProps = RouteComponentProps;


const BibliotheekAlgemeen: FC<BibliotheekAlgemeenProps> = (): ReactElement => (
  <main className="page">
    <PageHeader title="Bibliotheek: Algemeen" />
    <HTMLContent html={content.bibliotheek.algemeen} />
  </main>
);


export default BibliotheekAlgemeen;

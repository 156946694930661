import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { HTMLContent, PageHeader } from '../../components';
import { BottomNavigationWithExitButton } from '../../containers';

import content from '../../content';

type StartBibliotheekProps = RouteComponentProps;


const StartBibliotheek: FC<StartBibliotheekProps> = (): ReactElement => (
  <main className="page">
    <PageHeader title="Bibliotheek" />
    <HTMLContent html={content.start.bibliotheek} />
    <BottomNavigationWithExitButton />
  </main>
);


export default StartBibliotheek;

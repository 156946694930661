import {
  put, takeLatest, call, select,
} from 'redux-saga/effects';
import decode from 'jwt-decode';
import {
  postTokenWithCredentials,
  postInviteHash,
  patchUserPassword,
  postUserPasswordReset,
} from '../../helpers/api/authApi';
import { postValidateToken } from '../../helpers/api/resetPasswordApi';
import {
  AUTH_AUTHENTICATE_FAILURE,
  AUTH_AUTHENTICATE_REQUEST,
  AUTH_AUTHENTICATE_SUCCESS,
  AUTH_SAGA_AUTHENTICATE_USER_WITH_CREDENTIALS,
  AUTH_SAGA_AUTHENTICATE_USER_WITH_INVITE_TOKEN,
  AUTH_SAGA_REQUEST_USER_PASSWORD_RESET,
  AUTH_SAGA_LOGOUT,
  AUTH_UNSET_TOKENS,
  AUTH_SAGA_SET_PASSWORD,
  AUTH_SET_PASSWORD_REQUEST,
  AUTH_SET_PASSWORD_FAILURE,
  AUTH_SET_PASSWORD_SUCCESS,
  AUTH_SAGA_AUTHENTICATE_USER_WITH_RESET_TOKEN,
} from './authTypes';
import { getObjProperty } from '../../helpers/tools';

function* authenticateSuccess(response) {
  const { accessToken, refreshToken } = response.data;
  const decoded = decode(accessToken);
  const email = getObjProperty(decoded, 'email') || '';
  const userId = getObjProperty(decoded, 'id') || '';

  yield put(
    {
      type: AUTH_AUTHENTICATE_SUCCESS,
      apiToken: accessToken,
      userName: email,
      userId,
      refreshToken,
    },
  );
}

function* authenticateUserWithCredentials({ credentials, errorCallback }) {
  yield put({ type: AUTH_AUTHENTICATE_REQUEST });

  try {
    const response = yield call(() => postTokenWithCredentials(credentials));
    yield authenticateSuccess(response);
  } catch (error) {
    yield put({ type: AUTH_AUTHENTICATE_FAILURE });
    errorCallback();
  }
}

function* requestUserPasswordReset({ email }) {
  yield put({ type: AUTH_AUTHENTICATE_REQUEST });

  try {
    yield call(() => postUserPasswordReset(email));
  } catch (error) {
    yield put({ type: AUTH_AUTHENTICATE_FAILURE });
  }
}

function* authenticateUserWithInviteToken({ token }) {
  yield put({ type: AUTH_AUTHENTICATE_REQUEST });

  try {
    const response = yield call(() => postInviteHash(token));
    yield authenticateSuccess(response);
  } catch (error) {
    yield put({ type: AUTH_AUTHENTICATE_FAILURE });
  }
}

function* authenticateUserWithResetToken({ token }) {
  yield put({ type: AUTH_AUTHENTICATE_REQUEST });

  try {
    const response = yield call(() => postValidateToken(token));
    yield authenticateSuccess(response);
  } catch (error) {
    yield put({ type: AUTH_AUTHENTICATE_FAILURE });
  }
}

function* setUserPassword({ password, history, errorCallback }) {
  yield put({ type: AUTH_SET_PASSWORD_REQUEST });
  try {
    const state = yield select();
    const { userId } = state.authReducer;
    yield call(() => patchUserPassword(userId, password));
    history.push('/');
    yield put({ type: AUTH_SET_PASSWORD_SUCCESS });
  } catch (error) {
    // FIX: delete this when patchUserPassword works
    // history.push('/');

    yield put({ type: AUTH_SET_PASSWORD_FAILURE });
    errorCallback();

    throw new Error(error);
  }
}

function* unsetTokens() {
  yield put({ type: AUTH_UNSET_TOKENS });
}

export default function* authenticationWatcher() {
  yield takeLatest(AUTH_SAGA_AUTHENTICATE_USER_WITH_CREDENTIALS, authenticateUserWithCredentials);
  yield takeLatest(AUTH_SAGA_AUTHENTICATE_USER_WITH_RESET_TOKEN, authenticateUserWithResetToken);
  yield takeLatest(AUTH_SAGA_AUTHENTICATE_USER_WITH_INVITE_TOKEN, authenticateUserWithInviteToken);
  yield takeLatest(AUTH_SAGA_REQUEST_USER_PASSWORD_RESET, requestUserPasswordReset);
  yield takeLatest(AUTH_SAGA_SET_PASSWORD, setUserPassword);
  yield takeLatest(AUTH_SAGA_LOGOUT, unsetTokens);
}

import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { HTMLContent, PageHeader } from '../../components';

import content from '../../content';

type BibliotheekStartProps = RouteComponentProps;


const BibliotheekStart: FC<BibliotheekStartProps> = (): ReactElement => (
  <main className="page">
    <PageHeader title="Bibliotheek: Start" />
    <HTMLContent html={content.bibliotheek.start} />
  </main>
);


export default BibliotheekStart;

import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { Accordion, HTMLContent, StartTitle } from '../../components';
import content from '../../content';
import { ProceedType } from '../../data';
import ProceedTypeInfo from '../ProceedTypeInfo/ProceedTypeInfo';


const OpbrengstenVerdieping: FC = (): ReactElement => {
  const renderVerdiepingTotPersoneel = () => (
    <>
      <h3>Definitie</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.toegangTotPersoneel.definitie} />
      <h3>Samenhang met diversiteit</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.toegangTotPersoneel.diversiteit} />
      <h3>Tips om effectief in te zetten op toegang tot personeel</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.toegangTotPersoneel.actie} />
      <Accordion
        id="opbrengsten-verdieping-toegang-tot-personeel"
        items={[
          { label: 'Divers team', htmlContent: content.opbrengsten.opbrengsten.toegangTotPersoneel.actiePunten[0] },
          { label: 'Strategie voor werving selectie', htmlContent: content.opbrengsten.opbrengsten.toegangTotPersoneel.actiePunten[1] },
          { label: 'Profielschets', htmlContent: content.opbrengsten.opbrengsten.toegangTotPersoneel.actiePunten[2] },
          { label: 'Indicatoren van talent', htmlContent: content.opbrengsten.opbrengsten.toegangTotPersoneel.actiePunten[3] },
        ]}
      />
    </>
  );

  const renderVerdiepingTotKlant = () => (
    <>
      <h3>Definitie</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.toegangTotKlant.definitie} />
      <h3>Samenhang met diversiteit</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.toegangTotKlant.diversiteit} />
      <h3>Tips om effectief in te zetten op toegang tot personeel</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.toegangTotKlant.actie} />
      <Accordion
        id="opbrengsten-verdieping-toegang-tot-klant"
        items={[
          { label: 'Divers team', htmlContent: content.opbrengsten.opbrengsten.toegangTotKlant.actiePunten[0] },
          { label: 'Analyse klantengroepen', htmlContent: content.opbrengsten.opbrengsten.toegangTotKlant.actiePunten[1] },
          { label: 'Strategie voor contact', htmlContent: content.opbrengsten.opbrengsten.toegangTotKlant.actiePunten[2] },
          { label: 'Communicatiestrategie', htmlContent: content.opbrengsten.opbrengsten.toegangTotKlant.actiePunten[3] },
          { label: 'Optimaal gebruik van diversiteit als resource', htmlContent: content.opbrengsten.opbrengsten.toegangTotKlant.actiePunten[4] },
        ]}
      />
    </>
  );

  const renderInnovatieEnWendbaarheid = () => (
    <>
      <h3>Definitie</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.innovatieEnWendbaarheid.definitie} />
      <h3>Samenhang met diversiteit</h3>
      <HTMLContent html={content.opbrengsten.opbrengsten.innovatieEnWendbaarheid.diversiteit} />
      <h3>Tips om effectief in te zetten op innovatie en wendbaarheid</h3>
      <Accordion
        id="opbrengsten-innovatie-en-wendbaarheid"
        items={[
          { label: 'Flexibele werkstructuren', htmlContent: content.opbrengsten.opbrengsten.innovatieEnWendbaarheid.actiePunten[0] },
          { label: 'Waarborging individuele inbreng', htmlContent: content.opbrengsten.opbrengsten.innovatieEnWendbaarheid.actiePunten[1] },
          { label: 'Gestructureerd aandacht voor ingebrachte ideeën', htmlContent: content.opbrengsten.opbrengsten.innovatieEnWendbaarheid.actiePunten[2] },
          { label: 'Concrete doelen', htmlContent: content.opbrengsten.opbrengsten.innovatieEnWendbaarheid.actiePunten[3] },
        ]}
      />
    </>
  );

  return (
    <>
      <StartTitle text="Verdieping per opbrengst" />
      <h2>Organisatieniveau</h2>
      <Accordion
        id="opbrengsten-verdieping-verdieping-per-opbrengst"
        items={[
          { label: 'Toegang tot personeel', element: renderVerdiepingTotPersoneel() },
          { label: 'Toegang tot klant', element: renderVerdiepingTotKlant() },
          { label: 'Innovatie en wendbaarheid', element: renderInnovatieEnWendbaarheid() },
        ]}
      />

      <h2>Medewerker- en teamniveau</h2>
      <Accordion
        id="opbrengsten-verdieping-medewerker-en-teamniveau"
        items={[
          { label: ProceedType.werkTevredenheid, element: <ProceedTypeInfo proceedType={ProceedType.werkTevredenheid} /> },
          { label: ProceedType.identificatieMetDeOrganisatie, element: <ProceedTypeInfo proceedType={ProceedType.identificatieMetDeOrganisatie} /> },
          { label: ProceedType.ervarenSfeerInDeWerkomgeving, element: <ProceedTypeInfo proceedType={ProceedType.ervarenSfeerInDeWerkomgeving} /> },
          { label: ProceedType.ervarenCarrierePerspectief, element: <ProceedTypeInfo proceedType={ProceedType.ervarenCarrierePerspectief} /> },
          { label: ProceedType.authenticiteit, element: <ProceedTypeInfo proceedType={ProceedType.authenticiteit} /> },
          { label: ProceedType.erkenning, element: <ProceedTypeInfo proceedType={ProceedType.erkenning} /> },
          { label: ProceedType.creativiteit, element: <ProceedTypeInfo proceedType={ProceedType.creativiteit} /> },
          { label: ProceedType.reflectieEnBesluitvorming, element: <ProceedTypeInfo proceedType={ProceedType.reflectieEnBesluitvorming} /> },
        ]}
      />

      <BottomNavigation />
    </>
  );
};

export default OpbrengstenVerdieping;

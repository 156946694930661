import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';

const IconClock: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 18 18" className={className}>
    <path d="M9.00002 15.6667C10.7681 15.6667 12.4638 14.9643 13.7141 13.7141C14.9643 12.4638 15.6667 10.7681 15.6667 9.00002C15.6667 7.23191 14.9643 5.53622 13.7141 4.28598C12.4638 3.03573 10.7681 2.33335 9.00002 2.33335C7.23191 2.33335 5.53622 3.03573 4.28598 4.28598C3.03573 5.53622 2.33335 7.23191 2.33335 9.00002C2.33335 10.7681 3.03573 12.4638 4.28598 13.7141C5.53622 14.9643 7.23191 15.6667 9.00002 15.6667ZM9.00002 0.666687C10.0944 0.666687 11.178 0.882235 12.189 1.30102C13.2001 1.71981 14.1188 2.33364 14.8926 3.10746C15.6664 3.88129 16.2802 4.79994 16.699 5.81099C17.1178 6.82204 17.3334 7.90567 17.3334 9.00002C17.3334 11.2102 16.4554 13.3298 14.8926 14.8926C13.3298 16.4554 11.2102 17.3334 9.00002 17.3334C4.39169 17.3334 0.666687 13.5834 0.666687 9.00002C0.666687 6.78988 1.54466 4.67027 3.10746 3.10746C4.67027 1.54466 6.78988 0.666687 9.00002 0.666687ZM9.41669 4.83335V9.20835L13.1667 11.4334L12.5417 12.4584L8.16669 9.83335V4.83335H9.41669Z" />
  </svg>
);


export default IconClock;

import { SummaryType } from '../ModuleSummary';

export default function getSummaryTypeTranslation(type: SummaryType): string {
  switch (type) {
    case SummaryType.theory:
      return 'Diagnose';
    case SummaryType.diagnose:
      return 'Theorie';
    case SummaryType.practice:
      return 'Praktijk';
    default:
      return '';
  }
}
import { AUTH_UNSET_TOKENS } from '../auth/authTypes';
import {
  FurthestLocation,
  Motivations,
  USER_SET_FURTHEST_LOCATION,
  USER_SET_MOST_IMPORTANT_PROCEED,
  USER_SET_MOST_IMPORTANT_PROCEED_RATING,
  USER_SET_MOTIVATIONS,
  USER_SET_ORGANIZATION_PROCEED,
  USER_SET_ORGANIZATION_PROCEED_RATING,
  USER_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE,
  USER_SET_SUCCESS_FACTOR_LEADERSHIP,
  USER_SET_TOP_3_PROCEEDS_DEFINITIVE,
  USER_SET_TOP_3_PROCEEDS_INITIAL,
  USER_SET_TOP_3_PROCEEDS_INITIAL_RATING,
  USER_SET_WORKPLACE,
  UserReducerTypes,
  Workplace,
} from './userTypes';

import { getCookieValue, removeCookie, setStrictCookie } from '../../helpers/tools';

export type UserState = Readonly<{
  loading: boolean;
  furthestLocation: FurthestLocation | null;
  motivations: Motivations | null;
  mostImportantProceed: string;
  mostImportantProceedRating: number;
  organizationProceed: string;
  organizationProceedRating: number;
  successFactorLeaderShip: number;
  successFactorCulturalResilience: number;
  top3ProceedsDefinitive: string[];
  top3ProceedsInitial: string[];
  top3ProceedsInitialRating: { [key: string]: number };
  workplace: Workplace;
}>;

const initialState: UserState = {
  loading: false,
  furthestLocation: getCookieValue('userFurthestLocation')
    ? JSON.parse(getCookieValue('userFurthestLocation'))
    : { module: 'start', page: 'overview-tool' },
  mostImportantProceed: getCookieValue('userMostImportantProceed') || '',
  mostImportantProceedRating: getCookieValue('userMostImportantProceedRating')
    ? Number(getCookieValue('userMostImportantProceedRating'))
    : 0,
  motivations: getCookieValue('userMotivations')
    // @ts-ignore
    ? JSON.parse(getCookieValue('userMotivations'))
    : {
      kwaliteit: 0, rechtvaardigheid: 0, toegang: 0, leren: 0,
    },
  organizationProceed: getCookieValue('userOrganizationProceed') || '',
  organizationProceedRating: getCookieValue('userOrganizationProceedRating')
    ? Number(getCookieValue('userOrganizationProceedRating'))
    : 0,
  successFactorLeaderShip: getCookieValue('userSuccessFactorLeaderShip')
    ? Number(getCookieValue('userSuccessFactorLeaderShip'))
    : 0,
  successFactorCulturalResilience: getCookieValue('userSuccessFactorCulturalResilience')
    ? Number(getCookieValue('userSuccessFactorCulturalResilience'))
    : 0,
  top3ProceedsDefinitive: getCookieValue('userTop3ProceedsDefinitive')
    ? getCookieValue('userTop3ProceedsDefinitive').split(',')
    : [''],
  top3ProceedsInitial: getCookieValue('userTop3ProceedsInitial')
    ? getCookieValue('userTop3ProceedsInitial').split(',')
    : [''],
  top3ProceedsInitialRating: getCookieValue('userTop3ProceedsInitialRating')
    ? JSON.parse(getCookieValue('userTop3ProceedsInitialRating'))
    : { empty: 0 },
  workplace: getCookieValue('userWorkplace')
    ? JSON.parse(getCookieValue('userWorkplace'))
    : { organization: '-', subOrganization: '-', workUnit: '-' },
};

const userReducer = (state = initialState, action: UserReducerTypes): UserState => {
  switch (action.type) {
    case AUTH_UNSET_TOKENS:
      removeCookie('userFurthestLocation');
      removeCookie('userMostImportantProceed');
      removeCookie('userMostImportantProceedRating');
      removeCookie('userMotivations');
      removeCookie('userOrganizationProceed');
      removeCookie('userOrganizationProceedRating');
      removeCookie('userSuccessFactorLeaderShip');
      removeCookie('userSuccessFactorCulturalResilience');
      removeCookie('userTop3ProceedsDefinitive');
      removeCookie('userTop3ProceedsInitial');
      removeCookie('userTop3ProceedsInitialRating');
      removeCookie('userWorkplace');

      return {
        ...state,
        motivations: {
          kwaliteit: 0, rechtvaardigheid: 0, toegang: 0, leren: 0,
        },
      };
    case USER_SET_FURTHEST_LOCATION:
      setStrictCookie('userFurthestLocation', JSON.stringify(action.furthestLocation));
      return {
        ...state,
        furthestLocation: action.furthestLocation,
      };
    case USER_SET_MOST_IMPORTANT_PROCEED:
      setStrictCookie('userMostImportantProceed', action.mostImportantProceed);
      return {
        ...state,
        mostImportantProceed: action.mostImportantProceed,
      };
    case USER_SET_MOST_IMPORTANT_PROCEED_RATING:
      setStrictCookie('userMostImportantProceedRating', String(action.mostImportantProceedRating));
      return {
        ...state,
        mostImportantProceedRating: action.mostImportantProceedRating,
      };
    case USER_SET_MOTIVATIONS:
      setStrictCookie('userMotivations', JSON.stringify(action.motivations));
      return {
        ...state,
        motivations: action.motivations,
      };
    case USER_SET_ORGANIZATION_PROCEED:
      setStrictCookie('userOrganizationProceed', action.organizationProceed);
      return {
        ...state,
        organizationProceed: action.organizationProceed,
      };
    case USER_SET_ORGANIZATION_PROCEED_RATING:
      setStrictCookie('userOrganizationProceedRating', String(action.organizationProceedRating));
      return {
        ...state,
        organizationProceedRating: action.organizationProceedRating,
      };
    case USER_SET_SUCCESS_FACTOR_LEADERSHIP:
      setStrictCookie('userSuccessFactorLeaderShip', String(action.successFactorLeaderShip));
      return {
        ...state,
        successFactorLeaderShip: action.successFactorLeaderShip,
      };
    case USER_SET_SUCCESS_FACTOR_CULTURAL_RESILIENCE:
      setStrictCookie('userSuccessFactorCulturalResilience', String(action.successFactorCulturalResilience));
      return {
        ...state,
        successFactorCulturalResilience: action.successFactorCulturalResilience,
      };
    case USER_SET_TOP_3_PROCEEDS_DEFINITIVE:
      setStrictCookie('userTop3ProceedsDefinitive', action.top3ProceedsDefinitive.join(','));
      return {
        ...state,
        top3ProceedsDefinitive: action.top3ProceedsDefinitive,
      };
    case USER_SET_TOP_3_PROCEEDS_INITIAL:
      setStrictCookie('userTop3ProceedsInitial', action.top3ProceedsInitial.join(','));
      return {
        ...state,
        top3ProceedsInitial: action.top3ProceedsInitial,
      };
    case USER_SET_TOP_3_PROCEEDS_INITIAL_RATING:
      setStrictCookie('userTop3ProceedsInitialRating', JSON.stringify(action.top3ProceedsInitialRating));
      return {
        ...state,
        top3ProceedsInitialRating: action.top3ProceedsInitialRating,
      };
    case USER_SET_WORKPLACE:
      setStrictCookie('userWorkplace', JSON.stringify(action.workplace));
      return {
        ...state,
        workplace: action.workplace,
      };
    default: {
      return state;
    }
  }
};

export default userReducer;

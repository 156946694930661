import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconMinus: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 283.5 40.5" className={`icon ${className}`}>
    <polygon points="283.5,40.5 0,40.5 0,0 283.5,0" />
  </svg>
);


export default IconMinus;

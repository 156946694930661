import React, { FC, ReactElement } from 'react';

import { HTMLContent } from '../../components';
import { ModuleEstimation } from '../index';
import ModuleSummaryItem from './subcomponents/ModuleSummaryItem/ModuleSummaryItem';

import './ModuleSummary.scss';

interface ModuleSummaryProps {
  intro: string;
  diagnose?: string;
  theory?: string;
  practice: string | string[];
  outro?: string;
  estimationInMinutes?: number;
  estimationInHours?: number;
  className?: string;
}

export enum SummaryType {
  diagnose = 'diagnose',
  theory = 'theory',
  practice = 'practice',
}


const ModuleSummary: FC<ModuleSummaryProps> = ({
  intro,
  diagnose,
  theory,
  practice,
  outro,
  estimationInHours,
  estimationInMinutes,
  className = '',
}): ReactElement => {
  const itemClassName = 'module-summary__list-item';

  const renderListItems = (item: string, type: SummaryType, showType = true) => (
    <ModuleSummaryItem
      className={itemClassName}
      key={item}
      type={type}
      showType={showType}
      content={item}
    />
  );

  return (
    <div className={`module-summary ${className}`}>
      <HTMLContent html={intro} />

      <ul className="module-summary__list">
        {diagnose && renderListItems(diagnose, SummaryType.diagnose)}
        {theory && renderListItems(theory, SummaryType.theory)}
        {Array.isArray(practice) ? (
          <ModuleSummaryItem
            showType
            className={itemClassName}
            type={SummaryType.practice}
          >
            <ul>
              { practice.map(item => renderListItems(item, SummaryType.practice, false))}
            </ul>
          </ModuleSummaryItem>
        ) : (
          renderListItems(practice, SummaryType.practice)
        )}
      </ul>

      { outro && <HTMLContent html={outro} /> }
      { (estimationInMinutes || estimationInHours) && <ModuleEstimation minutes={estimationInMinutes} hours={estimationInHours} /> }
    </div>
  );
};

export default ModuleSummary;

import React, {
  FC,
  FormEvent,
  ReactElement,
  useState,
} from 'react';

import { AnswerBubble, Input } from '../../../components';
import '../QuizQuestion.scss';

import { QuizQuestionType } from '../QuizQuestion';


const QuizInput: FC<QuizQuestionType> = ({
  questionNumber,
  defaultValue,
  name,
  question,
  answer,
  answerImages,
  disabled,
  onChange,
  onSubmit,
  className = '',
}): ReactElement => {
  const [questionInput, setQuestionInput] = useState(defaultValue || '');
  const [hasSubmit, setSubmit] = useState(false);

  const handleChange = (event: any): void => {
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setSubmit(true);
    if (onSubmit) {
      onSubmit(questionInput);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`quiz-question ${className}`}>
      {questionNumber && <legend className="quiz-question__chapeau">{`Vraag ${questionNumber}`}</legend>}

      <Input
        label={question}
        name={name}
        defaultValue={defaultValue}
        disabled={disabled || hasSubmit}
        onChange={setQuestionInput}
      />
      {(answer && hasSubmit) && <AnswerBubble htmlContent={answer} images={answerImages} />}

      {(answer && !hasSubmit) && (
        <input
          type="submit"
          id={question}
          name={name}
          value={answer ? 'Bekijk antwoord' : 'Verstuur'}
          disabled={!questionInput}
          onChange={handleChange}
          className="quiz-question__submit-button"
        />
      )}
    </form>
  );
};


export default QuizInput;

import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconVisibilityOn: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 283.7 193.4" className={`icon ${className}`}>
    <circle cx="141.8" cy="96.7" r="38.7" />
    <path d="M141.8,0C77.4,0,22.3,40.1,0,96.7c22.3,56.6,77.4,96.7,141.8,96.7s119.5-40.1,141.8-96.7C261.4,40.1,206.3,0,141.8,0z M141.8,161.2c-35.6,0-64.5-28.9-64.5-64.5c0-35.6,28.9-64.5,64.5-64.5s64.5,28.9,64.5,64.5C206.3,132.3,177.4,161.2,141.8,161.2z" />
  </svg>
);


export default IconVisibilityOn;

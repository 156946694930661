import axios from 'axios';

interface ValidateTokenResponse extends Response {
  data: {
    accessToken: string;
    refreshToken: string;
    tokenType: 'bearer';
  };
}

export function postValidateToken(resetToken: string): Promise<ValidateTokenResponse> {
  return axios.post(`${process.env.REACT_APP_API_URL}/password/validate-token`, {
    resetToken,
  });
}

// eslint-disable @typescript-eslint/explicit-function-return-type

import axios from 'axios';

export function postTokenWithCredentials(credentials) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, credentials);
}

export function postInviteHash(inviteHash) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/invite-hash`, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    invite_hash: inviteHash,
  });
}

export function patchUserPassword(userId, password) {
  const body = {
    type: 'users',
    id: userId.toString(),
    attributes: {
      password,
    },
  };

  return axios.patch(`${process.env.REACT_APP_API_URL}/users/${userId}`, { data: body });
}

export function postUserPasswordReset(email) {
  return axios.post(`${process.env.REACT_APP_API_URL}/password/request-reset?email=${email}`);
}


export function refreshAccessToken(refreshToken) {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    refreshToken,
  });
}

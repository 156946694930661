import React, { FC, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';

import { OpbrengstenStart } from '../../containers';

type OpbrengstenStartProps = RouteComponentProps;


const Start: FC<OpbrengstenStartProps> = (): ReactElement => (
  <main className="page">
    <OpbrengstenStart />
  </main>
);


export default Start;

import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import content from './content';
import { getInviteHashFromUrlAndAuthenticate } from './helpers/tools';
import { setTranslations } from './helpers/translations';
import AuthInterceptor from './interceptors/AuthInterceptor';
import { configureStore } from './redux/store';


import './styles/global.scss';

// tslint:disable-next-line:no-var-requires
const packageJson = require('../package.json'); // eslint-disable-line

setTranslations(content);

if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `${packageJson.name}@${packageJson.version}`,
      environment: process.env.REACT_APP_APP_ENV,
      integrations: [new CaptureConsole({
        levels: ['error'],
      })],
    });
  }
}

configureStore({}).then((store): void => {
  const authInterceptor = new AuthInterceptor(store);
  authInterceptor.setInterceptors();
  getInviteHashFromUrlAndAuthenticate(store);

  ReactDOM.hydrate(
    <ReduxProvider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </ReduxProvider>,
    document.getElementById('root'),
  );
});

import React, { FC, ReactElement } from 'react';
import { SvgIconProps } from '../Icon';
import '../Icon.scss';


const IconChevronLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 175 283.5" className={`icon ${className}`}>
    <polygon points="175,249.9 66.9,141.7 175,33.3 141.7,0 0,141.7 141.7,283.5" />
  </svg>
);


export default IconChevronLeft;

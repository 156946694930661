import {
  PAGES_SET_PAGE_DATA,
  PAGES_SET_PAGE_DATA_SUCCESS, PAGES_SET_PAGE_FINISHED, PagesReducerTypes,
} from './pagesTypes';

export type PagesState = Readonly<{
  loading: boolean;
  pages: {
    // 'start-overview-tool': any;
    'start-culturele-diversiteit': any;
    // 'start-mijn-organisatie': any;
    // 'start-voortgang': any;
    // 'start-bibliotheek': any;
    // 'drijfveren-introductie': any;
    'drijfveren-zelfdiagnose': any;
    // 'drijfveren-mijn-drijfveer': any;
    // 'drijfveren-mijn-organisatie': any;
    'drijfveren-casuistiek': any;
    'drijfveren-leerdoelen': any;
    // 'drijfveren-bibliotheek': any;
    'succesfactoren-introductie': any;
    // 'succesfactoren-uitleg-en-score': any;
    'succesfactoren-leerdoelen': any;
    // 'succesfactoren-bibliotheek': any;
    // 'opbrengsten-introductie': any;
    'opbrengsten-opbrengsten': any;
    'opbrengsten-score': any;
    'opbrengsten-leerdoelen': any;
    // 'opbrengsten-bibliotheek': any;
    // 'aan-de-slag-overview': any;
    // 'aan-de-slag-good-practices': any;
    // 'aan-de-slag-actieplan': any;
    'aan-de-slag-opbrengst-1': any;
    'aan-de-slag-opbrengst-2': any;
    'aan-de-slag-opbrengst-3': any;
    'aan-de-slag-afronding': any;
  };
}>;

const initialState: PagesState = {
  loading: false,
  pages: {
    // 'start-overview-tool': {},
    'start-culturele-diversiteit': {},
    // 'start-mijn-organisatie': {},
    // 'start-voortgang': {},
    // 'start-bibliotheek': {},
    // 'drijfveren-introductie': {},
    'drijfveren-zelfdiagnose': {},
    // 'drijfveren-mijn-drijfveer': {},
    // 'drijfveren-mijn-organisatie': {},
    'drijfveren-casuistiek': {},
    'drijfveren-leerdoelen': {},
    // 'drijfveren-bibliotheek': {},
    'succesfactoren-introductie': {},
    // 'succesfactoren-uitleg-en-score': {},
    'succesfactoren-leerdoelen': {},
    // 'succesfactoren-bibliotheek': {},
    // 'opbrengsten-introductie': {},
    'opbrengsten-opbrengsten': {},
    'opbrengsten-score': {},
    'opbrengsten-leerdoelen': {},
    // 'opbrengsten-bibliotheek': {},
    // 'aan-de-slag-overview': {},
    // 'aan-de-slag-good-practices': {},
    // 'aan-de-slag-actieplan': {},
    'aan-de-slag-opbrengst-1': {},
    'aan-de-slag-opbrengst-2': {},
    'aan-de-slag-opbrengst-3': {},
    'aan-de-slag-afronding': {},
  },
};

const appReducer = (state = initialState, action: PagesReducerTypes) => {
  switch (action.type) {
    case PAGES_SET_PAGE_DATA:
    case PAGES_SET_PAGE_FINISHED:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.page]: action.data,
        },
      };

    case PAGES_SET_PAGE_DATA_SUCCESS:
      return { ...state };
    default: {
      return state;
    }
  }
};

export default appReducer;

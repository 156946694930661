import axios from 'axios';
import { Store } from 'redux';
// import { refreshAccessToken } from '../helpers/api/authApi';
import { getCookieValue, setStrictCookie } from '../helpers/tools';
import {
  logoutUser,
  // setApiToken,
  // setRefreshToken,
} from '../redux/auth/authActions';

export default class AuthInterceptor {
  private readonly store: Store;

  constructor(store: Store) {
    this.store = store;
  }

  public setInterceptors = async (): Promise<void> => {
    axios.interceptors.request.use(
      (requestConfig) => {
        requestConfig.headers.authorization = `Bearer ${this.getApiToken()}`;

        return requestConfig;
      },
      err => Promise.reject(err),
    );

    const interceptor = axios.interceptors.response.use(
      response => response,
      (error) => {
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        axios.interceptors.response.eject(interceptor);

        this.logout();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        return Promise.reject(error);
      },
    );
  };

  private getApiToken = (): ReturnType<typeof getCookieValue> => getCookieValue('apiToken');

  // private getRefreshToken = (): ReturnType<typeof getCookieValue> => getCookieValue('apiRefreshToken');

  private logout = (): void => {
    this.store.dispatch(logoutUser());
    setStrictCookie('authenticated', 'false');
  }
}

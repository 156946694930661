import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AanDeSlagVoorbereiding } from '../../containers';
import { ProceedType } from '../../data';
import useQuestion from '../../helpers/useQuestion';


const ConnectedAanDeSlagVoorbereiding: FC = (): ReactElement => {
  const [chosenProceedType, setChosenProceedType] = useState<ProceedType | null>(null);

  const apiChosenProceedType = useQuestion(9, '/opbrengsten/diagnose');

  useEffect(() => {
    if (apiChosenProceedType && apiChosenProceedType.attributes) {
      setChosenProceedType(apiChosenProceedType.attributes.answer as ProceedType);
    }
  }, [apiChosenProceedType]);

  if (!chosenProceedType) {
    return (
      <span>Laden...</span>
    );
  }

  return (
    <AanDeSlagVoorbereiding chosenProceedType={chosenProceedType} />
  );
};

export default ConnectedAanDeSlagVoorbereiding;

import React, { FC, ReactElement } from 'react';
import { convertStringToDash } from '../../helpers/tools';

import {
  Checkbox as QuizCheckbox,
  Input as QuizInput,
  Radio as QuizRadio,
  Textarea as QuizTextarea,
} from './subcomponents';

import './QuizQuestion.scss';

export interface QuizQuestionType {
  data?: any;
  questionNumber?: number;
  defaultValue?: string;
  defaultValues?: string | string[];
  name?: string;
  question: string;
  answer?: string;
  answerImages?: string[];
  options?: string[];
  onChange?: (value: any) => void;
  onSubmit?: (value: string|string[]) => void;
  className?: string;
  disabled?: boolean;
}

interface QuizQuestionProps extends QuizQuestionType {
  type: 'checkbox' | 'input' | 'radio' | 'textarea';
}


const QuizQuestion: FC<QuizQuestionProps> = ({
  type,
  data,
  questionNumber,
  defaultValue,
  question,
  answer,
  answerImages,
  options,
  onChange,
  onSubmit,
  className = '',
  disabled = false,
  ...rest
}): ReactElement => {
  const name = rest.name || convertStringToDash(question);
  const readDefaultValue = defaultValue || (data ? data[name] : undefined);

  switch (type) {
    case 'checkbox':
      return (
        <QuizCheckbox
          key={name}
          questionNumber={questionNumber}
          defaultValues={typeof readDefaultValue === 'string' ? [readDefaultValue] : readDefaultValue}
          name={name}
          question={question}
          options={options}
          answer={answer}
          answerImages={answerImages}
          onChange={onChange}
          onSubmit={onSubmit}
          className={className}
          disabled={disabled}
        />
      );
    case 'input':
      return (
        <QuizInput
          key={name}
          questionNumber={questionNumber}
          defaultValue={readDefaultValue}
          name={name}
          question={question}
          answer={answer}
          answerImages={answerImages}
          onChange={onChange}
          onSubmit={onSubmit}
          className={className}
          disabled={disabled}
        />
      );
    case 'radio':
      return (
        <QuizRadio
          key={name}
          questionNumber={questionNumber}
          defaultValue={readDefaultValue}
          name={name}
          question={question}
          options={options}
          answer={answer}
          answerImages={answerImages}
          onChange={onChange}
          onSubmit={onSubmit}
          className={className}
          disabled={disabled}
        />
      );
    case 'textarea':
    default:
      return (
        <QuizTextarea
          key={name}
          questionNumber={questionNumber}
          defaultValue={readDefaultValue}
          name={name}
          question={question}
          answer={answer}
          answerImages={answerImages}
          onChange={onChange}
          onSubmit={onSubmit}
          className={className}
          disabled={disabled}
        />
      );
  }
};


export default QuizQuestion;

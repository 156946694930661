import React, { FC, ReactElement } from 'react';

interface PaginationSwitcherProps {
  activePage: number;
  children: ReactElement[];
}


const PaginationSwitcher: FC<PaginationSwitcherProps> = ({ activePage, children }): ReactElement => (
  <div>{children[activePage - 1]}</div>
);


export default PaginationSwitcher;

import React, { FC, ReactElement } from 'react';
import './Icon.scss';

import {
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconClock,
  IconClose,
  IconDot,
  IconLogout,
  IconMinus,
  IconPlus,
  IconVisibilityOff,
  IconVisibilityOn,
} from './set';

interface IconSet {
  [key: string]: FC<SvgIconProps>;
}

export interface SvgIconProps {
  id?: string;
  className?: string;
}

interface IconProps extends SvgIconProps {
  name: string;
}

const icons: IconSet = {
  check: IconCheck,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  clock: IconClock,
  close: IconClose,
  dot: IconDot,
  logout: IconLogout,
  minus: IconMinus,
  plus: IconPlus,
  'visibility-off': IconVisibilityOff,
  'visibility-on': IconVisibilityOn,
};


const Icon: FC<IconProps> = ({ name, className = '' }): ReactElement | null => {
  const IconComponent = icons[name] ? icons[name] : null;

  return IconComponent ? <IconComponent className={className} /> : null;
};


export default Icon;

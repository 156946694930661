import React, {
  ChangeEvent,
  createRef,
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import './Textarea.scss';

export interface TextareaProps {
  label?: string;
  tabIndex?: 0 | -1;
  defaultValue?: string;
  name?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  className?: string;
}


const Textarea: FC<TextareaProps> = ({
  label,
  tabIndex,
  defaultValue,
  name,
  disabled,
  onChange,
  className = '',
}): ReactElement => {
  const [mayUpdate, setMayUpdate] = useState(true);

  const ref = createRef<HTMLTextAreaElement>();
  useEffect((): void => {
    if (onChange && ref.current && mayUpdate) {
      setMayUpdate(false);
      onChange(ref.current.value);
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  return (
    <label className="textarea">
      {label && <span className="textarea__label">{label}</span>}
      <textarea
        ref={ref}
        tabIndex={tabIndex}
        defaultValue={defaultValue}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        className={`textarea__field ${className}`}
      />
    </label>
  );
};


export default Textarea;

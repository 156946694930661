import React, { FC, ReactElement } from 'react';

import { BottomNavigation } from '..';
import { HTMLContent, LearningGoals, StartTitle } from '../../components';
import content from '../../content';


const OpbrengstenIntroductie: FC = (): ReactElement => (
  <>
    <StartTitle text="Introductie" />
    <HTMLContent html={content.opbrengsten.introductie.intro} />
    <LearningGoals knowledge={content.opbrengsten.introductie.kennis} insight={content.opbrengsten.introductie.inzicht} />
    <BottomNavigation />
  </>
);

export default OpbrengstenIntroductie;

import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import useRouter from 'use-react-router';

import { Button, Pagination } from '../../components';
import { timeout } from '../../helpers/tools';
import { dynamicRouteModules, getRouteModuleIndex, getRoutePageIndex } from '../../Routes';
import getNextButtonText from './helpers/getNextButtonText';

import './BottomNavigation.scss';

interface BottomNavigationProps extends RouteComponentProps {
  hidePrevButton?: boolean;
  hideNextButton?: boolean;
  lockPrevButton?: boolean;
  lockNextButton?: boolean;
  isForm?: boolean;
  isLastPageOfModule?: boolean;
  paginationSize?: number;
  unblockedPagination?: boolean;
  onPaginationChange?: (paginationPage: number) => void;
  onBeforePaginationChange?: (paginationPage: number) => void;
  onSubmit?: () => void;
  className?: string;
}

const BottomNavigation: FC<BottomNavigationProps> = ({
  location,
  hidePrevButton,
  hideNextButton,
  lockPrevButton,
  lockNextButton,
  isForm,
  paginationSize,
  isLastPageOfModule = false,
  unblockedPagination,
  onPaginationChange,
  onBeforePaginationChange,
  onSubmit,
  className,
}): ReactElement => {
  const history = useHistory();
  const router = useRouter();
  const qs = queryString.parse(router.location.search);
  const cleanLocation = location.pathname.split('/').filter((slug: string) => slug.length > 0);
  const activeModule = cleanLocation[0] ? getRouteModuleIndex(cleanLocation[0]) : 0;
  const activePage = cleanLocation[1] ? getRoutePageIndex(activeModule, cleanLocation[1]) : 0;

  const [activePaginationPage, setPaginationPage] = useState<number>(parseInt((qs.page as string|undefined) || '1', 10));

  const shouldSubmitNext = (paginationSize && isForm) ? activePaginationPage === paginationSize : isForm;
  const nextButtonText = getNextButtonText(
    isLastPageOfModule,
    activePaginationPage,
    paginationSize,
  );

  useEffect(() => {
    router.history.push({
      pathname: router.location.pathname,
      search: queryString.stringify({
        ...qs,
        page: activePaginationPage,
      }),
    });
  }, [activePaginationPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePaginationChange = async (paginationPage: number): Promise<void> => {
    if (paginationPage === activePaginationPage) {
      return;
    }

    if (onBeforePaginationChange) {
      onBeforePaginationChange(paginationPage);
    }

    if (onPaginationChange) {
      await timeout(1);
      setPaginationPage(paginationPage);
      onPaginationChange(paginationPage);
    }
  };

  useEffect(() => {
    const paginationPage = parseInt((qs.page as string|undefined) || '1', 10);

    if (onBeforePaginationChange) {
      onBeforePaginationChange(paginationPage);
    }

    if (onPaginationChange) {
      timeout(1).then(() => {
        setPaginationPage(paginationPage);
        onPaginationChange(paginationPage);
      });
    }
  }, [qs.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNextClick = async (): Promise<void> => {
    if (paginationSize && activePaginationPage < paginationSize) {
      handlePaginationChange(activePaginationPage + 1);
    } else {
      if (onBeforePaginationChange && onSubmit) {
        onBeforePaginationChange(1);
      }

      await timeout(1);
      if (shouldSubmitNext && onSubmit) {
        onSubmit();
      }

      if (dynamicRouteModules[activeModule]) {
        if (dynamicRouteModules[activeModule].pages[activePage + 1]) {
          history.push(`/${dynamicRouteModules[activeModule].slug}/${dynamicRouteModules[activeModule].pages[activePage + 1].slug}`);
        } else if (dynamicRouteModules[activeModule + 1]) {
          history.push(`/${dynamicRouteModules[activeModule + 1].slug}/${dynamicRouteModules[activeModule + 1].pages[0].slug}`);
        }
      }
    }
  };

  const handlePrevClick = (): void => {
    if (paginationSize && activePaginationPage > 1) {
      handlePaginationChange(activePaginationPage - 1);
    } else if (dynamicRouteModules[activeModule]) {
      if (dynamicRouteModules[activeModule].pages[activePage - 1]) {
        history.push(`/${dynamicRouteModules[activeModule].slug}/${dynamicRouteModules[activeModule].pages[activePage - 1].slug}`);
      } else if (dynamicRouteModules[activeModule - 1]) {
        history.push(`/${dynamicRouteModules[activeModule - 1].slug}/${dynamicRouteModules[activeModule - 1].pages[0].slug}`);
      }
    }
  };

  return (
    <div className={`bottom-navigation ${className}`}>
      {paginationSize && (
        <Pagination
          amountOfPages={paginationSize}
          currentPage={activePaginationPage}
          unblocked={unblockedPagination}
          onChange={handlePaginationChange}
        />
      )}
      <div className="bottom-navigation__direction-button-container">
        {!hideNextButton && (
          <Button
            text={nextButtonText}
            icon="chevron-right"
            iconPos="right"
            disabled={String(unblockedPagination) !== 'true' && lockNextButton}
            onClick={handleNextClick}
            className="bottom-navigation__direction-button bottom-navigation__direction-button--next"
          />
        )}
        {!hidePrevButton && (
          <Button
            title="Vorige"
            icon="chevron-left"
            iconPos="left"
            disabled={String(unblockedPagination) !== 'true' && lockPrevButton}
            onClick={handlePrevClick}
            className="bottom-navigation__direction-button bottom-navigation__direction-button--prev"
          />
        )}
      </div>
    </div>
  );
};


export default withRouter(BottomNavigation);

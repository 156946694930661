import React, { FC, ReactElement } from 'react';
import { Accordion, HTMLContent } from '../../../components';
import { ProceedType } from '../../../data';

interface ProceedTypeInfoCardProps {
  proceedType: ProceedType;
  definition: string;
  diversity: string;
  accordionItems: {
    label: string;
    htmlContent: string;
  }[];
}


const ProceedTypeInfoCard: FC<ProceedTypeInfoCardProps> = ({
  proceedType,
  definition,
  diversity,
  accordionItems,
}): ReactElement => (
  <>
    <h3>Definitie</h3>
    <HTMLContent html={definition} />
    <h3>Samenhang met diversiteit</h3>
    <HTMLContent html={diversity} />
    <h3>{`Tips om in te zetten op ${proceedType.toLowerCase()}`}</h3>
    <Accordion
      id={`opbrengsten-${proceedType}`}
      items={accordionItems}
    />
  </>
);

export default ProceedTypeInfoCard;

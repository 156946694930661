import classnames from 'classnames';
import React, { FC } from 'react';
import { ProceedType } from '../../data';
import { ButtonProps } from '../Button/Button';
import { Button } from '../index';

import './ProceedTypeButton.scss';

interface ProceedTypeButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: (proceedType: ProceedType) => void;
  proceedType: ProceedType;
  isActive: boolean;
}

const ProceedTypeButton: FC<ProceedTypeButtonProps> = ({
  proceedType,
  isActive,
  className,
  ...props
}) => {
  const classNames = classnames('proceed-type-button', {
    'proceed-type-button--is-active': isActive,
  }, [className]);

  const onClick = (): void => {
    props.onClick(proceedType);
  };

  return (
    <Button {...props} onClick={onClick} className={classNames} />
  );
};

export default ProceedTypeButton;

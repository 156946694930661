import actionCreatorFactory from 'typescript-fsa';
import { SingleJsonApiResponseType } from '../../fetches/JsonApiResponse';


const actionCreator = actionCreatorFactory('Questions');

export const getOrCreateQuestion = actionCreator.async<
{
  page: string;
  number: number;
},
SingleJsonApiResponseType<'questions'>['data']|undefined
>('GET_OR_CREATE_QUESTION');

export const addPatch = actionCreator('ADD_PATCH');
export const removePatch = actionCreator('REMOVE_PATCH');
